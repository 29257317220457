import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import classNames from 'classnames';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import createBrowserHistory from 'history/createBrowserHistory';
import WindowSizeListener from 'react-window-size-listener';


// Routing
import Routes from 'Core/Routes';

// CSS
import componentStyles from './styles.css';
import componentStylesEntireSite from 'Components/_CommonStyles/commonStyles.css';

// Components
import AuthRoute from './authRoute';
import CommonRoute from './CommonRoute';
import AdminRoute from './adminRoute';

// <Route path="/home(/:pathParam1)(/:pathParam2)" component={HomePage} />

// Helpers

// Actions
import { updateWindowSize, socketConnection } from 'Actions';


/////////////////////////////////////////////////
// TOAST MESSAGES FOR USER
/////////////////////////////////////////////////
toast.configure();


const browserHistory = createBrowserHistory();

class Root extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
    };

    this.recordWindowSize = this.recordWindowSize.bind(this);
  }

  componentDidMount() {
    this.props.socketConnection();    //THE PROPER SOCKET CONNECTION
  }
  
  /*
  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - Root/index");
  }
  */


  recordWindowSize(windowSize) {
    //console.log("recordWindowSize");
    //console.log(windowSize);
    //console.log(JSON.stringify(windowSize));
    this.props.updateWindowSize(windowSize);
  }

  
  render() {

  
    return (
      <Provider store={this.props.store}>
        <WindowSizeListener onResize={(windowSize) => this.recordWindowSize(windowSize)}>
          <div className={classNames({ pageWrapper: true })}>
            <Router history={browserHistory}>
              <Switch>

                {Routes.map((singleRoute) => {
                  const {
                  path, exact, permission, ...otherProps
                  } = singleRoute;

                  if (permission.length === 0) {
                    return (
                      <CommonRoute
                        exact={exact !== false}
                        key={singleRoute.path}
                        path={`/${singleRoute.path}`}
                        {...otherProps}
                      />
                    );
                  } else if (permission.includes('admin')) {
                    return (
                      <AdminRoute
                        exact={exact !== false}
                        key={singleRoute.path}
                        path={`/${singleRoute.path}`}
                        {...otherProps}
                      />
                    );
                  }
                  return (
                    <AuthRoute
                      exact={exact !== false}
                      key={singleRoute.path}
                      path={`/${singleRoute.path}`}
                      {...otherProps}
                    />
                  );
                })}


              </Switch>
            </Router>
          </div>
        </WindowSizeListener>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  updateWindowSize,
  socketConnection,
};


export default connect(null, mapDispatchToProps)(Root); 
//export default compose(connect(mapStateToProps, mapDispatchToProps))(Root);
//export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Root);    //Try deep packet inspection


/* ASYNC EXAMPLE
getAnime = async (query, variables) => {
  try {
    const response = await axios.post('https://graphql.anilist.co', {
      query,
      variables
    });

    // Log the response so we can look at it in the console
    console.log(response.data)

    // Set the data to the state
    this.setState(() => ({
      isLoaded: true,
      items: response.data.data.Page.media
    }));

  } catch (error) {
    // If there's an error, set the error to the state
    this.setState(() => ({ error }))
  }
}
*/

/*
EXAMPLE AUTH MODULE - should do this via component...
export default (
  <Route path="/" component={App}>
    <IndexRoute component={HomePage} />
    <Route path="/login" component={LogInPage} />
    <Route path="/cats" component={CatsPage}
      onEnter={requireAuth}>
      <Route path="/cats/new" component={NewCatPage} />
      <Route path="/cats/:id" component={CatPage} />
    </Route>
    <Route path="/about" component={AboutPage} />
  </Route>
);

function requireAuth(nextState, replace) {
  if (!sessionStorage.jwt) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}


/*
              <Route exact path="/landing" component={LandingPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/home" component={HomePage} />

              <AuthRoute exact path="/my-account/subscriptions" component={MyAccountSubscriptionsPage} />
              <AuthRoute exact path="/my-account/details" component={MyAccountDetailsPage} />
              <AuthRoute exact path="/my-account" component={MyAccountPage} />


              <Route exact path="/api/users/get" component={TestApi} />

              <AdminRoute exact path="/admin" component={AdminPage} />
              <Route exact path="/" component={HomePage} />
              */
