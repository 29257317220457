/** WEBSOCKET ACTIONS ** */


export const authStatusFalse_SOCKET = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const socketConnected = () => ({
  type: 'SET_SOCKET_CONNECTION',
});
export const socketDisconnected = () => ({
  type: 'SET_SOCKET_DISCONNECTION',
});


export const setSocketConnection = connection => ({
  type: 'SET_SOCKET_CONNECTION',
  connection,
});
export const updateSocketEventLog = data => ({
  type: 'UPDATE_SOCKET_EVENT_LOG',
  data,
});


export const customerConnectUserMessageToBusiness = data => ({
  type: 'SET_CUSTOMER_CONNECT_USER_MESSAGE_TO_BUSINESS',
  data,
});




export function wssMessageReceived(e) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | wssMessageReceived');
      console.log(e);

      // Update the socket events
      let data = {
        type: 'wss message received',
        timestamp: Date.now(),
      }
      dispatch(updateSocketEventLog(data));

      // Determine the payload action

      let dataReceived = {};
      try {
        dataReceived = JSON.parse(e.data);
      } catch(e) {}

      console.log("dataReceived");
      console.log(dataReceived);

      let messageType = null;
      try {
        messageType = dataReceived.data.type;
      } catch(e) {}
      console.log("messageType");
      console.log(messageType);

      let messageSubType = null;
      try {
        messageSubType = dataReceived.data.subType;
      } catch(e) {}
      console.log("messageSubType");
      console.log(messageSubType);


      let messageAction = null;
      try {
        messageAction = dataReceived.data.action;
      } catch(e) {}
      console.log("messageAction");
      console.log(messageAction);

      /*
      data: {
        action:"sendMessage",
        data:{
          type:"app",
          subType:"customerConnect",
          action: "messageMe"
          userId: "",
          businessId: "",
          transactionId:"MH7GIKmXxcgxypAGXg8Yh9daoM6MR1g2MIrGOmTij7a",
          message:"Please send me a message"
        }
      }
      */


      let dispatchData = {};

      ///////////////////////////////////////////////////////////////////////
      ///// Customer Connect - Customer Message to Business
      ///////////////////////////////////////////////////////////////////////
      if (messageType === "app" 
      && messageSubType === "customerConnect"
      && messageAction === "messageMe") {
        //Add Action = messageResponseFromCustomer

        let businessId = null;
        try {
          businessId = dataReceived.data.data.businessId;
        } catch(e) {}

        let transactionId = '';
        try {
          transactionId = dataReceived.data.data.transactionId;
        } catch(e) {}

        let customerMessage = '';
        try {
          customerMessage = dataReceived.data.data.message;
        } catch(e) {}


        dispatchData = {
          businessId,
          transactionId,
          customerMessage,
        }
        dispatch(customerConnectUserMessageToBusiness(dispatchData));


      }

     

      
    }));
  };
}

export function wssConnectionObject(ws) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | wssConnectionObject');
      console.log(ws);

      dispatch(setSocketConnection(ws)); // Returns the business object (sync/merge it all)




    }));
  };
}

export function wssOnOpen(e) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | onopen');
      console.log(e);

      let data = {
        type: 'wss connection open',
        timestamp: Date.now(),
      }
      dispatch(updateSocketEventLog(data));
      



    }));
  };
}

export function wssOnReconnect(e) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | onreconnect');
      console.log(e);

      let data = {
        type: 'wss connection re-established',
        timestamp: Date.now(),
      }
      dispatch(updateSocketEventLog(data));


    }));
  };
}

export function wssOnMaximum(e) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | onmaximum');
      console.log(e);

      let data = {
        type: 'wss maximum attempts',
        timestamp: Date.now(),
      }
      dispatch(updateSocketEventLog(data));


    }));
  };
}

export function wssOnClose(e) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | onclose');
      console.log(e);

      //reset socket to null
      socket = null;

      let data = {
        type: 'wss connection closed',
        timestamp: Date.now(),
      }
      dispatch(updateSocketEventLog(data));


    }));
  };
}

export function wssOnError(e) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | onerror');
      console.log(e);

      //reset socket to null
      socket = null;

      let data = {
        type: 'wss error',
        timestamp: Date.now(),
      }
      dispatch(updateSocketEventLog(data));


    }));
  };
}



import Sockette from "sockette";

let socket = null;


export function socketConnection() {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
            
      console.log('ACTION | socketConnection 2');

      /* WAS WORKING AT ONE STAGE

      if (socket !== null) {
        console.log("Socket is currently open");
      } else {
        //Open a new connection
        console.log("Open new socket connection")

        socket = new Sockette(
          `wss://g6w83v1xw1.execute-api.us-east-1.amazonaws.com/dev?token=${apiAccessToken}`,
          //`wss://ws.authchannel.com.au/dev?token=123&realtoken=${apiAccessToken}`,    //comes up as forbidden
          {
            timeout: 5e3,
            maxAttempts: 1,
            onopen: e => {
              console.log("connected:", e);
              dispatch(wssOnOpen(e));
              dispatch(socketConnected());
            },
            onmessage: e => {
              console.log("Message Received:", e);
              dispatch(wssMessageReceived(e));
            },
            onreconnect: e => {
              console.log("Reconnecting...", e);
              dispatch(wssOnReconnect(e));
              dispatch(socketDisconnected());
            },
            onmaximum: e => {
              console.log("Stop Attempting!...", e);
              dispatch(wssOnMaximum(e));
            },
            onclose: e => {
              console.log("Closed!", e);
              dispatch(wssOnClose(e));
              dispatch(socketDisconnected());
            },
            onerror: e => {
              console.log("Error: ", e);
              dispatch(wssOnError(e));
              dispatch(socketDisconnected());
            }
          }
        );
      }

      resolve(socket);

      */
     resolve(true);

    }));
  };
}
export function socketSend() {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('ACTION | socketSend 2');

      socket.json({
        action: "sendMessage",
        message: "Sending message from socketSend ACTION"
      });
      resolve();

    }));
  };
}
