/** *** USER ***** */
import _ from 'lodash';

const initialState = {
  userIdentities: [],
  userNotifications: [],
  userRemoteNotifications: [],

  userNotificationRequestSettings: '',
  userNotificationRequests: [],
  managedBusinesses: [],
};

const resetState = {
  userIdentities: [],
  userNotifications: [],
  userRemoteNotifications: [],

  userNotificationRequestSettings: '',
  userNotificationRequests: [],
  managedBusinesses: [],
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_USER':
    {
      return resetState;
    }

    case 'RESET_STATE_USER':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    

    case 'USER_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR':
    {
      const clone = Object.assign({}, state);
      //console.log('USER - USER_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR');

      for (let z = 0; z < clone.userIdentities.length; z++) {
        if (clone.userIdentities[z]._id === action.data.userIdentitiesId) {
          clone.userIdentities[z].verificationCodeInputError = true;
          clone.userIdentities[z].verificationCodeInputErrorMessage = action.data.message;
        }
      }
      return clone;
    }


    case 'SET_COMMUNICATIONS_TOKEN':
    {
      const clone = Object.assign({}, state);
      //console.log('USER - SET_COMMUNICATIONS_TOKEN');

      for (let z = 0; z < clone.userIdentities.length; z++) {
        if (clone.userIdentities[z]._id === action.data.userIdentitiesId) {
          if (action.data.userIdentitiesType === 'phone') {
            clone.userIdentities[z].communicationToken = action.data.communicationCode;//action.data.phoneCommunicationCode;
            clone.userIdentities[z].communicationTokenArray = action.data.communicationCodeArray;
          } else if (action.data.userIdentitiesType === 'mobile') {
            clone.userIdentities[z].communicationToken = action.data.communicationCode;//action.data.phoneCommunicationCode;
            clone.userIdentities[z].communicationTokenArray = action.data.communicationCodeArray;
          } else if (action.data.userIdentitiesType === 'email') {
            clone.userIdentities[z].communicationToken = action.data.communicationCode;
            clone.userIdentities[z].communicationTokenArray = action.data.communicationCodeArray;

            //clone.userIdentities[z].communicationToken = action.data.emailCommunicationCode_html;
            //clone.userIdentities[z].communicationToken_text = action.data.emailCommunicationCode_text;
            
          } else if (action.data.userIdentitiesType === 'postal') {
            clone.userIdentities[z].communicationToken = action.data.communicationCode;//action.data.phoneCommunicationCode;
            clone.userIdentities[z].communicationTokenArray = action.data.communicationCodeArray;//action.data.phoneCommunicationCode;
          }
          //No return communication tokens for web
        }
      }
      return clone;
    }

    /** **********************************************************************
    ***** NEW REDUX ACTIONS *************************************************
    ************************************************************************ */

    case 'REDUX_UPDATE_USER':
    {
      const clone = Object.assign({}, state);
      //console.log('USER - REDUX_UPDATE');
      //console.log(clone);

      const updateRecords = action.updateRecords;

      for (let z = 0; z < updateRecords.length; z++) {
        //console.log('For each record to update...');
        if (updateRecords !== null && updateRecords !== undefined) {
          const dotPath = updateRecords[z].dotPath;
          const updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue); // Update
          _.merge(clone, cloneItemUpdate); // Merge
          //console.log('==== LOOP: CLONE VALUE ====');
          //console.log(clone);
          cloneItemUpdate = null; // Clear
        }
      }
      //console.log('==== FINAL BEFORE RETURNING CLONE ====');
      //console.log(clone);
      return clone;
    }


    case 'REDUX_INSERT_USER': // insertRecords
    {
      const clone = Object.assign({}, state);
      //console.log('CORE - REDUX_INSERT');
      const insertRecords = action.insertRecords;

      for (let z = 0; z < insertRecords.length; z++) {
        //console.log('For each record to insert...');
        if (insertRecords !== null && insertRecords !== undefined) {
          const dotPath = insertRecords[z].dotPath;
          const updateValue = insertRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          // get dotPath to determine field type
          const dotPathValue = _.get(clone, dotPath);
          if (typeof (dotPathValue) === 'object') {
            if (Array.isArray(dotPathValue)) {
              // It is an array, push item into array
              //console.log(`${dotPath} is an array`);
              dotPathValue.push(updateValue);
              cloneItemUpdate = _.set(cloneItemUpdate, dotPath, dotPathValue);
            } else {
              // merge the value into the existing
              //console.log(`${dotPath} is an object`);
              const tempValueMerge = _.merge(dotPathValue, updateValue);
              cloneItemUpdate = _.set(cloneItemUpdate, dotPath, tempValueMerge);
            }
          } else {
            //console.log(`${dotPath} is NOT an array or object`);
            _.set(cloneItemUpdate, dotPath, updateValue); // Update
          }
          // in all cases, we merge back
          _.merge(clone, cloneItemUpdate); // Merge

          //console.log('==== LOOP: CLONE VALUE ====');
          //console.log(clone);
          cloneItemUpdate = null; // Clear
        }
      }
      //console.log('==== FINAL BEFORE RETURNING CLONE ====');
      //console.log(clone);
      return clone;
    }






    // ABOVE IS THE NEW REVISED CODE



































    // New - could cause issues initally.
    case 'HARD_SYNC_USER_DATA':
    {
      let clone = Object.assign({}, state);
      //console.log('HARD_SYNC_USER_DATA');
      //console.log(action);
      clone = action.user;
      clone = _.set(clone, 'userIdentities', action.user.userIdentities);
      return clone;
    }

    case 'SYNC_USER_DATA':
    {
      let clone = Object.assign({}, state);
      //console.log('SYNC_USER_DATA');
      //console.log(action);
      //console.log(typeof(action.user));
      if (typeof(action.user) === 'object') {
        _.merge(clone, action.user);
        if (action.user.userIdentities) {
          clone = _.set(clone, 'userIdentities', action.user.userIdentities);
        }
        if (action.user.userNotifications) {
          clone = _.set(clone, 'userNotifications', action.user.userNotifications);
        }
        if (action.user.userNotificationRequests) {
          clone = _.set(clone, 'userNotificationRequests', action.user.userNotificationRequests);
        }

      }
      
      return clone;
    }
    




    ///////////////////////////////////////////////////
    // LOGIN - MFA is required, only set the UserId
    ///////////////////////////////////////////////////
    case 'INITIALIZE_USER_ID':
    {
      let clone = Object.assign({}, state);
      //console.log('INITIALIZE_USER_ID');
      //console.log(action);
      //console.log(JSON.stringify(action));

      clone = action.user;      //The endpoint only returns the id

      return clone;
    }


    // LOGIN - REFRESH ALL THE USER DATA (NEW WAY)
    case 'INITIALIZE_USER_DATA':
    {
      let clone = Object.assign({}, state);
      //console.log('INITIALIZE_USER_DATA');
      //console.log(action);
      //console.log(JSON.stringify(action));
      clone = action.user;

      return clone;
    }

    // NEW WAY
    case 'SET_USER_DATA':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_USER_DATA');
      //console.log(action);
      //console.log(JSON.stringify(action));
      _.merge(clone, action.user);
      return clone;
    }


    case 'UPDATE_USER_DATA':
    {
      const clone = Object.assign({}, state);
      //console.log('UPDATE_USER_DATA');
      //console.log(action);
      //console.log(JSON.stringify(action));

      _.merge(clone, action.userData);
      return clone;
    }




    case 'REMOVE_USER_DATA':
    {
      //console.log('removing user data');
      return initialState;
    }


    case 'SET_USER_MANAGED_BUSINESS':
    {
      const clone = Object.assign({}, state);
      // clone.profile = action.userProfile;
      //console.log('SET_USER_PROFILE');
      //console.log(action);

      clone.managedBusinesses = action.user.managedBusinesses;

      return clone;
    }


    default:
      return state;
  }
};

export default UserReducer;
