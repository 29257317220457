import { combineReducers } from 'redux';

import Socket from './Socket';
import App from './App';
import Core from './Core';
import Demo from './Demo';            //Allow a user to demo the system
import Reporting from './Reporting';
import Sync from './Sync';
import UI from './UI';
import Auth from './Auth';
import User from './User';
import Verify from './Verify';
import ApiActions from './ApiActions';
import Product from './Product';
import BusinessIdentities from './BusinessIdentities';
import Interactions from './Interactions';
import Sidebar from './Sidebar';
import CommonActions from './CommonActions';

import AdminWork from './AdminWork';
import AdminStats from './AdminStats';



const appReducer = combineReducers({
  Socket,
  App,
  //Core,
  Demo,
  Reporting,
  Sync,
  UI,
  CommonActions,
  Auth,
  User,
  //Verify,
  ApiActions,
  Product,
  BusinessIdentities,
  Interactions,
  //Sidebar,

  AdminWork,
  AdminStats,
});


/** ********************************************************
 * rootReducer. This is where we can reset/clear state.
 * Otherwise, we return the appReducer which creates all
 * the state for the components requried.
********************************************************** */

const rootReducer = (state, action) => {
  switch (action.type) {

    case 'RESET_STATE':
    {
      state = undefined;
      return state;
    }
    
    case 'REMOVE_STATE_STRUCTURE': 
    {
      const { App, } = state;
      state = { App };
      return state;
    }  

    default:
      return appReducer(state, action);
  }
};
export default rootReducer;
