import React, { Component } from 'react';
import asyncComponent from "Helpers/AsyncFunc";
const Routes = [
  {
    path: "clean",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/PublicUserCleanLocalStorage")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Client Reset',
    navTitleExpanded: 'Client Reset',
    contentTitle: 'Client Reset',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Client Reset',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "work-queue",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/AdminWorkQueuePage")),
    permission: ["auth"],
    businessAccountRoute: false,
    navVisible: true,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Work Queue',
    navTitleExpanded: 'Work Queue',
    contentTitle: 'Work Queue',

    //META tags
    metaTitle: 'Blitz',
    metaDesc: 'Work Queue',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },


  {
    path: "work-queue/:workQueueItem",
    navPosition: 20,
    component: asyncComponent(() => import("Containers/AdminWorkQueueItemPage")),
    permission: ["auth"],
    userRoutePermissions: ["owner", "admin", "user"],
    businessAccountRoute: false,
    navVisible: false,
    displayicon: 'fas fa-home',
    navTitleCollapsed: 'Work Item',
    navTitleExpanded: 'Work Item',
    contentTitle: 'Work Item',


    metaTitle: 'Blitz',
    metaDesc: 'Work Item',
    headerTitlenav: 'Work Item',
    headerTitlebar: 'Work Item',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'business',
        linkGroupOrder: 1,
      },
    ],


    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
      {
        url: '/account/business',
        title: 'Businesses',
      },
      {
        url: '/account/business/:routebusinessid',
        title: 'Dashboard',
      },
    ],
  },


  /////// SUPPORT CENTRE ////////
  {
    path: "support-centre",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/AdminSupportCentrePage")),
    permission: ["auth"],
    businessAccountRoute: false,
    navVisible: true,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Support Centre',
    navTitleExpanded: 'Support Centre',
    contentTitle: 'Support Centre',

    //META tags
    metaTitle: 'Blitz',
    metaDesc: 'Support Centre',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/support-centre',
        title: 'Support Centre',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },


  {
    path: "support-centre/:supportCentreItem",
    navPosition: 20,
    component: asyncComponent(() => import("Containers/AdminSupportCentreItemPage")),
    permission: ["auth"],
    userRoutePermissions: ["owner", "admin", "user"],
    businessAccountRoute: false,
    navVisible: false,
    displayicon: 'fas fa-home',
    navTitleCollapsed: 'Support Centre',
    navTitleExpanded: 'Support Centre',
    contentTitle: 'Support Centre',


    metaTitle: 'Blitz',
    metaDesc: 'Support Centre',
    headerTitlenav: 'Support Centre',
    headerTitlebar: 'Support Centre',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'business',
        linkGroupOrder: 1,
      },
    ],


    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
      {
        url: '/support-centre',
        title: 'Support Centre',
      },
    ],
  },










  ////////////////////////////////////////////////////////////////////////////////////////////////////


  {
    path: "user-identity-verification/email/:userId/:userIdentityId/:verificationCode",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/AdminUserIdentityVerificationEmail")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Email Verification',
    navTitleExpanded: 'Email Verification',
    contentTitle: 'Email Verification',

    //META tags
    metaTitle: 'Blitz',
    metaDesc: 'Email Verification',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "permission-denied",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/RootPermissionDenied")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Permission Denied',
    navTitleExpanded: 'Permission Denied',
    contentTitle: 'Permission Denied',

    //META tags
    metaTitle: 'Blitz',
    metaDesc: 'Permission Denied',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "health",
    navPosition: 90,
    component: asyncComponent(() => import("Containers/AdminClientHealth")),
    permission: [],
    navVisible: false,
    navVisiblePublic: false, 
    displayicon: 'fas fa-ambulance',
    navTitleCollapsed: 'Health',
    navTitleExpanded: 'Client Health',
    contentTitle: 'Client Health',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Client Health',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Client Health',
    ogSitename: 'Client Health',
    ogDesc: 'Client Health',
    ogType: '',
    ogImage: '',
    ogURL: '',


    //linkGroups (URL Site Usage)
    linkGroups: ['support', 'adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'support',
        linkGroupOrder: 20,
      },
    ],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
      {
        url: '/health',
        title: 'Health',
      },
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicUserLoginV2Page")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Login',
    navTitleExpanded: 'Login',
    contentTitle: 'Login',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  }, 
  {
    path: "user-account-management",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/FirebaseUserAccountActions")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Login',
    navTitleExpanded: 'Login',
    contentTitle: 'Login',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "login",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicUserLoginV2Page")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Login',
    navTitleExpanded: 'Login',
    contentTitle: 'Login',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "forgot-password",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicUserForgotPasswordPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Forgot Password',
    navTitleExpanded: 'Forgot Password',
    contentTitle: 'Forgot Password',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/forgot-password',
        title: 'Forgot Password',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },

  {
    path: "sign-up",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicUserSignUpPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Sign Up',
    navTitleExpanded: 'Sign Up',
    contentTitle: 'Sign Up',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    mmetaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/sign-up',
        title: 'Sign Up',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "logout",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicUserLogoutPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-sign-in-alt',
    navTitleCollapsed: 'Logout',
    navTitleExpanded: 'Logout',
    contentTitle: 'Logout',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/login',
        title: 'Login',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "service-status",
    navPosition: 99,
    component: asyncComponent(() => import("Containers/PublicPlatformDashboardStatusPage")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-thermometer-three-quarters',
    navTitleCollapsed: 'Service Status',
    navTitleExpanded: 'Service Status',
    contentTitle: 'Service Status',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['support', 'adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'support',
        linkGroupOrder: 10,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      }
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  
  


  


  {
    path: "account/dashboard",
    navPosition: 1,
    component: asyncComponent(() => import("Containers/AccountUserDashboardPage")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-tachometer-alt',
    navTitleCollapsed: 'Dashboard',
    navTitleExpanded: 'Account Dashboard',
    contentTitle: 'Admin Dashboard',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Guard Point provides customers with free cyber protection services in an effort to combat identity theft, spam and scams worldwide. ',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Account',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "dashboards/users",
    navPosition: 1,
    component: asyncComponent(() => import("Containers/DashboardUsers")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-user-cog',
    navTitleCollapsed: 'Users',
    navTitleExpanded: 'Users',
    contentTitle: 'Users',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Users',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['dashboards','adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'dashboards',
        linkGroupOrder: 1,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Dashboard',
      },
      {
        url: '/account',
        title: 'Account',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "dashboards/business",
    navPosition: 1,
    component: asyncComponent(() => import("Containers/DashboardBusiness")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-user-cog',
    navTitleCollapsed: 'Business',
    navTitleExpanded: 'Business',
    contentTitle: 'Business',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Business',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['dashboards','adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'dashboards',
        linkGroupOrder: 1,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Dashboard',
      },
      {
        url: '/account',
        title: 'Account',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "account",
    navPosition: 1,
    component: asyncComponent(() => import("Containers/AccountUserSettingsMyAccountPage")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-user-cog',
    navTitleCollapsed: 'Account',
    navTitleExpanded: 'Account',
    contentTitle: 'Account',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Account',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['accountusersettings','adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'accountusersettings',
        linkGroupOrder: 1,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Dashboard',
      },
      {
        url: '/account',
        title: 'Account',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "resources",
    navPosition: 1,
    component: asyncComponent(() => import("Containers/ResourcesPage")),
    permission: ["auth"],
    navVisible: true,
    displayicon: 'fas fa-user-cog',
    navTitleCollapsed: 'Resources',
    navTitleExpanded: 'Resources',
    contentTitle: 'Resources',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Resources',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['accountusersettings','adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'accountusersettings',
        linkGroupOrder: 1,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Dashboard',
      },
      {
        url: '/resources',
        title: 'Resources',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  
  
  
  {
    //path: "account/settings/identities",
    path: "account/identities",
    navPosition: 10,
    component: asyncComponent(() => import("Containers/AccountUserSettingsIdentitiesPage")),
    permission: ["auth"],
    navVisible: false,
    displayicon: 'fas fa-id-badge',
    navTitleCollapsed: 'Identities',
    navTitleExpanded: 'Identities',
    contentTitle: 'Identities',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Identities',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Guard Point | Consumer Cyber Protection Services',
    ogSitename: '',
    ogDesc: 'Guard Point helps fight identity theft, spam and scams on consumers.',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/202006-GP-FooledOnce-OpenGraphImage.jpg',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['accountusersettings','adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'accountusersettings',
        linkGroupOrder: 5,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Dashboard',
      },
      {
        url: '/account/identities',
        title: 'Identities',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    //path: "account/settings/notifications",
    path: "account/notifications",
    navPosition: 20,
    component: asyncComponent(() => import("Containers/AccountUserSettingsNotificationsPage")),
    permission: ["auth"],
    navVisible: false,
    displayicon: 'fas fa-bell',
    navTitleCollapsed: 'Notifications',
    navTitleExpanded: 'Notifications',
    contentTitle: 'Notifications',

    //META tags
    metaTitle: 'Blitz | Guard Point | Consumer Protection Services',
    metaDesc: 'Notifications',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Account | Notifications',
    ogSitename: 'Auth Channel',
    ogDesc: 'Account Notifications',
    ogType: 'article',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['accountusersettings','adminpagelisting'],
    linkGroupsData: [
      {
        linkGroup: 'accountusersettings',
        linkGroupOrder: 10,
      },
    ],

   
    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
      {
        url: '/account/dashboard',
        title: 'Dashboard',
      },
      {
        url: '/account/notifications',
        title: 'Notifications',
      },
    ],

    rightcrumb: [],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },













  
  






]
export default Routes;


