/** USER ACTIONS ** */
import { db } from 'Store/client';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import UsersApi from './api/UsersApi';
import DemoApi from './api/DemoApi';




const uuidv1 = require('uuid/v1');

export const authStatusFalse_DEMO = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_DEMO = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const registerReduxDemo = data => ({
  type: 'REGISTER_DEMO',
  data,
});


///////////////////////////////////////////////////////
// Used as part of the user-demos. We retieve only this
// users mobile identities to allow a call to be made easily.
export function registerDemo(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    let userId = _id;

    
    return new Promise(((resolve, reject) => {
      const {
        demoComponent = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      console.warn("demoComponent");
      console.warn(demoComponent);


      const apiBody = JSON.stringify({
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'retrieveMobileUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.retrieveMobileUserIdentities(userDataRegion, userId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | retrieveMobileUserIdentities");
          console.log(apiResponseData);

          
          console.warn("demoComponent after return");
          console.warn(demoComponent);
          

          //Whether we had a successful transaction or not, attempt to register the demo.
          //The user can refresh just the identities via a button.
          let mobileUserIdentities = [];
          try {
            mobileUserIdentities = apiResponseData.data.mobileUserIdentities;
          } catch (e) {
            mobileUserIdentities = [];
          }
          let demoData = {
            mobileUserIdentities,
            demoId: demoComponent.demoId,
          }
          dispatch(registerReduxDemo(demoData));




          if (apiResponseData.status === true) {
            //dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_DEMO());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_DEMO({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}




///////////////////////////////////////////////////////
// INITATE DEMO - BUSINESS REGISTERS INTENTION
//////////////////////////////////////////////////////
export function userDemoBusinessRegistersIntent(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    let userId = _id;

    
    return new Promise(((resolve, reject) => {
      const {
        demoComponent = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      console.warn("demoComponent | ACTION");
      console.warn(demoComponent);

      let demoId = '';
      try {
        demoId = demoComponent.demoId;
      } catch (e) {} 
      let identity = '';
      try {
        identity = demoComponent.identity;
      } catch (e) {} 

      const apiBody = JSON.stringify({
        userDataRegion,
        demoId,
        identity,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userDemoBusinessRegistersIntent',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      DemoApi.connectDemoUser_demo1_notification(userDataRegion, userId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | connectDemoUser_demo1_notification");
          console.log(apiResponseData);

          
          console.warn("demoComponent after return");
          console.warn(demoComponent);
          

          //Whether we had a successful transaction or not, attempt to register the demo.
          //The user can refresh just the identities via a button.
          let mobileUserIdentities = [];
          try {
            mobileUserIdentities = apiResponseData.data.mobileUserIdentities;
          } catch (e) {
            mobileUserIdentities = [];
          }
          let demoData = {
            mobileUserIdentities,
            demoId: demoComponent.demoId,
          }
          dispatch(registerReduxDemo(demoData));




          if (apiResponseData.status === true) {
            //dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_DEMO());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_DEMO({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


///////////////////////////////////////////////////////
// INITATE DEMO - BUSINESS REGISTERS INTENTION (VALID)
//////////////////////////////////////////////////////
export function userDemoBusinessMakesPhoneCall_validSecurityCode(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    let userId = _id;

    
    return new Promise(((resolve, reject) => {
      const {
        demoComponent = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      console.warn("demoComponent | ACTION");
      console.warn(demoComponent);

      let demoId = '';
      try {
        demoId = demoComponent.demoId;
      } catch (e) {} 

      const apiBody = JSON.stringify({
        userDataRegion,
        demoId,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userDemoBusinessMakesPhoneCall',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      DemoApi.connectDemoUser_phone_validSecurityCode(userDataRegion, userId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | connectDemoUser_phone_validSecurityCode");
          console.log(apiResponseData);

          
          console.warn("demoComponent after return");
          console.warn(demoComponent);
          

          if (apiResponseData.status === true) {
            //dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_DEMO());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_DEMO({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

///////////////////////////////////////////////////////
// INITATE DEMO - BUSINESS REGISTERS INTENTION (INVALID)
//////////////////////////////////////////////////////
export function userDemoBusinessMakesPhoneCall_invalidSecurityCode(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    let userId = _id;

    
    return new Promise(((resolve, reject) => {
      const {
        demoComponent = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      console.warn("demoComponent | ACTION");
      console.warn(demoComponent);

      let demoId = '';
      try {
        demoId = demoComponent.demoId;
      } catch (e) {} 

      const apiBody = JSON.stringify({
        userDataRegion,
        demoId,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userDemoBusinessMakesPhoneCall',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      DemoApi.connectDemoUser_phone_invalidSecurityCode(userDataRegion, userId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | connectDemoUser_phone_validSecurityCode");
          console.log(apiResponseData);

          
          console.warn("demoComponent after return");
          console.warn(demoComponent);
          

          if (apiResponseData.status === true) {
            //dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_DEMO());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_DEMO({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_DEMO({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}