import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';


const config = {
  // GUARDPOINT BLITZ CODE
  //messagingSenderId: "981232026435",
  //measurementId: "G-4MWHCR93FE"
  apiKey: "AIzaSyCVXqUpWGkXdZsP2mKMwNY9ICwTWeXe89c",
  authDomain: "guardpoint-blitz.firebaseapp.com",
  databaseURL: "https://guardpoint-blitz.firebaseio.com",
  projectId: "guardpoint-blitz",
  storageBucket: "guardpoint-blitz.appspot.com",
  messagingSenderId: "873418465695",
  appId: "1:873418465695:web:9af68dadd7444b99bd9daa"
};

firebase.initializeApp(config);

export const auth = firebase.auth;


let messaging;
if(firebase.messaging.isSupported()) {
  console.log("Firebase Messaging is Supported");
}
