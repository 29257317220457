/** BUSINESS REGISTRATION ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import StripePaymentsApi from './api/StripePaymentsApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_PAYMENTS = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_PAYMENTS = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const setBusinessRegistration_paymentComplete = paymentDetails => ({
  type: 'SET_BUSINESS_REGISTRATION_PAYMENT_COMPLETE',
  paymentDetails,
});


export const syncBusinessData_PAYMENTS = businesses => ({
  type: 'SYNC_BUSINESS_DATA',
  businesses,
});
export const syncBusinessData_INVOICES = businessInvoiceData => ({
  type: 'SYNC_BUSINESS_DATA_INVOICES',
  businessInvoiceData,
});




export function createBusinessSubscription(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { subscriptionObj } = body;

      let businessId = '';
      try {
        const { data } = body;
        businessId = data.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}


      const apiBody = JSON.stringify({
        subscriptionData: subscriptionObj,
        businessDataRegion,
      });
      dispatch(ApiMonitoring.updateApiInProgressCounter()); // API In Progress
      StripePaymentsApi.createBusinessSubscription(businessDataRegion, businessId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | createBusinessSubscription');
        console.log(apiResponseData);

        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_PAYMENTS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_PAYMENTS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_PAYMENTS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);

      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter()); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}




export function updateBusinessSubscriptionCard(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { subscriptionPaymentUpdateObj } = body;

      let businessId = '';
      try {
        const { data } = body;
        businessId = data.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}


      const apiBody = JSON.stringify({
        subscriptionData: subscriptionPaymentUpdateObj,
        businessDataRegion,
      });
      dispatch(ApiMonitoring.updateApiInProgressCounter()); // API In Progress
      StripePaymentsApi.updateBusinessSubscriptionPayment(businessDataRegion, businessId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | updateBusinessSubscriptionPayment');
        console.log(apiResponseData);

        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_PAYMENTS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_PAYMENTS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_PAYMENTS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);

      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter()); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}


// WAs configured for subscription page (however relates to delete business)
// May be used to delete an optional subscription
export function cancelBusinessSubscription(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let businessId = '';
      let subscriptionId = '';
      try {
        const { data } = body;
        businessId = data.businessId;
        subscriptionId = data.subscriptionId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      
      const apiBody = JSON.stringify({
        subscriptionData: subscriptionObj,
        businessDataRegion,
      });

      dispatch(ApiMonitoring.updateApiInProgressCounter()); // API In Progress
      StripePaymentsApi.cancelBusinessSubscription(businessDataRegion, businessId, subscriptionId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | cancelBusinessSubscription');
        console.log(apiResponseData);

        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_PAYMENTS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_PAYMENTS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_PAYMENTS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter()); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}








export function listBusinessInvoices(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      //const { subscriptionObj } = body;

      let businessId = '';
      try {
        const { data } = body;
        businessId = data.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}


      
      const apiBody = JSON.stringify({
        businessDataRegion,
      });
      
      dispatch(ApiMonitoring.updateApiInProgressCounter()); // API In Progress
      StripePaymentsApi.listBusinessInvoices(businessDataRegion, businessId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | listBusinessInvoices');
        console.log(apiResponseData);


        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_INVOICES(apiResponseData.data.businessInvoiceData));

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_PAYMENTS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_PAYMENTS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_PAYMENTS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);

      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter()); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}












//Likely not used?
export function createAdhocChange(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { subscriptionObj } = body;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }


      const apiBody = JSON.stringify({
        subscriptionData: subscriptionObj,
      });
      dispatch(ApiMonitoring.updateApiInProgressCounter()); // API In Progress
      StripePaymentsApi.createAdhocChange(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.log('Return | API | stripePaymentCharge');
          console.log(apiResponseData);

          if (apiResponseData.status === true) {
  
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_PAYMENTS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_PAYMENTS());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_PAYMENTS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_PAYMENTS({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter()); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function createPaymentCharge(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { payment } = body;

      const token = payment.token;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }


      const apiBody = JSON.stringify({
        paymentdata: token,
      });
      dispatch(ApiMonitoring.updateApiInProgressCounter()); // API In Progress
      StripePaymentsApi.stripePaymentCharge(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.log('Return | API | stripePaymentCharge');
          
          if (apiResponseData.status === true) {
  
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_PAYMENTS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_PAYMENTS());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_PAYMENTS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_PAYMENTS({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter()); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
