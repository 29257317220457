import Request from './Request';
import RequestRegion from './RequestRegion';


class BusinessApi {

  // // PROFILES //// - REGION SUPPORT
  static createBusinessProfile(businessDataRegion, businessId, body, accessToken) {
    console.warn(`API | POST | Create Business Profile | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile`, body, global.gpAccessToken);
  }
  static updateBusinessProfile(businessDataRegion, businessId, profileId, body, accessToken) {
    console.warn(`API | PATCH | Update Business Profile | Region: ${businessDataRegion}`);
    return RequestRegion.PATCH_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}`, body, global.gpAccessToken);
  }
  static deleteBusinessProfile(businessDataRegion, businessId, profileId, body, accessToken) {
    console.warn(`API | DELETE | Delete Business Profile | Region: ${businessDataRegion}`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}`, body, global.gpAccessToken);
  }
// // ASSOCIATE PROFILE //// - REGION SUPPORT
  static createBusinessIdentityAssociationToProfile(businessDataRegion, businessId, profileId, identityId, body, accessToken) {
    console.warn(`API | POST | Associate Identity to Profile | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}/associate/${identityId}`, body, global.gpAccessToken);
  }
  static deleteBusinessIdentityAssociationToProfile(businessDataRegion, businessId, profileId, identityId, body, accessToken) {
    console.warn(`API | POST | Disassociate Identity to Profile | Region: ${businessDataRegion}`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}/associate/${identityId}`, body, global.gpAccessToken);
  }


  // // IDENTITIES //// - REGION SUPPORT
  static createBusinessIdentity(businessDataRegion, businessId, body, accessToken) {
    console.warn(`API | POST | Create Identity | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/identities/business/${businessId}/identity`, body, global.gpAccessToken);
  }
  static updateBusinessIdentity(businessDataRegion, businessId, identityId, body, accessToken) {
    console.warn(`API | PATCH | Update Identity | Region: ${businessDataRegion}`);
    return RequestRegion.PATCH_json(businessDataRegion, 'businessApi', `/v1/identities/business/${businessId}/identity/${identityId}`, body, global.gpAccessToken);
  }
  static deleteBusinessIdentity(businessDataRegion, businessId, identityId, body, accessToken) {
    console.warn(`API | DELETE | Delete Identity | Region: ${businessDataRegion}`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/identities/business/${businessId}/identity/${identityId}`, body, global.gpAccessToken);
  }


  // // VERIFICATION - REQUESTS ////
  static createBusinessIdentityVerificationRequest(businessId, body, accessToken) {
    console.warn(`API | POST | Create Identity Verification Request`);
    return Request.POST_json(`${process.env.businessAPI}/v1/verification-requests/business/${businessId}/verification/identities`, body, global.gpAccessToken);
  }

  // // VERIFICATIONS ////

  static createBusinessIdentityVerificationConfirmation(businessDataRegion, businessId, body, accessToken, businessIdentitiesId, verificationCode) {
    console.warn(`API | GET | Verify an Identity Confirmation | Region: ${businessDataRegion}`);
    return RequestRegion.GET_json(businessDataRegion, 'businessApi', `/v1/verifications/business/${businessId}/identity/${businessIdentitiesId}/verify?id=${verificationCode}`, body, global.gpAccessToken);
  }

  static createBusinessIdentityVerificationConfirmationRemote(businessId, body, accessToken, businessIdentitiesId) {
    console.warn(`API | POST | Verify an Identity Confirmation | Remote | Verify from system`);
    return Request.POST_json(`${process.env.businessAPI}/v1/verifications/business/${businessId}/identity/${businessIdentitiesId}/remote/verify`, body, global.gpAccessToken);
  }

  static createBusinessIdentityVerificationCode(businessId, identityId, identityType, body, accessToken) {
    console.warn(`API | POST | Create Identity Verification Code (website)`);
    return Request.POST_json(`${process.env.businessAPI}/v1/verifications/business/${businessId}/identity/${identityId}/generate/${identityType}`, body, global.gpAccessToken);
  }

  static businessIdentitiesVerificationUnauthenticated(businessId, userIdentityId, userVerificationCode) {
    console.warn(`API | GET | Verify an identity (Unauthenticated)`);
    return Request.GET_json(`${process.env.businessAPI}/v1/verifications/business/${businessId}/identity/${userIdentityId}/verify?id=${userVerificationCode}`, null, null);
  }



  // // GENERIC UPDATES //// - REGION SUPPORT
  static updateBusinessData(businessDataRegion, businessId, body, accessToken) {
    console.warn(`API | PATCH | Update Business Data | Region: ${businessDataRegion}`);
    return RequestRegion.PATCH_json(businessDataRegion, 'businessApi', `/v1/businessdata/${businessId}`, body, global.gpAccessToken);
  }



 // // REPORTING DATA //// - REGION SUPPORT
 static updateBusinessReportingData(businessDataRegion, businessId, reportName, body, accessToken) {
  console.warn(`API | POST | Update Business Reporting Data | Region: ${businessDataRegion}`);
  return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/reporting/business/${businessId}/reporting/connect/${reportName}`, body, global.gpAccessToken);
}



  // // BUSINESS USERS ////
  static createBusinessUser(businessDataRegion, businessId, userId, body, accessToken) {
    console.warn(`API | POST | Create Business User`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator`, body, global.gpAccessToken);
  }
  static deleteBusinessUser(businessDataRegion, businessId, userId, administratorId, body, accessToken) {
    console.warn(`API | DELETE | Delete Business User`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator/${administratorId}`, body, global.gpAccessToken);
  }
 



  

  // permissions haven't been migrated as yet.
  static createBusinessUserPermission(businessDataRegion, businessId, userId, administratorId, body, accessToken) {
    console.warn(`API | POST | Create Business User Permission`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator/${administratorId}/permissions`, body, global.gpAccessToken);
  }
  static deleteBusinessUserPermission(businessDataRegion, businessId, userId, administratorId, body, accessToken) {
    console.warn(`API | DELETE | Delete Business User Permission`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator/${administratorId}/permissions`, body, global.gpAccessToken);
  }



  // // BUSINESS - USER RELATED ACTIVITIES ON BUSINESS ////
  static userBusinessInvitationResponse(businessDataRegion, userInvitationResponse, businessId, userId, body, accessToken) {
    console.warn(`API | POST | User Accepts/Declines Business Invitation`);
    let userResponse = 'decline';
    if (userInvitationResponse === 'accept') {
      userResponse = 'accept';
    }
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/business-invitation/${userResponse}`, body, global.gpAccessToken);
  }


  // // BUSINESS - CREATE AND DELETE ////
  static createBusiness(businessDataRegion, body, accessToken) {
    console.warn(`API | POST | Create Business`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/business/region/${businessDataRegion}`, body, global.gpAccessToken);
  }

  static deleteBusiness(businessId, body, accessToken) {
    console.warn(`API | DELETE | Delete Business`);
    return Request.DELETE_json(`${process.env.businessAPI}/v1/business/${businessId}/remove`, body, global.gpAccessToken);
  }
  static requestImmediateBusinessDeletion(businessId, body, accessToken) {
    console.warn(`API | DELETE | Request Immediate Business Deletion`);
    return Request.DELETE_json(`${process.env.businessAPI}/v1/business/${businessId}/remove/immediate`, body, global.gpAccessToken);
  }


  // // BUSINESS CONNECT CODES  ////
  // NOTE: connectAPI (not BusinessAPI)
  static createCustomerConnectCode(businessDataRegion, businessId, businessProfileId, body, accessToken) {
    console.warn(`API | POST | Create Business Customer Connect Codes`);
    return RequestRegion.POST_json(businessDataRegion, 'connectUsersApi', `/v1/business/${businessId}/profile/${businessProfileId}`, body, global.gpAccessToken);
  }
  static createCustomerConnectCode_inPerson(businessDataRegion, businessId, businessProfileId, body, accessToken) {
    console.warn(`API | POST | Create Business Customer Connect Codes for In-Person meetings`);
    return RequestRegion.POST_json(businessDataRegion, 'connectUsersApi', `/v1/meeting/business/${businessId}/profile/${businessProfileId}`, body, global.gpAccessToken);
  }


 

  // // BUSINESS - CONTACT DETAILS FOR ACCOUNT ////

  static createBusinessContactIdentityToAccount(businessId, businessIdentityId, body, accessToken) {
    console.warn(`API | POST | Create Business Contacts`);
    return Request.POST_json(`${process.env.businessAPI}/v1/contacts/business/${businessId}/identity/${businessIdentityId}/businesscontact/create`, body, global.gpAccessToken);
  }
  static deleteBusinessContactIdentityFromAccount(businessId, businessIdentityId, body, accessToken) {
    console.warn(`API | DELETE | Delete Business Contacts`);
    return Request.DELETE_json(`${process.env.businessAPI}/v1/contacts/business/${businessId}/identity/${businessIdentityId}/businesscontact/delete`, body, global.gpAccessToken);
  }

























  // // SYNC BUSINESS DATA  ////
  static syncBusinessData(accessToken) {
    console.warn(`API | POST | Sync Business Data`);
    return Request.POST_json(`${process.env.businessAPI}/v1/business/sync`, null, global.gpAccessToken);
  }







  // // SYNC BUSINESS REPORTING LOGS  ////
  static syncLogs(businessDataRegion, businessId, body, accessToken) {
    console.warn(`API | GET | Sync Reporting Log Data`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/reporting/business/${businessId}/logs`, body, global.gpAccessToken);
  }







}


export default BusinessApi;
