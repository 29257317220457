import Request from './Request';
import RequestAxios from './RequestAxios';
import RequestRegion from './RequestRegion';
import RequestRegionAxios from './RequestRegionAxios';

class AuthApi {

  /********* LOGIN AND MFA V2 *****************/
  static loginV2(body) {
    console.warn('API | POST | Login user V2');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v2/user-login/login`, body, global.fbAccessToken, withHttpOnlyCookies);
  }
  static loginMfaV2(userId, body) {
    console.warn('API | POST | MFA user login V2');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v2/user-login/user/${userId}/mfa`, body, global.fbAccessToken, withHttpOnlyCookies);
  }








  static login(body, accessToken) {
    console.warn('API | POST | Login user');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v1/user-login/login`, body, global.fbAccessToken, withHttpOnlyCookies);
  }
  static mfaLogin(userId, body, accessToken) {
    console.warn('API | POST | MFA user login');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v1/user-login/user/${userId}/mfa`, body, global.fbAccessToken, withHttpOnlyCookies);
  }
  static signup(region, body, accessToken) {
    console.warn('API | POST | Signup user');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v1/user-signup/region/${region}`, body, global.fbAccessToken, withHttpOnlyCookies);
  }


  static refreshAccessToken(userDataRegion, body) {
    console.warn('API | POST | Refresh Access Token');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-refresh/refresh`, body, global.gpRefreshToken, withHttpOnlyCookies);
  }

  //DELETE USER
  static userDelete(userDataRegion, userId, body, accessToken) {
    console.warn('API | POST | Delete user');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-delete/user/${userId}`, body, global.gpAccessToken);
  }


  


  



  static loginAndLinkAccount(newFirebaseAccessToken, existingUserId, body, accessToken) {
    console.warn('API | POST | Login and link user account');
    return Request.POST_json(`${process.env.authAPI}/v1/link/${existingUserId}`, body, global.gpAccessToken);
  }

  static loginAndDelete(accessToken) {
    console.log('loginAndDelete(accessToken)');
    console.log(accessToken);
    return Request.POST_json(`${process.env.authAPI}/v1/login/delete`, null, global.gpAccessToken);
  }
  
  static firebaseAuthUpdate(accessToken, body) {
    console.warn('API | POST | Firebase user update');
    return Request.POST_json(`${process.env.authAPI}/v1/firebaseupdate`, body, global.gpAccessToken);
  }
  
  
  



}

export default AuthApi;

