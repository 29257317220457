/** USER ACTIONS ** */
import { db } from 'Store/client';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import HealthApi from './Api/HealthApi';




const uuidv1 = require('uuid/v1');

export const authStatusFalse_PLATFORM_HEALTH = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_PLATFORM_HEALTH = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});


///////////////////////////////////////////////////////
// GET THE PLATFORM SERVICE HEALTH
export function getPlatformServiceHealth(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;


    return new Promise(((resolve, reject) => {
      const {
        demoComponent = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      const apiBody = JSON.stringify({
        
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'HealthApi',
        function: 'getPlatformHealth',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      HealthApi.healthPlatformServiceApi(apiBody)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | healthPlatformServiceApi");
          console.log(apiResponseData);

          let apiServiceHealthResponse = [];

          if (apiResponseData.status === true) {
            if (apiResponseData.data.appStatus === true) {

              apiServiceHealthResponse = apiResponseData.data.overallHealthCheckStatus;

            }
            
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_PLATFORM_HEALTH({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_PLATFORM_HEALTH());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_PLATFORM_HEALTH({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_PLATFORM_HEALTH({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful

          //Respond back to the requesting page with the dataset
          resolve(apiServiceHealthResponse);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}



