

/** CORE ACTIONS ** */
import store from 'Store/ConfigureStore';
import _ from 'lodash';
import ApiMonitoring from './ApiMonitoring';

const uuidv1 = require('uuid/v1');

// Following are used for data updates (generic format for updating)
import UsersApi from './api/UsersApi';
import BusinessApi from './api/BusinessApi';

export const authStatusFalse_SYNC = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

/** *************************************************
**** SYNC *****************
************************************************** */
export const syncTimerCreation = syncTimerId => ({
  type: 'SYNC_CREATE_TIMER',
  syncTimerId,
});
export const syncDataBusinessResults = data => ({
  type: 'SYNC_DATA_BUSINESS_RESULTS',
  data,
});





export function syncActive(body = {}) {
  return (dispatch, getState) => {
    const { syncTimer } = getState().Sync;
    return new Promise(((resolve, reject) => {
      console.warn('syncActive');
      /** ****************************************
       * If the syncTimer is '', we are starting.
       * Create a timer to check in on syncs
       **************************************** */

      if (syncTimer === '') {
        // Create timer
        const syncTimerId = setInterval(() => {
          dispatch(syncUserBusiness());
        }, 1800000); // 30 minutes
        dispatch(syncTimerCreation(syncTimerId)); // NEW SYNC TRIAL
        resolve();
      } else {
        // Ignore.
        resolve();
      }
    }));
  };
}


export function syncUserBusiness(body = {}) {
  return (dispatch, getState) => {
    const { lastSyncUser, lastSyncBusiness, lastSyncSoftware } = getState().Sync;
    return new Promise(((resolve, reject) => {
      console.warn('syncUserBusiness');
      const timestampNow = Date.now();

      if (lastSyncUser === '') {
        // sync now
        //console.log('Sync User Account NOW');

      } else {
        const mins10 = 600000;
        if (timestampNow - lastSyncUser > mins10) {
          //console.log('Sync User Account');

        } else {
          //console.log('No need to sync the user account yet');
        }
      }
      resolve();
    }));
  };
}


export function syncDataBusiness(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'syncBusinessData',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.syncBusinessData(apiAccessToken)
        .then((businessData) => {
          //console.log('Return | API | businessData');
          //console.log(businessData);
          dispatch(syncDataBusinessResults(businessData)); // NEW SYNC TRIAL

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(businessData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


export function TEMPLATE(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;
      const identityId = business.identityId;
      const identityType = business.identityType;
      const identityContactType = business.identityContactType;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessId,
        identityId,
        identityType,
        identityContactType,
      });

      //console.log('apiActivityId');
      //console.log(apiActivityId);

      console.table([
        {
          name: 'api',
          value: 'Business',
        },
        {
          name: 'function',
          value: 'addBusinessContactIdentityToAccount',
        },
        {
          name: 'AccessToken',
          value: apiAccessToken,
        },
      ]);
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessContactIdentityToAccount(businessId, identityId, apiBody, apiAccessToken)
        .then((businessResult) => {
          //console.log('Return | API | createBusinessContactIdentityToAccount');
          //console.log(businessResult);
          // dispatch(syncBusinessData(businessResult)); // NEW SYNC TRIAL

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(businessResult);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
