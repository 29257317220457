import Request from './Request';
import RequestRegion from './RequestRegion';

class UsersApi {

  /////////////////////////////////////
  // REGION SUPPORTED API ENDPOINTS
  /////////////////////////////////////

  // NEW API
  static updateUserData(userDataRegion, userId, body, accessToken) {
    console.warn(`API | PATCH | Update User Data | Region: ${userDataRegion}`);
    return RequestRegion.PATCH_json(userDataRegion, 'usersApi', `/v1/userdata/${userId}`, body, global.gpAccessToken);
  }

  /*
  static updateUserData(userId, body, accessToken) {
    console.warn(`API | PATCH | Update User Data `);
    return Request.PATCH_json(`${process.env.usersAPI}/v1/userdata/${userId}`, body, global.gpAccessToken);
  }
  */


  // REQUEST AN IDENTITY VERIFICATION
  static userIdentitiesVerify(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Request an Identity to be Verified | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/verification-requests/user/${userId}/verification/identites`, body, global.gpAccessToken);
  }


  // NEW API
  static createUserIdentity(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Create User Identity | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/identity/user/${userId}/identity`, body, global.gpAccessToken);
  }
  // NEW API
  static deleteUserIdentity(userDataRegion, userId, identityId, body, accessToken) {
    console.warn(`API | DELETE | Delete User Identity | Region: ${userDataRegion}`);
    return RequestRegion.DELETE_json(userDataRegion, 'usersApi', `/v1/identity/user/${userId}/identity/${identityId}`, body, global.gpAccessToken);
  }



  // VERIFY AN IDENTITY ////
  static userIdentitiesVerificationCode(userDataRegion, userId, body, userIdentitiesId, verificationCode, accessToken) {
    console.warn(`API | GET | Verify an identity (Authenticated) | Region: ${userDataRegion}`);
    return RequestRegion.GET_json(userDataRegion, 'usersApi', `/v1/verifications/user/${userId}/identity/${userIdentitiesId}/verify?id=${verificationCode}`, body, global.gpAccessToken);
  }
  //Unable to use region support (NOTE:::)
  static userIdentitiesVerificationUnauthenticated(userId, userIdentityId, userVerificationCode) {
    console.warn('API | GET | Verify an identity (Unauthenticated)');
    return Request.GET_json(`${process.env.usersAPI}/v1/verifications/user/${userId}/identity/${userIdentityId}/verify?id=${userVerificationCode}`, null, null);
  }




  ////////////////////////////////////

  // ///  USER | NOTIFICATIONS ///// (not in use???)
  static createUserNotificationApp(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Create User Notification App | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/user/${userId}/notifications`, body, global.gpAccessToken);
  }

  // NEW API
  // /// NOTIFICATIONS - CREATE NOTIFICATION DEVICE /////
  static userNotificationIdentityCreate(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Notification Identity Add New | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity`, body, global.gpAccessToken);
  }
  // /// NOTIFICATIONS - DELETE NOTIFICATION /////
  static userNotificationIdentityDelete(userDataRegion, userId, userNotificationsIdentitiesId, body, accessToken) {
    console.warn(`API | DELETE | Notification Identity Add New | Region: ${userDataRegion}`);
    return RequestRegion.DELETE_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity/${userNotificationsIdentitiesId}`, body, global.gpAccessToken);
  }
  // NEW API
  // /// NOTIFICATIONS - VERIFICATION REQUEST /////
  static userNotificationIdentityVerificationRequest(userDataRegion, userId, userIdentitiesId, body, accessToken) {
    console.warn(`API | POST | Notification Identity Verification Request | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity/${userIdentitiesId}/verification/request`, body, global.gpAccessToken);
  }
  // NEW API
  // /// NOTIFICATIONS - VERIFICATION CONFIRMATION /////
  static userNotificationIdentityVerificationConfirmation(userDataRegion, userId, userIdentitiesId, body, accessToken) {
    console.warn(`API | POST | Notification Identity Verification Request | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity/${userIdentitiesId}/verification/confirm`, body, global.gpAccessToken);
  }


  


  






 // // LIST USER INTERACTIONS //    1/1/2020 (For new interaction listing page for users)
 static listUserInteractions(userId) {
  console.warn('API | GET | Verify an identity (Unauthenticated)');
  return Request.POST_json(`${process.env.usersAPI}/v1/interactions/user/${userId}`, null, null);
}
  


  /*
  static updateUser(userId, body, accessToken) {
    console.warn('API | PATCH | Update User');
    return Request.PATCH_json(`${process.env.usersAPI}/v1/user/${userId}`, body, global.gpAccessToken);
  }

  static createUser(body, accessToken) {
    console.warn('API | POST | Create User');
    return Request.POST_json(`${process.env.usersAPI}/v1/user`, body, global.gpAccessToken);
  }

  static findUser(body, accessToken) {
    console.warn('API | GET | Find User');
    return Request.GET_json(`${process.env.usersAPI}/v1/user/${userId}`, global.gpAccessToken);
  }
  */
  /*
  static updateUser(userId, body, accessToken) {
    return Request.PATCH_json(`${process.env.usersAPI}/v1/user/${userId}`, body, global.gpAccessToken);
  }
  */
/* THIS SHOUDL NOT BE RUN - It is handled in AUTH module
  static deleteUser(userId, body, accessToken) {
    console.warn('API | DELETE | Delete User');
    return Request.DELETE_json(`${process.env.usersAPI}/v1/user/${userId}`, global.gpAccessToken);
  }
  */
  /*
  static createUserPermission(userId, body, accessToken) {
    return Request.POST_json(`${process.env.usersAPI}/v1/user/${userId}/permission`, body, global.gpAccessToken);
  }

  static removeUserPermission(userId, body, accessToken) {
    return Request.DELETE_json(`${process.env.usersAPI}/v1/user/${userId}/permission`, body, global.gpAccessToken);
  }
  */
  /*
  ///// GENERATE CODES /////
  static userIdentitiesGenerateCode(userId, type, body, accessToken) {
    return Request.POST_json(`${process.env.usersAPI}/v1/user/${userId}/codes/${type}`, body, global.gpAccessToken);
  }
  */

  

 
  // RETRIEVE USER MOBILE IDENTITIES FOR DEMOS ////
  static retrieveMobileUserIdentities(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Retrieve Mobile User Identities | Region: ${userDataRegion}`);
    return RequestRegion.POST_json(userDataRegion, 'usersApi', `/v1/demo/user/${userId}/mobileIdentities`, body, global.gpAccessToken);
  }



}

export default UsersApi;

