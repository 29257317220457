import Request from './Request';

class VerifyApi {

  static verifyInteraction(interactionCode, accessToken) {
    console.warn('API | POST | Verify an Interaction Code');
    return Request.POST_json(`${process.env.verifyAPI}/v1/verify/${interactionCode}`, null, global.gpAccessToken);
  }

}
export default VerifyApi;
