const axios = require('axios');
module.exports.RequestAxios_json = (method, region, endpoint, url, accessToken, body, withHttpOnlyCookies) => {

  console.log(`region: ${region}, endpoint: ${endpoint}`);

  if (region === 'aus') {
    if (endpoint === 'usersApi') {
      url = `${process.env.usersRegionAusApi}${url}`;
    } else if (endpoint === 'businessApi') {
      url = `${process.env.businessRegionAusApi}${url}`;
    } else if (endpoint === 'paymentsApi') {
      url = `${process.env.paymentsRegionAusApi}${url}`;
    } else if (endpoint === 'authApi') {
      url = `${process.env.authRegionAusApi}${url}`;
    } else if (endpoint === 'connectUsersApi') {
      url = `${process.env.connectUsersRegionAusApi}${url}`;
    } else if (endpoint === 'connectDemoApi') {
      url = `${process.env.connectDemoRegionAusApi}${url}`;
    } else if (endpoint === 'verifyInteractionApi') {
      url = `${process.env.verifyInteractionRegionAusApi}${url}`;
    } else if (endpoint === 'verifyPhoneApi') {
      url = `${process.env.verifyPhoneRegionAusApi}${url}`;
    } else if (endpoint === 'generalApi') {
      url = `${process.env.generalRegionAusApi}${url}`;
    } 

  } else if (region === 'usa') {
    if (endpoint === 'usersApi') {
      url = `${process.env.usersRegionUsaApi}${url}`;
    } else if (endpoint === 'businessApi') {
      url = `${process.env.businessRegionUsaApi}${url}`;
    } else if (endpoint === 'paymentsApi') {
      url = `${process.env.paymentsRegionUsaApi}${url}`;
    } else if (endpoint === 'authApi') {
      url = `${process.env.authRegionUsaApi}${url}`;
    } else if (endpoint === 'connectUsersApi') {
      url = `${process.env.connectUsersRegionUsaApi}${url}`;
    } else if (endpoint === 'connectDemoApi') {
      url = `${process.env.connectDemoRegionUsaApi}${url}`;
    } else if (endpoint === 'verifyInteractionApi') {
      url = `${process.env.verifyInteractionRegionUsaApi}${url}`;
    } else if (endpoint === 'verifyPhoneApi') {
      url = `${process.env.verifyPhoneRegionUsaApi}${url}`;
    } else if (endpoint === 'generalApi') {
      url = `${process.env.generalRegionUsaApi}${url}`;
    } 
  }

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  console.log("withHttpOnlyCookies");
  console.log(withHttpOnlyCookies);
  
  if (withHttpOnlyCookies === undefined || withHttpOnlyCookies === null || withHttpOnlyCookies === '') {
    withHttpOnlyCookies = false;
  }

  
  return new Promise(((resolve, reject) => {
    console.log(`Axios ${url} | method: ${method} | body: ${body}`);
    axios({
      method: method,
      url: url,
      headers: headers,
      data: body,
      withCredentials: withHttpOnlyCookies,     //true/false passed in
      responseType: 'json',
      // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
      //xsrfCookieName: 'XSRF-TOKEN', // default
      // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
      //xsrfHeaderName: 'X-XSRF-TOKEN', // default
      // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
      //maxContentLength: 2000,
    
      // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
      //maxBodyLength: 2000,

    
    })
    .then((json) => {
      console.log(json);
      let jsonDataResponse = {};
      try {
        jsonDataResponse = json.data;
      } catch (e) {
        jsonDataResponse = {};
      }
      if (jsonDataResponse === undefined) {
        jsonDataResponse = {};
      }
      resolve(jsonDataResponse);
      
    })
    .catch((err) => {
      console.error(`Fetch Error | ${err}`);
      resolve(false);
    });
  }));
};

module.exports.GET_json = (region, endpoint, url, accessToken, withHttpOnlyCookies) => module.exports.RequestAxios_json('GET', region, endpoint, url, accessToken, null, withHttpOnlyCookies);
module.exports.PATCH_json = (region, endpoint, url, body, accessToken, withHttpOnlyCookies) => module.exports.RequestAxios_json('PATCH', region, endpoint, url, accessToken, body, withHttpOnlyCookies);
module.exports.POST_json = (region, endpoint, url, body, accessToken, withHttpOnlyCookies) => module.exports.RequestAxios_json('POST', region, endpoint, url, accessToken, body, withHttpOnlyCookies);
module.exports.DELETE_json = (region, endpoint, url, body, accessToken, withHttpOnlyCookies) => module.exports.RequestAxios_json('DELETE', region, endpoint, url, accessToken, body, withHttpOnlyCookies);

