/** *** AUTH ***** */
import _ from 'lodash';

const initialState = {
  mfaSecurityRequired: false,   //If MFA UI should be switched on
  mfaSecurityRequiredTimeSet: '',
  //apiAccessToken: '', // Access (Resource Token)
  apiAccessTokenExpiresIn: '', // how many seconds (not that useful)
  apiAccessTokenExpiresOn: '', // timestamp that it expires (used)
  //apiRefreshToken: '', // Refresh (Authentication Token)
  apiRefreshTokenExpiresIn: '', // how many seconds (not that useful)
  apiRefreshTokenExpiresOn: '', // timestamp that it expires (used)
  //authToken: '',        // Firebase token

  //firebaseAccessToken: '',    // Firebase access token
  firebaseAuthData: {
    iss: '',
    aud: '',
    user_id: '',
    sub: '',
    email: '',
    email_verified: '',
    firebase: '',
  },

  isAuthenticated: false, // UI Visibility (no actual access)
  isAdmin: false, // UI Visibility (no actual access)
  authActivity: [],

  authTimers: {
    accessTokenTimer: null,
    accessTokenTimerSet: false,
    refreshTokenTimerSet: false,
  }
};

const resetState = {
  mfaSecurityRequired: false,   //If MFA UI should be switched on
  mfaSecurityRequiredTimeSet: '',
  //apiAccessToken: '', // Access (Resource Token)
  apiAccessTokenExpiresIn: '', // how many seconds (not that useful)
  apiAccessTokenExpiresOn: '', // timestamp that it expires (used)
  //apiRefreshToken: '', // Refresh (Authentication Token)
  apiRefreshTokenExpiresIn: '', // how many seconds (not that useful)
  apiRefreshTokenExpiresOn: '', // timestamp that it expires (used)
  //authToken: '',        // Firebase token

  //firebaseAccessToken: '',    // Firebase access token
  firebaseAuthData: {
    iss: '',
    aud: '',
    user_id: '',
    sub: '',
    email: '',
    email_verified: '',
    firebase: '',
  },

  isAuthenticated: false, // UI Visibility (no actual access)
  isAdmin: false, // UI Visibility (no actual access)
  authActivity: [],

  authTimers: {
    accessTokenTimer: null,
    accessTokenTimerSet: false,
    refreshTokenTimerSet: false,
  }
};


const AuthReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_AUTH':
    {
      return resetState;
    }

    case 'RESET_STATE_AUTH':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }
    
    //2020-02
    case 'SET_MFA_LOGIN_REQUIRED':
    {
      const clone = Object.assign({}, state);
      clone.mfaSecurityRequired = true;
      clone.mfaSecurityRequiredTimeSet = Date.now();
      return clone;
    }
    //2020-02
    case 'REMOVE_MFA_LOGIN_REQUIRED':
    {
      const clone = Object.assign({}, state);
      clone.mfaSecurityRequired = false;
      return clone;
    }


    
    //2019-10
    case 'SET_FIREBASE_ACCESS_TOKEN':
    {
      const clone = Object.assign({}, state);
      clone.firebaseAccessToken = action.firebaseAccessToken;

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'SET_FIREBASE_ACCESS_TOKEN', timestamp: currentTimestamp });

      return clone;
    }
    //2019-10
    case 'SET_FIREBASE_AUTH_DATA':
      {
        const clone = Object.assign({}, state);
        clone.firebaseAuthData.iss = action.firebaseAuthData.iss;
        clone.firebaseAuthData.aud = action.firebaseAuthData.aud;
        clone.firebaseAuthData.user_id = action.firebaseAuthData.user_id;
        clone.firebaseAuthData.sub = action.firebaseAuthData.sub;
        clone.firebaseAuthData.email = action.firebaseAuthData.email;
        clone.firebaseAuthData.email_verified = action.firebaseAuthData.email_verified;
        clone.firebaseAuthData.firebase = action.firebaseAuthData.firebase;

        // Record the auth Activities locally (for troubleshooting)
        const currentDate = new Date();
        const currentTimestamp = currentDate.getTime();
        clone.authActivity.push({ action: 'SET_FIREBASE_AUTH_DATA', timestamp: currentTimestamp });

        return clone;
      }

    //2019-10
    case 'SET_IS_AUTHENTICATED':
    {
      const clone = Object.assign({}, state);
      clone.isAuthenticated = action.status;

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'SET_IS_AUTHENTICATED', timestamp: currentTimestamp });

      return clone;
    }

    
    //2019-10
    case 'UPDATE_AUTH_TIMER_ACCESS_TOKEN_TIMER':
    {
      const clone = Object.assign({}, state);
      clone.authTimers.accessTokenTimerSet = action.status;

      return clone;
    }
    //2019-10
    case 'UPDATE_AUTH_TIMER_REFRESH_TOKEN_TIMER':
    {
      const clone = Object.assign({}, state);
      clone.authTimers.refreshTokenTimerSet = action.status;

      return clone;
    }

    case 'AUTH_FAILURE_LOG':
    {
      const clone = Object.assign({}, state);
      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'AUTH_FAILURE_LOG', timestamp: currentTimestamp, error: action.error });
      return clone;
    }

    case 'SET_AUTH_TOKENS':
    {
      const clone = Object.assign({}, state);
      //console.log('REDUCER | SET_AUTH_TOKENS');
      //console.log(action);
      //clone.apiAccessToken = action.tokens.accessToken;       //Removed from storing in Redux.
      global.gpAccessToken = action.tokens.accessToken;         //Storing in Memory rather than Redux (Session Storage).
      clone.apiAccessTokenExpiresIn = action.tokens.accessTokenExpiresIn;
      clone.apiAccessTokenExpiresOn = action.tokens.accessTokenExpiresOn;
      //clone.apiRefreshToken = action.tokens.refreshToken;     //Removed from storing in Redux.
      global.gpRefreshToken = action.tokens.refreshToken;         //Storing in Memory rather than Redux (Session Storage).
      clone.apiRefreshTokenExpiresIn = action.tokens.refreshTokenExpiresIn;
      clone.apiRefreshTokenExpiresOn = action.tokens.refreshTokenExpiresOn;

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({
        action: 'SET_TOKENS', timestamp: currentTimestamp, accessExpiry: action.tokens.accessTokenExpiresOn, refreshExpiry: action.tokens.refreshTokenExpiresOn,
      });

      clone.isAuthenticated = true;
      return clone;
    }
    
    case 'SET_USER_FIREBASE_OBJ':
    {
      const clone = Object.assign({}, state);

      console.error("SET_USER_FIREBASE_OBJ has been run....");

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'SET_USER_FIREBASE_OBJ', timestamp: currentTimestamp });

      return clone;
    }
    case 'SET_USER_AUTH':
    {
      const clone = Object.assign({}, state);
      //console.log('user auth');
      //clone.apiAccessToken = action.data.accessToken;       //Removed from storing in Redux.
      global.gpAccessToken = action.data.accessToken;         //Storing in Memory rather than Redux (Session Storage).
      clone.apiAccessTokenExpiresIn = action.data.accessTokenExpiresIn;
      clone.apiAccessTokenExpiresOn = action.data.accessTokenExpiresOn;
      //clone.apiRefreshToken = action.data.refreshToken;     //Removed from storing in Redux.
      global.gpRefreshToken = action.data.refreshToken;         //Storing in Memory rather than Redux (Session Storage).
      clone.apiRefreshTokenExpiresIn = action.data.refreshTokenExpiresIn;
      clone.apiRefreshTokenExpiresOn = action.data.refreshTokenExpiresOn;

      clone.authToken = action.data.accessToken;

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();

      //Check if clone.authActivity exists


      let authActivityArray = false;
      try {
        authActivityArray = _.get(clone, `authActivity`, false);
      } catch(e) {}
      if (authActivityArray === false) {
        clone.authActivity = [];
        clone.authActivity.push({
          action: 'SET_USER_AUTH', timestamp: currentTimestamp, accessExpiry: action.data.accessTokenExpiresOn, refreshExpiry: action.data.refreshTokenExpiresOn,
        });
      } else {
        clone.authActivity.push({
          action: 'SET_USER_AUTH', timestamp: currentTimestamp, accessExpiry: action.data.accessTokenExpiresOn, refreshExpiry: action.data.refreshTokenExpiresOn,
        });
      }

      clone.isAuthenticated = true;
      return clone;
    }

    case 'REMOVE_USER_AUTH':
    {
      //console.log('removing user auth');
      return initialState;
    }



    case 'SET_LOGGEDIN_USER':
    {
      const clone = Object.assign({}, state);
      clone.user = action.user;
      clone.loggedInEvent = true;

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'SET_LOGGEDIN_USER', timestamp: currentTimestamp });

      clone.isAuthenticated = true;
      return clone;
    }

    case 'SET_LOGGEDOUT_USER':
    {
      const clone = Object.assign({}, state);
      clone.user = '';

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'SET_LOGGEDOUT_USER', timestamp: currentTimestamp });

      clone.isAuthenticated = false;
      return clone;
    }

    case 'SET_AUTHENTICATED':
    {
      const clone = Object.assign({}, state);

      // Record the auth Activities locally (for troubleshooting)
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      clone.authActivity.push({ action: 'SET_AUTHENTICATED', timestamp: currentTimestamp });

      clone.isAuthenticated = action.loginValue;
      return clone;
    }


    default:
      return state;
  }
};

export default AuthReducer;
