//Common actions for API Monitoring//
class ApiMonitoring {

  static updateApiSuccessCounter = (apiMonitoring) => ({
    type: 'UPDATE_API_SUCCESS_COUNT',
    apiMonitoring
  });
  static updateApiFailureCounter = (apiMonitoring) => ({
    type: 'UPDATE_API_FAILURE_COUNT',
    apiMonitoring
  });
  static updateApiInProgressCounter = (apiMonitoring) => ({
    type: 'UPDATE_API_INPROGRESS_COUNT',
    apiMonitoring
  });
  static updateApiStatusCodeCounter = (statusCode) => ({
    type: 'UPDATE_API_STATUS_CODES',
    statusCode
  });
  
}

export default ApiMonitoring;

