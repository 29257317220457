import Request from './Request';

class VerifyPhoneApi {

  static verifyUserPhoneNumberWithCall(body, accessToken) {
    return Request.POST_json(`${process.env.verifyPhoneAPI}/user`, body, global.gpAccessToken);
  }
  static verifyBusinessPhoneNumberWithCall(body, accessToken) {
    return Request.POST_json(`${process.env.verifyPhoneAPI}/business`, body, global.gpAccessToken);
  }
}
export default VerifyPhoneApi;
