/** AUTH ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import CommonActions from './CommonActions';
import ApiUI from './UI';


import AuthApi from './api/AuthApi';
import UsersApi from './api/UsersApi';

import { auth, db } from 'Store/client';

import isEqual from 'react-fast-compare';
import moment from 'moment';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_AUTH = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_AUTH = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

//Set MFA to true to update UI
export const setMfaLoginRequired = () => ({
  type: 'SET_MFA_LOGIN_REQUIRED',
});
export const removeMfaLoginRequired = () => ({
  type: 'REMOVE_MFA_LOGIN_REQUIRED',
});


//Used by firebase auth state change - reset (needs to be confirmed)
export const resetAllState = () => ({
  type: 'RESET_STATE',
});

export const setFirebaseAccessToken = firebaseAccessToken => ({
  type: 'SET_FIREBASE_ACCESS_TOKEN',
  firebaseAccessToken,
});
export const setFirebaseAuthData = firebaseAuthData => ({
  type: 'SET_FIREBASE_AUTH_DATA',
  firebaseAuthData,
});



export const updateIsAuthenticatedStatus = status => ({
  type: 'SET_IS_AUTHENTICATED',
  status,
});
// Used in access token refresh 
export const updateAuthTokens = tokens => ({
  type: 'SET_AUTH_TOKENS',
  tokens,
});


export const updateAuthTimerAccessTokenTimer = status => ({
  type: 'UPDATE_AUTH_TIMER_ACCESS_TOKEN_TIMER',
  status,
});
export const updateAuthTimerRefreshTokenTimer = status => ({
  type: 'UPDATE_AUTH_TIMER_REFRESH_TOKEN_TIMER',
  status,
});







export const setAuthToken = user => ({
  type: 'SET_USER_AUTH',
  data: user,
});



export const initializeUserId = user => ({
  type: 'INITIALIZE_USER_ID',
  user,
});
export const initializeUserData = user => ({
  type: 'INITIALIZE_USER_DATA',
  user,
});
export const initializeBusinessData = business => ({
  type: 'INITIALIZE_BUSINESS_DATA',
  business,
});


export const setUserData = user => ({
  type: 'SET_USER_DATA',
  user,
});
export const setUserFirebaseObj = user => ({
  type: 'SET_USER_FIREBASE_OBJ',
  data: user,
});
export const setBusinessIdentitiesData = businessIdentities => ({
  type: 'BUSINESS_IDENTITY_INIT_SET',
  data: businessIdentities,
});






export const authFailureLog = error => ({
  type: 'AUTH_FAILURE_LOG',
  error,
});
export const authFailureCount = () => ({
  type: 'AUTH_FAILURE_COUNT',
});



/*********************************************************************
 * AUTHENTICATION STATUS TIMERS
 *********************************************************************/
/*
Overview
- Every minute, check if the current access token is valid from a timer perspective. 
- If expired, request a new access token independent of a user event.
- We check every minute, and a request will only take a few seconds. 
- We need to check with at least 2 minutes to spare

*/

let authStatusCheck = setInterval(function() {
  console.groupCollapsed(`Auth Status | Check`);
  let accessRefreshRequired = requireAccessTokenRefresh();

  if (accessRefreshRequired === true) {
    //Perform refresh activity. 
    //Only do this if the refresh token is still valid and within time.

    
    let refreshTokenValid = isRefreshTokenValid();
    if (refreshTokenValid === false) {
      console.warn('REFRESH TOKEN HAS EXPIRED, LOGIN ON NEXT ACTION - show alert to log in again');

    } else {
      console.log('Ok to attempt access token refresh');
      if (global.gpAccessToken !== undefined && global.gpAccessToken !== '') {
        store.dispatch(refreshAccessToken({}));
      } else {
        console.log('Access token is null or undefined | relogin');
        dispatch(authStatusFalse_AUTH());
      }
      
    }

  }

  console.groupEnd();
}, 60000);  //Every Minute

function getAccessTokenExpiryTime() {
  const { apiAccessTokenExpiresOn } = store.getState().Auth;
  return apiAccessTokenExpiresOn;
}
function getRefreshTokenExpiryTime() {
  const { apiRefreshTokenExpiresOn } = store.getState().Auth;
  return apiRefreshTokenExpiresOn;
}

function requireAccessTokenRefresh() {
  //Expiry Date (final)
  let expiryTimestampSec = getAccessTokenExpiryTime();

  let expiryTimestampMs = expiryTimestampSec * 1000;
  console.log(`expiryTimestampMs: ${expiryTimestampMs}`);

  let expiryDateMs = new Date(expiryTimestampMs);
  console.log(`expiryDateMs: ${expiryDateMs}`);



  //The date we want to check up to (at least 2 minutes before expiry)
  let expiryTimestampBoundary = expiryDateMs.getTime() - 140*1000;    //over the 2 minutes
  console.log(`expiryTimestampBoundary: ${expiryTimestampBoundary}`);

  let expiryTimestampBoundaryMs = expiryTimestampBoundary.valueOf();
  console.log(`expiryTimestampBoundaryMs: ${expiryTimestampBoundaryMs}`);
  
  let expiryTimestampBoundarySec = unixTimestampMillisecondsToSeconds(expiryTimestampBoundaryMs);
          

  let currentTimestampSec = new Date().valueOf();
  currentTimestampSec = unixTimestampMillisecondsToSeconds(currentTimestampSec);

  console.log(`getSecondsBeforeAccessExpiry: ${currentTimestampSec} > ${expiryTimestampBoundarySec}`);

  //If the current time is great than the expiry boundary, we need to refresh.
  if (currentTimestampSec >= expiryTimestampBoundarySec) {
    console.log('We need to refresh the auth token.');
    return true;
  } else {
    console.log('We DO NOT need to refresh the auth token.');
    return false;  //normally return false
  }
}




function isRefreshTokenValid() {
  //Check the Refresh Token to see if it is valid.
  let expiryTimestampSec = getRefreshTokenExpiryTime();

  let expiryTimestampMs = expiryTimestampSec * 1000;
  console.log(`expiryTimestampMs: ${expiryTimestampMs}`);

  let expiryDateMs = new Date(expiryTimestampMs);
  console.log(`expiryDateMs: ${expiryDateMs}`);



  //The date we want to check up to (at least 2 minutes before expiry)
  let expiryTimestampBoundary = expiryDateMs.getTime() - 140*1000;    //over the 2 minutes
  console.log(`expiryTimestampBoundary: ${expiryTimestampBoundary}`);

  let expiryTimestampBoundaryMs = expiryTimestampBoundary.valueOf();
  console.log(`expiryTimestampBoundaryMs: ${expiryTimestampBoundaryMs}`);
  
  let expiryTimestampBoundarySec = unixTimestampMillisecondsToSeconds(expiryTimestampBoundaryMs);
          

  let currentTimestampSec = new Date().valueOf();
  currentTimestampSec = unixTimestampMillisecondsToSeconds(currentTimestampSec);

  console.log(`refreshTokenTimestamp: ${currentTimestampSec} > ${expiryTimestampBoundarySec}`);

  //If the current time is great than the expiry boundary, we need to refresh.
  if (currentTimestampSec >= expiryTimestampBoundarySec) {
    console.log('Refresh token as expired.');
    return false;
  } else {
    console.log('Refresh token is still valid.');
    return true;  //normally return false
  }

}





function unixTimestampMillisecondsToSeconds(timestamp) {
  /*let str = timestamp.toString();
  str = str.substring(0, str.length - 3);
  str = parseInt(str);*/

  //console.log("Function | unixTimestampMillisecondsToSeconds");
  console.groupCollapsed();
  let num = timestamp;
  //console.log(num);
  //console.log(num.toString());
  //console.log(num.toString().length);

  if (num.toString().length >=13) {
    //In milliseconds
    //console.log("Millisecond conversion");
    num = (num-(num%1000))/1000;
    //console.log(`new value: ${num}`);
  }
  console.groupEnd();
  return num;
}


//



///////////////////////////////////////////////////////
// LOGOUT USER AND DESTROY LOCAL DATA
///////////////////////////////////////////////////////
export function logoutUser(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      
      dispatch(CommonActions.CLEAR_STATE_CORE()); 
      dispatch(CommonActions.CLEAR_STATE_ADMIN_WORK()); 
      dispatch(CommonActions.CLEAR_STATE_API_ACTIONS()); 
      dispatch(CommonActions.CLEAR_STATE_APP()); 
      dispatch(CommonActions.CLEAR_STATE_AUTH()); 
      dispatch(CommonActions.CLEAR_STATE_BUSINESS_IDENTITIES()); 
      dispatch(CommonActions.CLEAR_STATE_COMMON_ACTIONS()); 
      dispatch(CommonActions.CLEAR_STATE_NOTIFICATIONS()); 
      dispatch(CommonActions.CLEAR_STATE_PRODUCT()); 
      dispatch(CommonActions.CLEAR_STATE_REPORTING()); 
      dispatch(CommonActions.CLEAR_STATE_SIDEBAR()); 
      dispatch(CommonActions.CLEAR_STATE_SOCKET()); 
      dispatch(CommonActions.CLEAR_STATE_SYNC()); 
      dispatch(CommonActions.CLEAR_STATE_UI()); 
      dispatch(CommonActions.CLEAR_STATE_USER()); 
      dispatch(CommonActions.CLEAR_STATE_VERIFY()); 
      

      resolve(true);
    }));
  };
}




















//////////////////////////////////////
// MFA LOGIN
// User logs in with U & P. Then prompted
// for Multi-factor login. This is the 
// login event from entering in the MFA
// value
//////////////////////////////////////
export function mfaLogin(body = {}) {
  return (dispatch, getState) => {
    const { firebaseAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      console.warn(`MFA LOGIN`);

      const { data } = body;

      let mfaCode = '';
      try {
        mfaCode = data.code;
      } catch (e) {}

      let userId = id;


      //console.log(`userId: ${userId}`);

      //console.log("firebaseAccessToken");
      //console.log(firebaseAccessToken);

      const apiBody = JSON.stringify({
        mfaCode: mfaCode,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'mfaLogin',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AuthApi.mfaLogin(userId, apiBody, firebaseAccessToken)
      .then((apiResponseData) => {
        let toastData = {};
        console.warn('API | RESPONSE | MFA Login');
        dispatch(removeMfaLoginRequired());

        if (apiResponseData.status === true) {
          
          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            dispatch(setAuthToken(apiResponseData.data.authResult));
            dispatch(initializeUserData(apiResponseData.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
            dispatch(initializeBusinessData(apiResponseData.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.

            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }
              dispatch(addGlobalToastMessage_AUTH(toastData));

            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_AUTH());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_AUTH(toastData));
            }
          }

          

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_AUTH({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        }

        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve({
          appStatus: apiResponseData.appStatus,
        });

      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });

    }));
  };
}








/** *************************************
****** GUARD POINT LOGIN V2 ***************
************************************** */

export function guardpointUserAuth(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { user } = body;
      console.groupCollapsed('Login');
      //console.log('FN: guardpointUserAuth');
      //console.log(JSON.stringify(user));
      //console.log(user.credential);
      //console.log(auth().currentUser.getIdTokenResult());
      console.groupEnd();

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'guardpointUserAuth',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      auth().currentUser.getIdToken()
        .then(async (firebaseToken) => {
          console.table([
            {
              firebaseToken,
            },
          ]);
          dispatch(setFirebaseAccessToken(firebaseToken));

          global.fbAccessToken = firebaseToken;

          const idTokenResult = await auth().currentUser.getIdTokenResult();

          const firebaseAuthData = {
            iss: idTokenResult.claims.iss,
            aud: idTokenResult.claims.aud,
            user_id: idTokenResult.claims.user_id,
            sub: idTokenResult.claims.sub,
            email: idTokenResult.claims.email,
            email_verified: idTokenResult.claims.email_verified,
            firebase: idTokenResult.claims.firebase,
          }
          dispatch(setFirebaseAuthData(firebaseAuthData));

          //loginType

          const apiBody = JSON.stringify({
            loginType: "webApp",
          });
          

          return AuthApi.loginV2(apiBody, firebaseToken);
        })
        .then((apiResponseData) => {
          let toastData = {};
          console.warn('API | RESPONSE | LoginV2');

          if (apiResponseData.status === true) {


            
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              if (apiResponseData.data.mfaSecurityRequired === true) {
                //User to enter MFA Security Code

                dispatch(setMfaLoginRequired());
                dispatch(initializeUserId(apiResponseData.data.userResult));    //Only set the ID

              } else {

                //NEW CONCEPT
                console.log("Attempt to write JWT to memory");
                console.log(apiResponseData);
                //WRITE JWT TOKENS TO MEMORY
                let accessToken = '';
                try {
                  accessToken = apiResponseData.data.authResult.accessToken;
                } catch (e) {
                  accessToken = '';
                }
                if (accessToken === undefined) {
                  accessToken = '';
                }
                
                let refreshToken = '';
                try {
                  refreshToken = apiResponseData.data.authResult.refreshToken;
                } catch (e) {
                  refreshToken = '';
                }
                if (refreshToken === undefined) {
                  refreshToken = '';
                }
                

                global.gpAccessToken = accessToken;


                dispatch(setAuthToken(apiResponseData.data.authResult));
                dispatch(initializeUserData(apiResponseData.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
                dispatch(initializeBusinessData(apiResponseData.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.


                //Display a success Toast if configured
                if (apiResponseData.data.appMessageSuccess !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageSuccess, 
                    type: 'success',
                  }
                  dispatch(addGlobalToastMessage_AUTH(toastData));
                }
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_AUTH());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_AUTH(toastData));
              }
            }

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_AUTH({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
            /*
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
            */
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            //dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          //console.log('Error: AuthUsers.login');
          //console.log(error);
          dispatch(authFailureLog(error)); // Record the failure log
          dispatch(authFailureCount()); // Record the failure count


          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });


    }));
  };
}


//////////////////////////////////////
// MFA LOGIN V2
// User logs in with U & P. Then prompted
// for Multi-factor login. This is the 
// login event from entering in the MFA
// value
//////////////////////////////////////
export function loginMfaV2(body = {}) {
  return (dispatch, getState) => {
    const { firebaseAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      console.warn(`MFA LOGIN V2`);

      const { data } = body;

      let mfaLoginData = '';
      try {
        mfaLoginData = data.mfaLoginData;
      } catch (e) {
        mfaLoginData = '';
      }
      if (mfaLoginData === undefined) {
        mfaLoginData = '';
      }

      let userId = id;


      //console.log(`userId: ${userId}`);

      //console.log("firebaseAccessToken");
      //console.log(firebaseAccessToken);

      const apiBody = JSON.stringify({
        mfaLoginData: mfaLoginData,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'loginMfaV2',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AuthApi.loginMfaV2(userId, apiBody, firebaseAccessToken)
      .then((apiResponseData) => {
        let toastData = {};
        console.warn('API | RESPONSE | MFA Login V2');
        dispatch(removeMfaLoginRequired());

        if (apiResponseData.status === true) {
          
          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {

            

            dispatch(setAuthToken(apiResponseData.data.authResult));
            dispatch(initializeUserData(apiResponseData.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
            dispatch(initializeBusinessData(apiResponseData.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.

            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }
              dispatch(addGlobalToastMessage_AUTH(toastData));

            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_AUTH());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_AUTH(toastData));
            }
          }

          

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_AUTH({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));
          /*
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          */
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          //dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        }

        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve({
          appStatus: apiResponseData.appStatus,
        });

      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });

    }));
  };
}














/** *************************************
****** LOGIN VIA WEBSITE ***************
************************************** */

export function userAuthEmail(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { user } = body;
      console.groupCollapsed('Login');
      //console.log('FN: userAuthEmail');
      //console.log(JSON.stringify(user));
      //console.log(user.credential);
      //console.log(auth().currentUser.getIdTokenResult());
      console.groupEnd();

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'userAuthEmail',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      auth().currentUser.getIdToken()
        .then(async (firebaseToken) => {
          console.table([
            {
              firebaseToken,
            },
          ]);
          dispatch(setFirebaseAccessToken(firebaseToken));

          global.fbAccessToken = firebaseToken;

          const idTokenResult = await auth().currentUser.getIdTokenResult();

          const firebaseAuthData = {
            iss: idTokenResult.claims.iss,
            aud: idTokenResult.claims.aud,
            user_id: idTokenResult.claims.user_id,
            sub: idTokenResult.claims.sub,
            email: idTokenResult.claims.email,
            email_verified: idTokenResult.claims.email_verified,
            firebase: idTokenResult.claims.firebase,
          }
          dispatch(setFirebaseAuthData(firebaseAuthData));

          //loginType

          const apiBody = JSON.stringify({
            loginType: "webApp",
          });
          

          return AuthApi.login(apiBody, firebaseToken);
        })
        .then((apiResponseData) => {
          let toastData = {};
          console.warn('API | RESPONSE | Login');

          if (apiResponseData.status === true) {


            
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              if (apiResponseData.data.mfaSecurityRequired === true) {
                //User to enter MFA Security Code

                dispatch(setMfaLoginRequired());
                dispatch(initializeUserId(apiResponseData.data.userResult));    //Only set the ID

              } else {
                let accessToken = '';
                try {
                  accessToken = apiResponseData.data.authResult.accessToken;
                } catch (e) {
                  accessToken = '';
                }
                if (accessToken === undefined) {
                  accessToken = '';
                }
                
                let refreshToken = '';
                try {
                  refreshToken = apiResponseData.data.authResult.refreshToken;
                } catch (e) {
                  refreshToken = '';
                }
                if (refreshToken === undefined) {
                  refreshToken = '';
                }

                global.gpAccessToken = accessToken;

                dispatch(setAuthToken(apiResponseData.data.authResult));
                dispatch(initializeUserData(apiResponseData.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
                dispatch(initializeBusinessData(apiResponseData.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.

                
                //Display a success Toast if configured
                if (apiResponseData.data.appMessageSuccess !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageSuccess, 
                    type: 'success',
                  }
                  dispatch(addGlobalToastMessage_AUTH(toastData));
                }
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_AUTH());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_AUTH(toastData));
              }
            }

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_AUTH({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          //console.log('Error: AuthUsers.login');
          //console.log(error);
          dispatch(authFailureLog(error)); // Record the failure log
          dispatch(authFailureCount()); // Record the failure count


          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });


    }));
  };
}

export function mobileAppAutoLogin(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { firebaseToken } = body;
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.

      const apiBody = JSON.stringify({
        loginType: "mobileApp",
      });

      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'mobileAppAutoLogin',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AuthApi.login(apiBody, firebaseToken)
      .then((apiResponseData) => {
        let toastData = {};
        console.warn('API | RESPONSE | Login');

        if (apiResponseData.status === true) {
          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            if (apiResponseData.data.mfaSecurityRequired === true) {
              //User to enter MFA Security Code

              dispatch(setMfaLoginRequired());
              dispatch(initializeUserId(apiResponseData.data.userResult));    //Only set the ID

            } else {
              dispatch(setAuthToken(apiResponseData.data.authResult));
              dispatch(initializeUserData(apiResponseData.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
              dispatch(initializeBusinessData(apiResponseData.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.


              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_AUTH(toastData));
              }
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_AUTH());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_AUTH(toastData));
            }
          }

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_AUTH({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        }

        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);

      })
      .catch((error) => {
        //console.log('Error: AuthUsers.login');
        //console.log(error);
        dispatch(authFailureLog(error)); // Record the failure log
        dispatch(authFailureCount()); // Record the failure count


        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });


    }));
  };
}


/** *************************************
****** SIGNUP VIA WEBSITE ***************
************************************** */

export function userSignupEmail(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { userData, data } = body;

      let userDataRegion = '';
      try {
        userDataRegion = data.userDataRegion;
      } catch (e) {}

      //console.log("userDataRegion");
      //console.log(userDataRegion);

      console.groupCollapsed('Login');
      //console.log('FN: userSignupEmail');
      //console.log(JSON.stringify(userData));
      //console.log(userData.credential);
      //console.log(auth().currentUser.getIdTokenResult());
      console.groupEnd();

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'userSignupEmail',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      auth().currentUser.getIdToken()
        .then(async (firebaseToken) => {
          console.table([
            {
              firebaseToken,
            },
          ]);
          dispatch(setFirebaseAccessToken(firebaseToken));

          const idTokenResult = await auth().currentUser.getIdTokenResult();

          const firebaseAuthData = {
            iss: idTokenResult.claims.iss,
            aud: idTokenResult.claims.aud,
            user_id: idTokenResult.claims.user_id,
            sub: idTokenResult.claims.sub,
            email: idTokenResult.claims.email,
            email_verified: idTokenResult.claims.email_verified,
            firebase: idTokenResult.claims.firebase,
          }
          dispatch(setFirebaseAuthData(firebaseAuthData));

          //loginType

          const apiBody = JSON.stringify({
            loginType: "webApp",
            userDataRegion,
          });
          

          return AuthApi.signup(userDataRegion, apiBody, firebaseToken);
        })
        .then((apiResponseData) => {
          let toastData = {};
          console.warn('API | RESPONSE | Signup');

          if (apiResponseData.status === true) {


            
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              if (apiResponseData.data.mfaSecurityRequired === true) {
                //User to enter MFA Security Code

                dispatch(setMfaLoginRequired());
                dispatch(initializeUserId(apiResponseData.data.userResult));    //Only set the ID

              } else {
                dispatch(setAuthToken(apiResponseData.data.authResult));
                dispatch(initializeUserData(apiResponseData.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
                dispatch(initializeBusinessData(apiResponseData.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.


                //Display a success Toast if configured
                if (apiResponseData.data.appMessageSuccess !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageSuccess, 
                    type: 'success',
                  }
                  dispatch(addGlobalToastMessage_AUTH(toastData));
                }
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_AUTH());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_AUTH(toastData));
              }
            }

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_AUTH({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          //console.log('Error: AuthUsers.login');
          //console.log(error);
          dispatch(authFailureLog(error)); // Record the failure log
          dispatch(authFailureCount()); // Record the failure count


          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });


    }));
  };
}




















































/*******************************************
 * setInterval - JwtRefreshTokenManager
 * Just check what the impact is
 */







export function JwtRefreshTokenManager(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessTokenExpiresOn, apiRefreshTokenExpiresOn } = getState().Auth;
    return new Promise(((resolve, reject) => {
      /*****************************************************************************
       * JWT Refresh Manager
       * - If the access token is close to expiring, requests a new one.
       * - If the access token is NOT close to expiring, set a timer for refreshing the access token
       * 
       * - If the refresh token is close to expiring, request a new one.
       * - If the refresh token is NOT close to expiring, set a timer for refreshing the refresh token
       * 
       * ************************************************************************* */





      console.groupCollapsed("JWT Refresh Manager");
      //console.log(`=== JwtRefreshTokenManager ====`);
      /*******************************************************
       * NOTE: THE JWT TOKENS need to be compared to UTC time.
       * The tokens can be generated from any region, we need to 
       * standardise on UTC for verification.
       *******************************************************/
      ///// GET CORE VARIABLES
      let accessTokenTime = unixTimestampMillisecondsToSeconds(apiAccessTokenExpiresOn);
      let refreshTokenTime = unixTimestampMillisecondsToSeconds(apiRefreshTokenExpiresOn);
      let currentTime = unixTimestampMillisecondsToSeconds(Date.now().valueOf());

      let continueProcessing = true;
      //If no valid access token - exit
      if (apiAccessTokenExpiresOn == null || apiAccessTokenExpiresOn == undefined) {
        continueProcessing = false;
      }
      //If no valid access token - exit
      if (apiRefreshTokenExpiresOn == null || apiRefreshTokenExpiresOn == undefined) {
        continueProcessing = false;
      }
      //If refresh token has expired
      if (currentTime >= apiRefreshTokenExpiresOn) {
        continueProcessing = false;
      }

      ////let refreshAccessTokenTime = unixTimestampMillisecondsToSeconds(apiRefreshTokenExpiresOn);
      

      if (continueProcessing) {

        // - If the refresh token is close to expiring, request a new one.
        // - If the refresh token is NOT close to expiring, set a timer for refreshing the refresh token
        // - If the access token is close to expiring, requests a new one.
        // - If the access token is NOT close to expiring, set a timer for refreshing the access token

        //console.log(`accessTokenTime: ${accessTokenTime}`);
        //console.log(`refreshTokenTime: ${refreshTokenTime}`);
        //console.log(`currentTime: ${currentTime}`);



        let timeDifferenceBetweenAccessTokenAndNow = accessTokenTime - currentTime;   //In seconds
        let timeDifferenceBetweenRefreshTokenAndNow = refreshTokenTime - currentTime;   //In seconds

        //console.log(`timeDifferenceBetweenAccessTokenAndNow: ${timeDifferenceBetweenAccessTokenAndNow}`);
        //console.log(`timeDifferenceBetweenRefreshTokenAndNow: ${timeDifferenceBetweenRefreshTokenAndNow}`);


        ///// SET THE BOUNDARIES FOR TOKEN REFRESHES 

        let minimumTimeRemaining_ACCESSTOKEN = 10;      //The minimum time remaining before we need to refresh the access tokens.
        //let minimumTimeBeforeChecking_ACCESSTOKEN = 10; //The interval we remove before checking again
        let additionalTimeToWait_ACCESSTOKEN = 10;      //Additional gap to make sure the refresh happens

        let minimumTimeRemaining_REFRESHTOKEN = 60;       //The minimum time remaining before we need to refresh the refresh tokens.
        //let minimumTimeBeforeChecking_REFRESHTOKEN = 30;  //The interval we remove before checking again
        let additionalTimeToWait_REFRESHTOKEN = 10;       //Additional gap to make sure the refresh happens


        //console.log(`minimumTimeRemaining_ACCESSTOKEN: ${minimumTimeRemaining_ACCESSTOKEN}`);
        //console.log(`if (${timeDifferenceBetweenAccessTokenAndNow} <= ${minimumTimeRemaining_ACCESSTOKEN}) get a new access token`);

        //console.log(`minimumTimeRemaining_ACCESSTOKEN: ${minimumTimeRemaining_REFRESHTOKEN}`);
        //console.log(`if (${timeDifferenceBetweenRefreshTokenAndNow} <= ${minimumTimeRemaining_REFRESHTOKEN}) get a new refresh token`);

        ///////////////////////////////////
        ///// REFRESH TOKEN ACTIVITIES
        ///////////////////////////////////
        let refreshTheAccessToken = true;
        if (timeDifferenceBetweenRefreshTokenAndNow <= minimumTimeRemaining_REFRESHTOKEN) {
          console.warn("Get a new Refresh token (and Access Token)");
          //dispatch(refreshRefreshToken({}));
          ///// Update timer status in auth state
          dispatch(updateAuthTimerRefreshTokenTimer(true));
 
          

          refreshTheAccessToken = false;  //When we refresh the "Refresh Token" we will get a new Access Token. So don't attempt an Access Token refresh.
        } else {
          console.warn("Set a timer to refresh the Refresh token");
          // We need to set a timer to call the "JwtRefreshTokenManager" within the time window of minium time remaining so a token can be requested.
          // timeDifferenceBetweenRefreshTokenAndNow - minimumTimeRemaining_REFRESHTOKEN + additionalTimeToWait_REFRESHTOKEN
          
          let refreshTokenTimerSet = false;
          try {
            refreshTokenTimerSet = getState().Auth.authTimers.refreshTokenTimerSet;
          } catch (e) {}

          if (refreshTokenTimerSet === false) {   //No timers set, we can set one.
            let timerRefreshWaitTime = timeDifferenceBetweenRefreshTokenAndNow - minimumTimeRemaining_REFRESHTOKEN + additionalTimeToWait_REFRESHTOKEN;
            let timerRefreshWaitTimeMs = timerRefreshWaitTime*1000;
            console.warn(`WAIT ${timerRefreshWaitTime} seconds (${timerRefreshWaitTimeMs} milliseconds) before attempting to try and refresh this "Refresh Token"`);

            
            let RefreshTimer = setTimeout(() => {
              console.warn(`TIMER | Refresh Token Request via timer.`);
              ///// Update timer status in auth state (the timer has now expired, there are no new timers.)
              dispatch(updateAuthTimerRefreshTokenTimer(false));
              ///// Dispatch the JWT Manager who will determine what tokens need to be refreshed.
              dispatch(JwtRefreshTokenManager({}));

            }, timerRefreshWaitTimeMs);
            

            // We just set a timer, update state to reflect this
            dispatch(updateAuthTimerRefreshTokenTimer(true));
            

          } else {  //Timers are set, don't set another timer
            console.warn("There is already a timer for Refresh Token - Don't set another");
          }


        }
        ///////////////////////////////////
        ///// ACCESS TOKEN ACTIVITIES
        ///////////////////////////////////
        if (refreshTheAccessToken === true) {
          if (timeDifferenceBetweenAccessTokenAndNow <= minimumTimeRemaining_ACCESSTOKEN) {
            console.warn("Get a new Access token");
            dispatch(refreshAccessToken({}));
            ///// Update timer status in auth state
            dispatch(updateAuthTimerAccessTokenTimer(true));
            console.groupEnd();
            
          } else {
            console.warn("Set a timer to refresh the Access token");
            let timerAccessWaitTime = timeDifferenceBetweenAccessTokenAndNow - minimumTimeRemaining_ACCESSTOKEN + additionalTimeToWait_ACCESSTOKEN;
            let timerAccessWaitTimeMs = timerAccessWaitTime*1000;
            console.warn(`WAIT ${timerAccessWaitTime} seconds (${timerAccessWaitTimeMs} milliseconds) before attempting to try and refresh this "Access Token"`);

            let accessTokenTimerSet = false;
            try {
              accessTokenTimerSet = getState().Auth.authTimers.accessTokenTimerSet;
            } catch (e) {}

            console.warn(`accessTokenTimerSet is set to ${accessTokenTimerSet}`);
            
            if (accessTokenTimerSet === false) {   //No timers set, we can set one.
              let AccessTimer = setTimeout(() => {
                console.warn(`TIMER | Access Token Request via timer.`);

                ///// Update timer status in auth state (the timer has now expired, there are no new timers.)
                dispatch(updateAuthTimerAccessTokenTimer(false));
                ///// Dispatch the JWT Manager who will determine what tokens need to be refreshed.
                dispatch(JwtRefreshTokenManager({}));

              }, timerAccessWaitTimeMs);

              // We just set a timer, update state to reflect this
              dispatch(updateAuthTimerAccessTokenTimer(true));

            } else {  //Timers are set, don't set another timer
              console.warn("There is already a timer for Access Token - Don't set another");
            }

            
            
          }
        } else {
          console.warn("We are refreshing the Refresh token, don't attempt a access token refresh");
        }
        







        ///////////////////////////////// NEW CODE ABOVE













        /*
        let minimumTimeRemaining = 20;      //The minimum time remaining before we need to refresh the tokens.
        let minimumTimeBeforeChecking = 10; //The interval we remove before checking again
        let additionalTimeToWait = 20;


        //console.log('Tokens are suitable for processing. continue.');

        

        

        //console.log(`accessTokenTime: ${accessTokenTime}`);
        //console.log(`refreshTokenTime: ${accessTokenTime}`);
        //console.log(`currentTime: ${currentTime}`);
        //console.log(`timeDifferenceBetweenAccessTokenAndNow: ${timeDifferenceBetweenAccessTokenAndNow}`);
        //console.log(`minimumTimeRemaining: ${minimumTimeRemaining}`);
        //console.log(`if (${timeDifferenceBetweenAccessTokenAndNow} <= ${minimumTimeRemaining}) get a new token`);

        // Set default
        let targetAccessTokenTimeRefreshInSeconds = 180;
        
        ///// DIFFERENCE
        //let refreshAccessTokenTimeDiffToNow = refreshAccessTokenTime - currentTime;   //seconds
        
        //In Seconds
        if (timeDifferenceBetweenAccessTokenAndNow <= minimumTimeRemaining) {
          console.warn(`Get a new Refresh Token now`);
          dispatch(refreshAccessToken({}));
          ///// Update timer status in auth state
          dispatch(updateAuthTimerRefreshTokenTimer(true));
          console.groupEnd();
          resolve();

        } else {
          //console.log(`Determine if we need to set a new timer`);
          targetAccessTokenTimeRefreshInSeconds = timeDifferenceBetweenAccessTokenAndNow - minimumTimeBeforeChecking;
          //console.log(`accessTokenExpiresIn: ${timeDifferenceBetweenAccessTokenAndNow}`);
          //console.log(`we will attempt to check/refresh the access token in : ${targetAccessTokenTimeRefreshInSeconds}`);

          //convert to milliseconds for setTimeout() paramter
          targetAccessTokenTimeRefreshInSeconds = targetAccessTokenTimeRefreshInSeconds * 1000;


          let refreshTokenTimerSet = false;
          try {
            refreshTokenTimerSet = getState().Auth.authTimers.refreshTokenTimerSet;
          } catch (e) {}

          if (refreshTokenTimerSet === false) {
            console.warn(`refreshTokenTimerSet === false | set a new timer`);

            setTimeout(() => {
              console.warn(`TIMER | Refresh Token Request via timer.`);

              // Confirm that the refresh token is still valid before requesting a new access token
              let timerApiRefreshTokenExpiresOn = getState().Auth.apiRefreshTokenExpiresOn;
              // Check if there is an existing timer already set, cancel this timer if already set.
              let refreshTokenTimerSet = false;
              try {
                refreshTokenTimerSet = getState().Auth.authTimers.refreshTokenTimerSet;
              } catch (e) {}


              let timerRefreshAccessTokenTime = unixTimestampMillisecondsToSeconds(timerApiRefreshTokenExpiresOn);
              let timerCurrentTime = unixTimestampMillisecondsToSeconds(Date.now().valueOf());
     
              // Get difference between refresh token and current time to determine if we can refresh the access token
              let timeDifferenceBetweenRefreshTokenAndNow = timerRefreshAccessTokenTime - timerCurrentTime;   //In seconds


              //console.log(`Timer | Checks`);
              //console.log(`timerRefreshAccessTokenTime: ${timerRefreshAccessTokenTime}`);
              //console.log(`timerCurrentTime: ${timerCurrentTime}`);
              //console.log(`timeDifferenceBetweenRefreshTokenAndNow: ${timeDifferenceBetweenRefreshTokenAndNow}`);


              //console.log(`if (${timeDifferenceBetweenRefreshTokenAndNow} <= ${minimumTimeRemaining}) get a new token`);

              //We are just checking to make sure the refresh token has time left so we don't waste an api call.
              if (timeDifferenceBetweenRefreshTokenAndNow > 0) {
                //console.log(`Refresh Token from Timer: Refresh now.`);
                dispatch(refreshAccessToken({}));

              } else {
                console.warn(`refresh token has expired, fail out and return to login page.`);
                dispatch(updateAuthTimerRefreshTokenTimer(false));    //There is no timers set, reset

                //Redirect to login page
                //TODO
                




                /*
                //If there are no other timers, set one again.-active
                if (refreshTokenTimerSet === false) {
                  console.warn(`There are no timers set, we should set one.`);
                  
                  setTimeout(() => {
                    refreshAccessToken({});
                  }, additionalTimeToWait);

                  ///// Update timer status in auth state
                  dispatch(updateAuthTimerRefreshTokenTimer(true));

                } else {
                  console.warn(`There is an existing timer set, don't set another one.`);
                }
                *//*******
                

              }
            }, targetAccessTokenTimeRefreshInSeconds, additionalTimeToWait, minimumTimeRemaining);

            ///// Update timer status in auth state
            dispatch(updateAuthTimerRefreshTokenTimer(true));

          } else {
            //console.log(`refreshTokenTimerSet === true | do not set a new timer`);
          }
          console.groupEnd();
          resolve();
        }
        */
       console.groupEnd();
       resolve();
      } else {
        console.warn('Refresh token expired before timer set.Cancelling the timer and redirecting to logon.');
        dispatch(updateAuthTimerAccessTokenTimer(false));    //There is no timers set, reset
        dispatch(updateAuthTimerRefreshTokenTimer(false));    //There is no timers set, reset
        console.groupEnd();
        resolve();
      }
      console.groupEnd();

    }));
  };
}

/*
export function JwtRefreshTokenManager(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessTokenExpiresOn, apiRefreshTokenExpiresOn } = getState().Auth;
    return new Promise(((resolve, reject) => {
      console.group("JWT Refresh Manager");
      //console.log(`=== JwtRefreshTokenManager ====`);
      /*******************************************************
       * NOTE: THE JWT TOKENS need to be compared to UTC time.
       * The tokens can be generated from any region, we need to 
       * standardise on UTC for verification.
       *******************************************************//*
      ///// GET CORE VARIABLES
      let accessTokenTime = unixTimestampMillisecondsToSeconds(apiAccessTokenExpiresOn);
      let refreshTokenTime = unixTimestampMillisecondsToSeconds(apiRefreshTokenExpiresOn);
      let currentTime = unixTimestampMillisecondsToSeconds(Date.now().valueOf());




      
      let continueProcessing = true;
      ///// Check - Is there a refresh token present
      if (apiRefreshTokenExpiresOn == null || apiRefreshTokenExpiresOn == undefined) {
        continueProcessing = false;
      }
      ///// Check - Has the refresh token expired
      if (currentTime >= apiRefreshTokenExpiresOn) {
        continueProcessing = false;
      }


      


      let refreshAccessTokenTime = unixTimestampMillisecondsToSeconds(apiRefreshTokenExpiresOn);
      

      

      if (continueProcessing) {
        //console.log('Refresh token ok to process timer - exists and hasnt expired');

        let minimumTimeRemaining = 20;      //The minimum time remaining before we need to refresh the tokens.
        let minimumTimeBeforeChecking = 10; //The interval we remove before checking again
        let additionalTimeToWait = 20;

        

        ///// DIFFERENCE
        let refreshAccessTokenTimeDiffToNow = refreshAccessTokenTime - currentTime;   //seconds
        
        let targetRefreshAccessTokenTimeInMinutes = 1440;

        //console.log(`refreshAccessTokenTime: ${refreshAccessTokenTime}`);
        //console.log(`currentTime: ${currentTime}`);
        //console.log(`refreshAccessTokenTimeDiffToNow: ${refreshAccessTokenTimeDiffToNow}`);

        //console.log(`minimumTimeRemaining: ${minimumTimeRemaining}`);

        //console.log(`if (${refreshAccessTokenTimeDiffToNow} <= ${minimumTimeRemaining}) get a new refresh token`);

        //In Seconds
        if (refreshAccessTokenTimeDiffToNow <= minimumTimeRemaining) { //was 60
          console.warn(`Get a new Refresh Token now`);
          dispatch(refreshAccessToken({}));
          ///// Update timer status in auth state
          dispatch(updateAuthTimerRefreshTokenTimer(true));
          console.groupEnd();
          resolve();

        } else {
          //console.log(`Determine if we need to set a new timer`);
          targetRefreshAccessTokenTimeInMinutes = refreshAccessTokenTimeDiffToNow - minimumTimeBeforeChecking;

          //convert to milliseconds (tests are in seconds, prod would be in minutes)
          targetRefreshAccessTokenTimeInMinutes = targetRefreshAccessTokenTimeInMinutes * 1000;


          let refreshTokenTimerSet = false;
          try {
            refreshTokenTimerSet = getState().Auth.authTimers.refreshTokenTimerSet;
          } catch (e) {}

          if (refreshTokenTimerSet === false) {
            console.warn(`refreshTokenTimerSet === false | set a new timer`);

            setTimeout(() => {
              console.warn(`TIMER | Refresh Token Request via timer.`);
              //If the refresh token is still less than 60 minutes, proceed
              let timerApiRefreshTokenExpiresOn = getState().Auth.apiRefreshTokenExpiresOn;
              let refreshTokenTimerSet = false;
              try {
                refreshTokenTimerSet = getState().Auth.authTimers.refreshTokenTimerSet;
              } catch (e) {}
              let timerRefreshAccessTokenTime = unixTimestampMillisecondsToSeconds(timerApiRefreshTokenExpiresOn);
              let timerCurrentTime = unixTimestampMillisecondsToSeconds(Date.now().valueOf());
              
              //let timerRefreshAccessTokenTimeDiffToNow = timerRefreshAccessTokenTime.diff(timerCurrentTime, 'minutes');

              ///// DIFFERENCE
              refreshAccessTokenTimeDiffToNow = timerRefreshAccessTokenTime - timerCurrentTime;

              //console.log(`timerRefreshAccessTokenTime: ${timerRefreshAccessTokenTime}`);
              //console.log(`timerCurrentTime: ${timerCurrentTime}`);
              //console.log(`refreshAccessTokenTimeDiffToNow: ${refreshAccessTokenTimeDiffToNow}`);



              console.warn(`${refreshAccessTokenTimeDiffToNow} <= ${minimumTimeRemaining}`);
              if (refreshAccessTokenTimeDiffToNow <= minimumTimeRemaining) {
                //console.log(`Refresh Token from Timer: Refresh now.`);
                dispatch(refreshAccessToken({}));

              } else {
                console.warn(`Refresh Token from Timer: No action required. `);
                
                //If there are no other timers, set one again.-active
                if (refreshTokenTimerSet === false) {
                  console.warn(`There are no timers set, we should set one.`);
                  
                  setTimeout(() => {
                    refreshAccessToken({});
                  }, additionalTimeToWait);

                  ///// Update timer status in auth state
                  dispatch(updateAuthTimerRefreshTokenTimer(true));

                } else {
                  console.warn(`There is an existing timer set, don't set another one.`);
                }
                

              }
            }, targetRefreshAccessTokenTimeInMinutes);

            ///// Update timer status in auth state
            dispatch(updateAuthTimerRefreshTokenTimer(true));

          } else {
            //console.log(`refreshTokenTimerSet === true | do not set a new timer`);
          }
          console.groupEnd();
          resolve();
        }

      } else {
        console.warn('Refresh token expired before timer set.Cancelling the timer and redirecting to logon.');
        dispatch(updateAuthTimerRefreshTokenTimer(false));    //There is no timers set, reset
        console.groupEnd();
        resolve();
      }

    }));
  };
}
*/










//NOT CONFIGURED YET FOR REFRESH TOKENS
export function refreshRefreshToken(body = {}) {
  return (dispatch, getState) => {
    const { dataRegion } = getState().User;
    let userDataRegion = dataRegion;
    const { apiRefreshToken, apiRefreshTokenExpiresIn, apiRefreshTokenExpiresOn, authTimers } = getState().Auth;
    return new Promise(((resolve, reject) => {
      console.warn(`Refreshing refresh token`);

      const apiBody = JSON.stringify({
        userDataRegion,
        refreshToken: apiRefreshToken,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'refreshRefreshToken',
        timestamp: Date.now(),
      };

      
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AuthApi.refreshRefreshToken(userDataRegion, apiBody, apiRefreshToken)
      .then((accessTokenRefreshResponse) => {
        console.warn(`API | POST | Response from access token refresh`);

        if (accessTokenRefreshResponse.status === true) {
          console.warn(`Response: TRUE`);

          
        } else {
          console.warn(`Response: FALSE`);
        }

        //console.log(accessTokenRefreshResponse);

        // UPDATE THE AUTH TOKENS
        dispatch(updateAuthTokens(accessTokenRefreshResponse.data.authResult));
        ///// Update timer status in auth state (RESET)
        dispatch(updateAuthTimerRefreshTokenTimer(false));

        dispatch(JwtRefreshTokenManager());   //Pass off to another function to manage timers..... TESTING...
          //UPDATE - this should only happen, if we get a successful token response....

        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        

        //Respond to the calling function (primary is the AuthRoute component)
        if (accessTokenRefreshResponse.status === true) {
          resolve({
            status: true
          });
        } else {
          resolve({
            status: false
          });
        }

      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}

// Using the refresh Token, get a new Access Token
export function refreshAccessToken(body = {}) {
  return (dispatch, getState) => {
    const { dataRegion } = getState().User;
    let userDataRegion = dataRegion;
    const { apiRefreshToken, apiRefreshTokenExpiresIn, apiRefreshTokenExpiresOn, authTimers } = getState().Auth;
    return new Promise(((resolve, reject) => {
      console.warn(`Refreshing access token with refresh token.`);

      const apiBody = JSON.stringify({
        userDataRegion,
        refreshToken: apiRefreshToken,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'refreshAccessToken',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AuthApi.refreshAccessToken(userDataRegion, apiBody)
        .then((accessTokenRefreshResponse) => {
          console.warn(`API | POST | Response from access token refresh`);

          if (accessTokenRefreshResponse.status === true) {
            console.warn(`Response: TRUE`);

            
          } else {
            console.warn(`Response: FALSE`);
          }

          //console.log(accessTokenRefreshResponse);

          // UPDATE THE AUTH TOKENS
          dispatch(updateAuthTokens(accessTokenRefreshResponse.data.authResult));
          ///// Update timer status in auth state (RESET)
          dispatch(updateAuthTimerAccessTokenTimer(false));

          ///////////////////////////////////////////////////////////////////////////////////////////
          // HANDLE PROACTIVE REFRESH TOKEN REFRESHES
          // This is to ensure that a refresh token doesn't expire. When the refresh token is getting close
          // to the expiry, we request a new refresh token to ensure seamless access.
          // The refresh access can be used a specific number of times before it is enforced that 
          // the user must log back in. This is to stop a refresh token being replayed for ever. 

          /**********************************************************
           * PROCESS
           * Check if there is a current timer for refresh token.
           * Create a timer for 3/4's the time of the refresh token expiry (of time remaining)
           * Update the state for the timer
           * 
           * When the timer executes, confirm that there is less the 3/4's the time remaining, 
           * then perform an api call for an update. Or cancel.
           * 
           **********************************************************/




          dispatch(JwtRefreshTokenManager());   //Pass off to another function to manage timers..... TESTING...
            //UPDATE - this should only happen, if we get a successful token response....



          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          

          ///////////////////////////////////////////////////////////////////////////////////////////

          //Respond to the calling function (primary is the AuthRoute component)
          if (accessTokenRefreshResponse.status === true) {
            resolve({
              status: true
            });
          } else {
            resolve({
              status: false
            });
          }

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });

    }));
  };
}



/** *************************************
****** LINK A USER ACCOUNT ***************
************************************** */

/****************************************
 * To link a user account, the user must be logged into 
 * an existing account, setup an identity, verify the 
 * identity belongs to them via a proof mechanism. 
 * Following this, a valid logged in user token must be
 * provided to the API along with a verified firebase login
 * token to link the accounts.
 */

export function userAuthLinkAccount(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      const { user } = body;

      //console.log('Link a user account');
      //console.log(user);
      //console.log(JSON.stringify(user));
      //console.log(user.credential);
      //console.log(auth().currentUser.getIdTokenResult());

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'userAuthLinkAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      auth().currentUser.getIdToken()
        .then((firebaseToken) => {
          console.table([
            {
              firebaseToken,
            },
          ]);
          //firebaseToken is the new user account. 
          //id is the existing logged in user id. http url reporting.
          //apiAccessToken is the current logged in user access token

          const apiBody = JSON.stringify({
            firebaseToken,
            id,
            apiAccessToken,
          });
          return AuthApi.loginAndLinkAccount(firebaseToken, id, apiBody, apiAccessToken);
        })
        .then((authResponse) => {
          console.warn('API | RESPONSE | Login and link user account');
          //console.log(JSON.stringify(authResponse));


          if (authResponse.status === true) {
            dispatch(setAuthToken(authResponse.data.authResult));
            dispatch(initializeUserData(authResponse.data.userResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.
            dispatch(initializeBusinessData(authResponse.data.businessResult)); // NEW - OVER WRITE ALL THE DATA ON LOGIN.

            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful

          } else {
          // TOAST ???
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          }

        })
        .catch((error) => {
          //console.log('Error: AuthUsers.login');
          //console.log(error);
          dispatch(authFailureLog(error)); // Record the failure log
          dispatch(authFailureCount()); // Record the failure count


          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });


    }));
  };
}




/** ****************************************
**** AUTH OBJECT CHANGED FROM FIREBASE  ***
***************************************** */

export function firebaseAuthChange(user) {
  /***********************************************
   * Firebase will check the user account on every page
   * load. We will determine what information is relevant
   * for updating our backend systems. This stops unneccesary 
   * api calls for no reason.
   * If the user account is disabled in firebase, the app will 
   * clear all data and log the user out. 
   * TODO: The access token will be notified to be expired early. 
   ***********************************************/
  return async (dispatch, getState) => {
    const { firebaseAccessToken, firebaseAuthData, apiAccessToken } = getState().Auth;

    console.warn(`======================= firebaseAuthChange ===========================`);

    const token = await user.getIdToken();
    const firebaseIdTokenResult = await user.getIdTokenResult();

    console.warn(`${token} === ${firebaseAccessToken}`);
    if (token === firebaseAccessToken) {
      //console.log("token is equal");
    } else {
      //console.log("token is different");
    }

    //console.log("firebaseIdTokenResult");
    //console.log(firebaseIdTokenResult);


    const firebaseExistingData = {
      iss: firebaseAuthData.iss,
      aud: firebaseAuthData.aud,
      user_id: firebaseAuthData.user_id,
      sub: firebaseAuthData.sub,
      email: firebaseAuthData.email,
      email_verified: firebaseAuthData.email_verified,
      firebase: firebaseAuthData.firebase,
    };
  
    const firebaseNewData = {
      iss: firebaseIdTokenResult.claims.iss,
      aud: firebaseIdTokenResult.claims.aud,
      user_id: firebaseIdTokenResult.claims.user_id,
      sub: firebaseIdTokenResult.claims.sub,
      email: firebaseIdTokenResult.claims.email,
      email_verified: firebaseIdTokenResult.claims.email_verified,
      firebase: firebaseIdTokenResult.claims.firebase,
    };


    const firebaseAuthStateChanged = !isEqual(firebaseExistingData, firebaseNewData);
    //console.log(`Firebase Auth State has changed: ${firebaseAuthStateChanged}`);


    const apiBody = JSON.stringify({
      accessToken: apiAccessToken,
    });

    if (firebaseAuthStateChanged) {
      // Change of state, update the backend systems
      //console.log('Firebase state has changed');

      //console.log("firebaseExistingData");
      //console.log(firebaseExistingData);
      //console.log("firebaseNewData");
      //console.log(firebaseNewData);

      
      auth().currentUser.reload()
        .then(() => {
          auth().currentUser.getIdToken(true)
            .then(firebaseToken => AuthApi.firebaseAuthUpdate(firebaseToken, apiBody))
            .then((result) => {
              //console.log('result: firebaseAuthUpdate function');
              //console.log(JSON.stringify(result));

              //Update the local state with the new firebase auth data
              dispatch(setFirebaseAuthData(firebaseNewData));

            })
            .catch((error) => {
              //console.log('ERROR | firebaseAuthChange | firebaseAuthUpdate');
              //console.log(error);
              throw (error);
            });
        })
        .catch((error) => {
          //console.log('ERROR | firebaseAuthChange | firebase | getIdToken');
          //console.log(error);
          throw (error);
        });

    } else {
      // Request backend to get an updated auth record for a user.
      //console.log('No state change with Firebase Auth');

      /*
      // For testing, we want it to keep firing.
      auth().currentUser.reload()
        .then(() => {
          auth().currentUser.getIdToken(true)
            .then(firebaseToken => AuthApi.firebaseAuthUpdate(firebaseToken, apiBody))
            .then((result) => {
              //console.log('result: firebaseAuthUpdate function');
              //console.log(JSON.stringify(result));

              //Update the local state with the new firebase auth data
              dispatch(setFirebaseAuthData(firebaseNewData));

            })
            .catch((error) => {
              //console.log('ERROR | firebaseAuthChange | firebaseAuthUpdate');
              //console.log(error);
              throw (error);
            });
        })
        .catch((error) => {
          //console.log('ERROR | firebaseAuthChange | firebase | getIdToken');
          //console.log(error);
          throw (error);
        });
      */

    } //end if
  };

}




























// Main handler from /login page
export function userAuth(user) {
  //console.log('userAuth');
  //console.log(user);
  return (dispatch) => {
    //console.log(user.credential);
    //console.log(`Access Token: ${user.credential.accessToken}`);
    //console.log(`ID Token: ${user.credential.idToken}`);


    auth().currentUser.getIdToken()
      .then(firebaseToken => AuthApi.login(firebaseToken))
      .then((result) => {
        //console.log(result);
        /* returnData
        userResult: userResult,
        businessResult: businessResult,
        */
        dispatch(setAuthToken(result.userResult));
        dispatch(setUserData(result.userResult));
        dispatch(setBusinessIdentitiesData(result.businessResult));
      })
      .catch((error) => {
        throw (error);
      });


  };
}

export const removeAuthToken = user => ({
  type: 'REMOVE_USER_AUTH',
  data: user,
});

export const removeUserData = user => ({
  type: 'REMOVE_USER_DATA',
  data: user,
});




//////////////////////////////////////////
// DELETE USER ACCOUNT
//////////////////////////////////////////
export function userDelete(body = {}) {
  return (dispatch, getState) => {
    const { dataRegion, id } = getState().User;
    let userDataRegion = dataRegion;
    let userId = id;
    const { apiRefreshToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const apiBody = JSON.stringify({
        userDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'AuthApi',
        function: 'userDelete',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AuthApi.userDelete(userDataRegion, userId, apiBody, apiRefreshToken)
        .then((apiResponseData) => {
          console.warn(`API | POST | userDelete`);
          let toastData = {};
          if (apiResponseData.status === true) {
          
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Reset all state

              dispatch(CommonActions.CLEAR_STATE_CORE()); 
              dispatch(CommonActions.CLEAR_STATE_ADMIN_WORK()); 
              dispatch(CommonActions.CLEAR_STATE_API_ACTIONS()); 
              dispatch(CommonActions.CLEAR_STATE_APP()); 
              dispatch(CommonActions.CLEAR_STATE_AUTH()); 
              dispatch(CommonActions.CLEAR_STATE_BUSINESS_IDENTITIES()); 
              dispatch(CommonActions.CLEAR_STATE_COMMON_ACTIONS()); 
              dispatch(CommonActions.CLEAR_STATE_NOTIFICATIONS()); 
              dispatch(CommonActions.CLEAR_STATE_PRODUCT()); 
              dispatch(CommonActions.CLEAR_STATE_REPORTING()); 
              dispatch(CommonActions.CLEAR_STATE_SIDEBAR()); 
              dispatch(CommonActions.CLEAR_STATE_SOCKET()); 
              dispatch(CommonActions.CLEAR_STATE_SYNC()); 
              dispatch(CommonActions.CLEAR_STATE_UI()); 
              dispatch(CommonActions.CLEAR_STATE_USER()); 
              dispatch(CommonActions.CLEAR_STATE_VERIFY()); 

              console.table([
              {
                name: 'reset',
                value: 'CLEAR_STATE_CORE',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_ADMIN_WORK',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_API_ACTIONS',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_APP',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_AUTH',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_BUSINESS_IDENTITIES',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_COMMON_ACTIONS',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_NOTIFICATIONS',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_PRODUCT',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_REPORTING',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_SIDEBAR',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_SOCKET',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_SYNC',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_UI',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_USER',
              },
              {
                name: 'reset',
                value: 'CLEAR_STATE_VERIFY',
              },
            ]);
  
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_AUTH(toastData));
  
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_AUTH());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_AUTH(toastData));
              }
            }
  
            
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_AUTH({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          }
  
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve({
            appStatus: apiResponseData.appStatus,
          });



          /*
        //console.log(user.credential);
        // console.log(`Access Token: ${user.credential.accessToken}`);
        // console.log(`ID Token: ${user.credential.idToken}`);

        // likely better to have login & delete as a function so we don't expose a pure delete endpoint
        auth().currentUser.getIdToken()
        .then(firebaseToken => AuthApi.loginAndDelete(firebaseToken))
        /*
        .then((result) => {
          //console.log("now delete the user after auth'ing");
          UsersApi.deleteUser(result);
        })
        *//*
        .then((result) => {
          dispatch(removeAuthToken(result));
          dispatch(removeUserData(result));
        })
        .catch((error) => {
          throw (error);
        });
        */






         
          
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });

    }));
  };
}






































export const saveTestData = testdata => ({
  type: 'SET_TEST_DATA',
  testdata,
});

export const setUserAuthObj = user => ({
  type: 'SET_USER_AUTH_OBJ',
  user,
});


export const setUserSignUp = () => ({
  type: 'SET_USER_SIGNUP',
});




export function userSignUpEmail(user) {
  return (dispatch) => {
    // get credentials from firebase
    // - uid: ZXbWdo2YQQZuIHf31BqaHLtPyf52 as firebaseUserId
    // - email: selby.steer@gmail.com
    // - emailVerified: false

    const body = JSON.stringify({
      firebaseUserId: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
    });
    //console.log('body to send');
    //console.log(body);
    UsersApi.createUser(body)
      .then((result) => {
        //console.log('Created user');
        // dispatch(setAuthToken(result));
        // dispatch(setUserData(result));
        // dispatch(saveTestData(result));
      })
      .catch((error) => {
        throw (error);
      });

    /*
    user.user.getIdToken()
      .then(firebaseToken => AuthApi.login(firebaseToken))
      .then((result) => {
        dispatch(setAuthToken(result));
        dispatch(setUserData(result));
        dispatch(saveTestData(result));
      })
      .catch((error) => {
        throw (error);
      });
      */
  };
}


// export function getUserAccount(user) {
//   return loginFirebase(user.credentials.idToken);
// return loginById(user);
/*
    .then((result) => {
      dispatch(saveTestData(result));
      //console.log(result);
      if (result.code === 200) {
        dispatch(setUserData(result));
      } else {
        dispatch(setUserSignUp());
      }
    })
    .catch((err) => {
      throw (err);
    });
  */
// }

// export function userAuth(user) {
//   return (dispatch) => {
//     dispatch(getUserAccount(user));
//     dispatch(setUserAuthObj(user.user)); // Set the user obj from the AUTH PROVIDER in AUTH

//     // dispatch(setuserlogin(user));
//   };
// }


export const setuserlogin = user => ({
  type: 'SET_USER_LOGIN',
  user,
});

export const setuserlogout = () => ({
  type: 'SET_USER_LOGOUT',
});


export const setuserdata = data => ({
  type: 'SET_USER_DATA',
  data,
});


export function getuserdatadb(user) {
  // let abc = db.collection('usertest').where('uid', '==', '0QNOPfBVqGQYJIixN6Hg36f12Kq1').get().then((querySnapshot) => {
  //   querySnapshot.forEach(doc => doc.data().role);
  // });
  // dispatch(setuserdata(abc));


  // return dispatch(
  //  db.collection('usertest').where('uid', '==', '0QNOPfBVqGQYJIixN6Hg36f12Kq1').get().then((querySnapshot) => {
  //    querySnapshot.forEach(doc => doc.data().role);
  //  });

  return (dispatch) => {
    // db.collection('usertest').where()
    db.collection('usertest')
      .where('uid', '==', '0QNOPfBVqGQYJIixN6Hg36f12Kq1').get()
      .then(querySnapshot => querySnapshot.forEach(doc => doc.data().role))
      .then((result) => {
        //console.log(result);
        dispatch(setuserdata(result));
      });
  };
}

// login google -> UID -> to DB (UID) --> first name, last name --> update redux


export function loginFire(user) {
  return (dispatch) => {
    dispatch(getuserdatadb(user));
    dispatch(setuserlogin(user));
  };


  /*
  //console.log('karen-1');

  return (dispatch) => {
    return dispatch({ type: 'SET_USER_LOGIN', user });


    try {
      // we can do async and then dispatch more stuff
      return getuserdatadb(user)
        .then(result => store.dispatch({ type: 'SET_USER_DATA', result }))
    } catch (e) {
      return dispatch({ type: 'MY_FETCH_FAIL', error: e });
    }
    /*
    return getuserdatadb(user)
      .then((result) => {
        store.dispatch({ type: 'SET_USER_DATA', result });
      });
      *//*
  };
  */
}


