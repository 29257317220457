import React from 'react';
import { render } from 'react-dom';
import store from 'Store/ConfigureStore';
import Root from 'Containers/Root';
import { saveLocalStorageState } from './store/localStorage';

/////////////////////////////////////////////////
// GLOBAL VARIABLES
/////////////////////////////////////////////////
global.gpAccessToken = '';
global.gpRefreshToken = '';
global.fbAccessToken = '';


//Langauge Support
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      de: {
          common: common_de
      },
  },
});


if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

__webpack_nonce__ = '<%=nonce%>';

store.subscribe(() => {
  saveLocalStorageState(store.getState());
});


render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Root store={store} />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

/*
render(
  <Root store={store} />,
  document.getElementById('root'),
);
*/