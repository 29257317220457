import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Redirect } from 'react-router-dom';

// Components
import CommonTransit from './CommonTransit';

import { socketConnection, setIsAuthenticatedFalse, JwtRefreshTokenManager,
  refreshAccessToken, updateIsAuthenticatedStatus, syncActive } from 'Actions';

  function unixTimestampMillisecondsToSeconds(timestamp) {
    let num = timestamp;
    if (num.toString().length >=13) {
      //In milliseconds
      num = (num-(num%1000))/1000;
    }
    return num;
  }


class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.verifySecureRoute = this.verifySecureRoute.bind(this); // alter local state
    this.verifyPermissionRoute = this.verifyPermissionRoute.bind(this);
    this.socketConnection = this.socketConnection.bind(this);

  }

  componentDidMount() {
    const thisComponent = this.constructor.name;
    //console.log(`componentDidMount: ${thisComponent}`);

    //On initial mounting of the AuthRoute, set a timer to manage the refresh tokens

    //console.warn(`Run the JWT Refresh Manager`);
    this.props.JwtRefreshTokenManager();

  }

  /********************************************************************
   * AUTH ROUTE
   * Permission: "Auth" is the primary trigger for running this component. 
   * Web Permissions including Business Permissions are appended here too.
   * - owner, admin, user. 
   * This is because a page route has a permission based on the currently 
   * logged in users permissions. 
   * 
   * If accessing a business page, we need to lookup the "managedBusinesses" 
   * under the user, get the permissions and match to this page's permission
   * (catching if the page is a specific business page)
   * 
   *********************************************************************/







/***********************************************************************
 * VERIFY SECURE ROUTE
 * Any page that requires Authentication, will have two things checked:
 * 1) Verify the user has an access token to view the secure page
 * 2) Verify that the local user state has the "isAuthenticated" value set to true
 * 
 * How it works
 * 1) Check - Is there an access token present
 * 2) Check - Has the access token expired
 * 3) Check - How much longer has the access token got remaining & the refresh token
 * 4) Check - Is there an existing timer in local state - For generating a new access token request
 * 5) Set a new timer for 3/4 of the time of the Access token
 * 
 */




  async verifySecureRoute() {
    console.warn('====== Auth Route | VerifySecureRoute ======');
    ///// Get the current time
    const currentTimestamp = unixTimestampMillisecondsToSeconds(Date.now());
    //const currentTimestamp = currentDate.getTime();
    //console.warn(`Route Authentication: Current: ${currentTimestamp}. AccessToken: ${this.props.apiAccessTokenExpiresOn}`);

    ///// Check - Is there an access token present
    if (this.props.apiAccessTokenExpiresOn == null || this.props.apiAccessTokenExpiresOn == undefined
      || this.props.apiRefreshTokenExpiresOn == null || this.props.apiRefreshTokenExpiresOn == undefined) {
        //There is no access tokens. Auth has failed, redirect the user to the login page
        this.props.isAuthenticated = false;
        this.props.updateIsAuthenticatedStatus(false);
      return false;
    }

    //NEW 2020
    //If accessTokens in memory are cleared, check if the refresh token is still valid.
    if (global.gpAccessToken === '' || global.gpAccessToken === null || global.gpAccessToken === undefined) {
      //Check if the refresh tokens are not null
      if (this.props.apiRefreshTokenExpiresOn !== null || this.props.apiRefreshTokenExpiresOn !== undefined) {
        //Check if the refresh tokens are within the timeframes
        if (currentTimestamp <= this.props.apiRefreshTokenExpiresOn) {
          console.log('AUTH | Refresh token is still valid, request refresh.');
          let refreshTokenResponse = await this.props.refreshAccessToken({});
          console.log("refreshTokenResponse");
          console.log(refreshTokenResponse);
          try {
            if (refreshTokenResponse.status === true) {
              return true;
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        } else {
          console.log('AUTH | Refresh token has expired, force new login.');
          this.props.isAuthenticated = false;
          this.props.updateIsAuthenticatedStatus(false);
          return false;
        }
      }
    }



    //NEW 2020
    if (global.gpAccessToken === '' || global.gpAccessToken === null || global.gpAccessToken === undefined ||
    global.gpRefreshToken === '' || global.gpRefreshToken === null || global.gpRefreshToken === undefined) {
      console.log('AUTH | Both Access and Refresh tokens in memory have cleared.');
      this.props.isAuthenticated = false;
      this.props.updateIsAuthenticatedStatus(false);
      return false;
    }

    ///// Check - Has the access token expired
    if (currentTimestamp >= this.props.apiAccessTokenExpiresOn) {
      //console.warn(`Access Token has expired, attempt token refresh. Current: ${currentTimestamp} >= AccessToken: ${this.props.apiAccessTokenExpiresOn}`);
      
      ///// Check - Has the refresh token expired
      if (currentTimestamp >= this.props.apiRefreshTokenExpiresOn) {
        //console.warn(`Refresh Token has expired. The user needs to log back in. Current: ${currentTimestamp} >= RefreshToken: ${this.props.apiRefreshTokenExpiresOn}`);
        this.props.isAuthenticated = false;
        this.props.updateIsAuthenticatedStatus(false);
        return false;
      }



      /****** TEMP REMOVE WhiLE TESTing - managed by the JwtRefreshTokenManager
      ///// Refresh token valid. Attempt to get a new access token using the refresh token.
      console.warn(`Refresh Token valid. Request new Access Token. Current: ${currentTimestamp} < RefreshToken: ${this.props.apiRefreshTokenExpiresOn}`);
      await this.props.refreshAccessToken()
      .then((refreshAccessTokenResponse) => {
        console.warn(`Successfully requested new access token.`);

        let refreshAccessTokenStatus = false;
        try {
          refreshAccessTokenStatus = refreshAccessTokenResponse.status;
        } catch (e) {}

        if (refreshAccessTokenStatus === true) {
          console.warn(`Route authorised`);
          this.props.isAuthenticated = true;
          this.props.updateIsAuthenticatedStatus(true);
          return true;
        } else {
          console.warn(`Route declined`);
          this.props.isAuthenticated = false;
          this.props.updateIsAuthenticatedStatus(false);
          return false;
        }
      })
      .catch((error) => {
        console.warn(`Route declined. Error requesting new access token.`);
        this.props.isAuthenticated = false;
        return false;
      });
      */

    } else {
      //console.warn(`Route authorised. Access token valid.`);
      this.props.isAuthenticated = true;
      //this.props.updateIsAuthenticatedStatus(true);     //2020 - do we need to re-update this status each time???
      return true;
    }


  }




  ///// ENSURE THE USER HAS THE CORRECT PERMISSION TO VIEW THIS PAGE
  verifyPermissionRoute() {
    console.warn('====== Auth Route | verifyPermissionRoute ======');
    /***************************************************
     * The user has access to all the public and user pages.
     * The pages that are currently locked down (other than admin)
     * are the business pages. 
     * We should check if this is a business route, what the page
     * permissions are and then check if the user has the correct
     * permissions against that specific business.
     ****************************************************/
    //Get the page type
    //console.log(this.props);

    if (this.props.businessAccountRoute === true) {
      let userHasRoutePermission = false;

 
      //This is a business page, we need to check if the user has the correct permissions.
      //Get the page permissions
      //console.log("User Permissions requried to use this page");
      //console.log(this.props.userRoutePermissions);

      let userRoutePermissions = [];
      if (Array.isArray(this.props.userRoutePermissions)) {
        userRoutePermissions = this.props.userRoutePermissions;
      }

      //Is the page a business page?
      //console.log("Business Account Route?");
      //console.log(this.props.businessAccountRoute);

      //Get the businessId for the page being requested.
      //console.log("Business Id");
      //console.log(this.props.computedMatch.params.routebusinessid);

      //console.log("User Id");
      //console.log(this.props.userId);


      //console.log("businessIdentities");
      //console.log(this.props.businessIdentities);

      //Check the user to see if they manage this business
      ////>> Loop through "this.props.userManagedBusinesses" <-- we need to get the users permissions added to this too. 

      //for this business, determine what the permissions this user has.
      let userPermissions = [];
      if (this.props.businessAccountRoute === true) {
        //console.log(`this is a business account route`);
        for (let x = 0; x < this.props.businessIdentities.businesses.length; x++) {
          //console.log(`looking for business id: ${this.props.businessIdentities.businesses[x]._id} === ${this.props.computedMatch.params.routebusinessid}`);
          if (this.props.businessIdentities.businesses[x]._id === this.props.computedMatch.params.routebusinessid) {
            //console.log(`found match for business id: ${this.props.businessIdentities.businesses[x]._id} === ${this.props.computedMatch.params.routebusinessid}`);
            //find the user within the business and get the permissions
            for (let y = 0; y < this.props.businessIdentities.businesses[x].businessAdministrators.length; y++) {
              if (this.props.businessIdentities.businesses[x].businessAdministrators[y].userId === this.props.userId) {
                //console.log(`found match for user id: ${this.props.businessIdentities.businesses[x].businessAdministrators[y].userId} === ${this.props.userId}`);
                userPermissions = this.props.businessIdentities.businesses[x].businessAdministrators[y].userPermissions;
              }
            }
          }
        }
      }

      //Check the permissions for business
      if (this.props.businessAccountRoute === true) {
        //Check if there is a match between required permission and the user permissions.

        //this is an array in array type search

        //TODO>>>>>>>>>>>>>>>>>>>
        //console.log("userPermissions");
        //console.log(JSON.stringify(userPermissions));

        
        for (let p = 0; p < userPermissions.length; p++) {
          //console.log(`if ${userPermissions[p]} user permission is in the array for the route permissions, grant access`);
          if (userRoutePermissions.includes(userPermissions[p])) {
            userHasRoutePermission = true;
          }
        }

       
      }


      console.table([
        {
          name: 'Business Page',
          value: this.props.businessAccountRoute,
        },
        {
          name: 'Business Id',
          value: this.props.computedMatch.params.routebusinessid,
        },
        {
          name: 'User Id',
          value: this.props.userId,
        },
        {
          name: 'Page Permissions',
          value: JSON.stringify(this.props.userRoutePermissions),
        },
        {
          name: 'User Permissions',
          value: JSON.stringify(userPermissions),
        },
        {
          name: 'User Allowed',
          value: userHasRoutePermission,
        },

      ]);
      

      //console.log("userHasRoutePermission on Auth Route");
      //console.log(userHasRoutePermission);
      return userHasRoutePermission;
              

    } else {
      return true;    //Needs further validation (previously was false)
    }
  }

  getUserBusinessPermissionsForRoute() {

      //console.groupCollapsed('Login');
      //console.log('FN: userAuthEmail');
      //console.log(JSON.stringify(user));
      //console.log(user.credential);
      //console.log(auth().currentUser.getIdTokenResult());
      //console.groupEnd();


    console.warn('====== Auth Route | getUserBusinessPermissionsForRoute ======');
    /***************************************************
     * The user has access to all the public and user pages.
     * The pages that are currently locked down (other than admin)
     * are the business pages. 
     * We should check if this is a business route, what the page
     * permissions are and then check if the user has the correct
     * permissions against that specific business.
     ****************************************************/
    //Get the page type
    //console.log(this.props);

    if (this.props.businessAccountRoute === true) {
      let userHasRoutePermission = false;



      //This is a business page, we need to check if the user has the correct permissions.
      //Get the page permissions
      //console.log("User Permissions requried to use this page");
      //console.log(this.props.userRoutePermissions);

      let userRoutePermissions = [];
      if (Array.isArray(this.props.userRoutePermissions)) {
        userRoutePermissions = this.props.userRoutePermissions;
      }

      //Is the page a business page?
      //console.log("Business Account Route?");
      //console.log(this.props.businessAccountRoute);

      //Get the businessId for the page being requested.
      //console.log("Business Id");
      //console.log(this.props.computedMatch.params.routebusinessid);

      //console.log("User Id");
      //console.log(this.props.userId);


      //console.log("businessIdentities");
      //console.log(this.props.businessIdentities);

      //Check the user to see if they manage this business
      ////>> Loop through "this.props.userManagedBusinesses" <-- we need to get the users permissions added to this too. 

      //for this business, determine what the permissions this user has.
      let userPermissions = [];
      if (this.props.businessAccountRoute === true) {
        //console.log(`this is a business account route`);
        for (let x = 0; x < this.props.businessIdentities.businesses.length; x++) {
          //console.log(`looking for business id: ${this.props.businessIdentities.businesses[x]._id} === ${this.props.computedMatch.params.routebusinessid}`);
          if (this.props.businessIdentities.businesses[x]._id === this.props.computedMatch.params.routebusinessid) {
            //console.log(`found match for business id: ${this.props.businessIdentities.businesses[x]._id} === ${this.props.computedMatch.params.routebusinessid}`);
            //find the user within the business and get the permissions
            for (let y = 0; y < this.props.businessIdentities.businesses[x].businessAdministrators.length; y++) {
              if (this.props.businessIdentities.businesses[x].businessAdministrators[y].userId === this.props.userId) {
                //console.log(`found match for user id: ${this.props.businessIdentities.businesses[x].businessAdministrators[y].userId} === ${this.props.userId}`);
                userPermissions = this.props.businessIdentities.businesses[x].businessAdministrators[y].userPermissions;
              }
            }
          }
        }
      }
      /*
      console.table([
        {
          name: 'Business Page',
          value: this.props.businessAccountRoute,
        },
        {
          name: 'Business Id',
          value: this.props.computedMatch.params.routebusinessid,
        },
        {
          name: 'User Id',
          value: this.props.userId,
        },
        {
          name: 'User Permissions',
          value: JSON.stringify(userPermissions),
        },
      ]);
      */
      
      

      return userPermissions;
              

    } else {
      return [];
    }
  }


  async socketConnection() {
    console.warn('====== Auth Route | socketConnection ======');
    /***************************************************
     * Check if there is an active socket connection or 
     * initialise a new socket connection. 
     ****************************************************/

    //Only make a connection for specific pages (?)


    this.props.socketConnection();
    
    //On Error or Success, always return true (as we dont want a socket connect to block the core access usage)
    return true;
  }
  //&& this.socketConnection()
  
  render() {
    const { exact, key, path, businessAccountRoute, userRoutePermissions } = this.props;

    //console.log(`====== Auth Route | Authenticated: ${this.props.isAuthenticated} ======`);

    const DynamicRouteComponent = this.props.component;

    if (this.verifySecureRoute() && this.props.isAuthenticated) {
      console.warn('AUTHROUTE | verifySecureRoute and isAuth');


      if (this.verifyPermissionRoute()) {
        //return <Route render={(props) => <DynamicRouteComponent userHasRoutePermission="true" 
        //userBusinessRoutePermissions={this.getUserBusinessPermissionsForRoute()} {...props} /> } path={this.props.path} />;
        return <CommonTransit path={this.props.path}
        userHasRoutePermission="true" userBusinessRoutePermissions={this.getUserBusinessPermissionsForRoute()} 
        {...this.props}  />;
      } else {
        //return <Route render={(props) => <DynamicRouteComponent userHasRoutePermission="false" userBusinessRoutePermissions={this.getUserBusinessPermissionsForRoute()} {...props} /> } path={this.props.path} />;
        
        return <CommonTransit path={this.props.path}
        userHasRoutePermission="false" userBusinessRoutePermissions={this.getUserBusinessPermissionsForRoute()} 
        {...this.props}  />;



      }
    }
    return <Redirect to="/login" />;
  }
}


const mapStateToProps = (state, ownProps) => {

  return {
    isAuthenticated: state.Auth.isAuthenticated,
    apiAccessTokenExpiresOn: state.Auth.apiAccessTokenExpiresOn,
    apiAccessTokenExpiresIn: state.Auth.apiAccessTokenExpiresIn,
    apiRefreshTokenExpiresOn: state.Auth.apiRefreshTokenExpiresOn,
    apiRefreshTokenExpiresIn: state.Auth.apiRefreshTokenExpiresIn,

    userId: state.User._id,
    userManagedBusinesses: state.User.managedBusinesses,
    businessIdentities: state.BusinessIdentities.identities,
  };

};


const mapDispatchToProps = {
  socketConnection,
  setIsAuthenticatedFalse,
  JwtRefreshTokenManager,

  refreshAccessToken,
  updateIsAuthenticatedStatus,
  syncActive,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthRoute);
