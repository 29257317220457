

export const deleteStateData = () => ({
  type: 'DELETE_STATE',
});

export const submitFormData = data => ({
  type: 'COMPLETE',
  payload: data,
});

export const setloggedinuser = user => ({
  type: 'SET_LOGGEDIN_USER',
  user,
});

export const setloggedoutuser = user => ({
  type: 'SET_LOGGEDOUT_USER',
  user,
});


/*
export const setfirstname = firstname => ({
  type: 'SET_FIRSTNAME',
  firstname,
});
export const setlastname = lastname => ({
  type: 'SET_LASTNAME',
  lastname,
});
*/

/*
export const selectedValue = someValue => ({
  type: 'SET_SELECTED_VALUE',
  someValue,
});

export const login = loginValue => ({
  type: 'SET_AUTHENTICATED',
  loginValue,
});

export function performLogin(username, password) {
  return (dispatch, getState) => {
    const state = getState();
    return LoginApi.login(username, password)
      .then((result) => {
        if (result.code === 200) {
          dispatch(login(true));
        } else {
          dispatch(login(false));
        }
      })
      .catch((err) => {
        throw (err);
      });
  };
}
*/
