import _ from 'lodash';

const initialState = {
  manualVerifications: [],
  supportCentre: [],
};

const resetState = {
  manualVerifications: [],
  supportCentre: [],
};


const AdminWorkReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_ADMIN_WORK':
    {
      return resetState;
    }

    case 'RESET_STATE_ADMIN_WORK':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }
    
    case 'SYNC_SUPPORT_CENTRE_QUEUE':
    {
      console.warn("SYNC_SUPPORT_CENTRE_QUEUE");
      const clone = Object.assign({}, state);
      //clone.supportCentre = action.data;

      for (let y = 0; y < action.data.length; y++) {
        let id = action.data[y].id;

        let businessDataRegion = action.data[y].businessDataRegion;
        let category = action.data[y].category;
        let contactEmail = action.data[y].contactEmail;
        let contactName = action.data[y].contactName;
        let contactPhone = action.data[y].contactPhone;
        let createdAt = action.data[y].createdAt;
        let customerNotes = action.data[y].customerNotes;
        let description = action.data[y].description;
        let emailMessageId = action.data[y].emailMessageId;
        let modifiedAt = action.data[y].modifiedAt;
        let relatesToBusinessAccount = action.data[y].relatesToBusinessAccount;
        let relatesToBusinessAccountDetail = action.data[y].relatesToBusinessAccountDetail;
        let relatesToBusinessId = action.data[y].relatesToBusinessId;
        let relatesToUserAccount = action.data[y].relatesToUserAccount;
        let status = action.data[y].status;
        let subCategory = action.data[y].subCategory;
        let subject = action.data[y].subject;
        let supportGroup = action.data[y].supportGroup;
        let type = action.data[y].type;
        let userDataRegion = action.data[y].userDataRegion;
        let userFingerprint = action.data[y].userFingerprint;
        let userId = action.data[y].userId;
        let userIpAddress = action.data[y].userIpAddress;
        let workNotes = action.data[y].workNotes;

        


        let foundRecord = false;

        for (let x = 0; x < clone.supportCentre.length; x++) {
          if (clone.supportCentre[x].id === id) {
            foundRecord = true;

            clone.supportCentre[x].businessDataRegion = businessDataRegion;
            clone.supportCentre[x].category = category;
            clone.supportCentre[x].contactEmail = contactEmail;
            clone.supportCentre[x].contactName = contactName;
            clone.supportCentre[x].contactPhone = contactPhone;
            clone.supportCentre[x].createdAt = createdAt;
            clone.supportCentre[x].customerNotes = customerNotes;
            clone.supportCentre[x].description = description;
            clone.supportCentre[x].emailMessageId = emailMessageId;
            clone.supportCentre[x].modifiedAt = modifiedAt;
            clone.supportCentre[x].relatesToBusinessAccount = relatesToBusinessAccount;
            clone.supportCentre[x].relatesToBusinessAccountDetail = relatesToBusinessAccountDetail;
            clone.supportCentre[x].relatesToBusinessId = relatesToBusinessId;
            clone.supportCentre[x].relatesToUserAccount = relatesToUserAccount;
            clone.supportCentre[x].status = status;
            clone.supportCentre[x].subCategory = subCategory;
            clone.supportCentre[x].subject = subject;
            clone.supportCentre[x].supportGroup = supportGroup;
            clone.supportCentre[x].type = type;
            clone.supportCentre[x].userDataRegion = userDataRegion;
            clone.supportCentre[x].userFingerprint = userFingerprint;
            clone.supportCentre[x].userId = userId;
            clone.supportCentre[x].userIpAddress = userIpAddress;
            clone.supportCentre[x].workNotes = workNotes;



          }
        }

        if (foundRecord === false) {
          let newRecord = {
            businessDataRegion,
            category,
            contactEmail,
            contactName,
            contactPhone,
            createdAt,
            customerNotes,
            description,
            emailMessageId,
            modifiedAt,
            relatesToBusinessAccount,
            relatesToBusinessAccountDetail,
            relatesToBusinessId,
            relatesToUserAccount,
            status,
            subCategory,
            subject,
            supportGroup,
            type,
            userDataRegion,
            userFingerprint,
            userId,
            userIpAddress,
            workNotes, 
            id: id,
            _id: id,
          }
          clone.supportCentre.push(newRecord);
        }

      }

      return clone;
    }

    case 'SYNC_WORK_QUEUE_VERIFICATIONS':
    {
      console.warn("SYNC_WORK_QUEUE_VERIFICATIONS");
      const clone = Object.assign({}, state);
      //clone.manualVerifications = action.data;

      for (let y = 0; y < action.data.length; y++) {
        let id = action.data[y].id;
        let businessDataRegion = action.data[y].businessDataRegion;
        let businessId = action.data[y].businessId;
        let customerNotes = action.data[y].customerNotes;
        let workNotes = action.data[y].workNotes;
        let verificationType = action.data[y].verificationType;
        let status = action.data[y].status;
        let identityId = action.data[y].identityId;
        let createdAt = action.data[y].createdAt;
        let modifiedAt = action.data[y].modifiedAt;

        let foundRecord = false;

        for (let x = 0; x < clone.manualVerifications.length; x++) {
          if (clone.manualVerifications[x].id === id) {
            foundRecord = true;

            clone.manualVerifications[x].businessDataRegion = businessDataRegion;
            clone.manualVerifications[x].businessId = businessId;
            clone.manualVerifications[x].status = status;
            clone.manualVerifications[x].customerNotes = customerNotes;
            clone.manualVerifications[x].workNotes = workNotes;
            clone.manualVerifications[x].verificationType = verificationType;
            clone.manualVerifications[x].status = status;
            clone.manualVerifications[x].identityId = identityId;
            clone.manualVerifications[x].createdAt = createdAt;
            clone.manualVerifications[x].modifiedAt = modifiedAt;

          }
        }

        if (foundRecord === false) {
          let newRecord = {
            businessDataRegion,
            businessId,
            createdAt,
            customerNotes,
            data: {},
            id,
            identityId,
            modifiedAt,
            status,
            verificationType,
            workNotes,
            _id: id,
          }
          clone.manualVerifications.push(newRecord);
        }

      }

      return clone;
    }
    
    case 'STORE_WORK_QUEUE_VERIFICATION_DATA':
    {
      const clone = Object.assign({}, state);
      let id = action.data.workQueueItemId;
      let workItemData = action.data.workItemData;
      let businessItemData = action.data.businessItemData;

      console.warn("STORE_WORK_QUEUE_VERIFICATION_DATA");
      console.warn(action.data );
      console.warn(id);
      console.warn(workItemData);
      console.warn(businessItemData);

      let foundRecord = false;

      for (let x = 0; x < clone.manualVerifications.length; x++) {
        if (clone.manualVerifications[x].id === id) {
          foundRecord = true;

          clone.manualVerifications[x].workNotes = workItemData.workNotes;
          clone.manualVerifications[x].customerNotes = workItemData.customerNotes;
          clone.manualVerifications[x].status = workItemData.status;

          console.log("match on id");
          //we have the correct record to update.
          //Verify the update

          console.log(`businessId: ${clone.manualVerifications[x].businessId} === ${workItemData.businessId}`);
          console.log(`identityId: ${clone.manualVerifications[x].identityId} === ${workItemData.identityId}`);
          console.log(`type: ${clone.manualVerifications[x].verificationType} === ${workItemData.verificationType}`);
          if (clone.manualVerifications[x].businessId === workItemData.businessId &&
            clone.manualVerifications[x].identityId === workItemData.identityId &&
            clone.manualVerifications[x].verificationType === workItemData.verificationType) {
            //Ok to update records
            //console.log("Update data set");
            if (businessItemData === undefined || businessItemData === null || businessItemData === '' || businessItemData === "" || businessItemData === []) {
              console.log("businessItemData is not set, skipping overwrite");
            } else {
              clone.manualVerifications[x].data = businessItemData;
            }
            

          }
        }
      }

      if (foundRecord === false) {
        let newRecord = workItemData;
        newRecord['data'] = businessItemData;
        clone.manualVerifications.push(newRecord);
      }



      return clone;
    }


    case 'STORE_SUPPORT_QUEUE_DATA':
    {
      const clone = Object.assign({}, state);
      let id = action.data.supportQueueItemId;
      let supportItemData = action.data.supportItemData;
      let businessItemData = action.data.businessItemData;

      console.warn("STORE_WORK_QUEUE_VERIFICATION_DATA");
      console.warn(action.data );
      console.warn(id);
      console.warn(supportItemData);
      console.warn(businessItemData);

      let foundRecord = false;

      for (let x = 0; x < clone.supportCentre.length; x++) {
        if (clone.supportCentre[x].id === id) {
          foundRecord = true;

          clone.supportCentre[x].workNotes = supportItemData.workNotes;
          clone.supportCentre[x].customerNotes = supportItemData.customerNotes;
          clone.supportCentre[x].status = supportItemData.status;

          console.log("match on id");
          //we have the correct record to update.
          //Verify the update

          console.log(`businessId: ${clone.supportCentre[x].businessId} === ${supportItemData.businessId}`);
          console.log(`identityId: ${clone.supportCentre[x].identityId} === ${supportItemData.identityId}`);
          console.log(`type: ${clone.supportCentre[x].verificationType} === ${supportItemData.verificationType}`);
          if (clone.supportCentre[x].businessId === supportItemData.businessId &&
            clone.supportCentre[x].identityId === supportItemData.identityId &&
            clone.supportCentre[x].verificationType === supportItemData.verificationType) {
            //Ok to update records
            //console.log("Update data set");
            if (businessItemData === undefined || businessItemData === null || businessItemData === '' || businessItemData === "" || businessItemData === []) {
              console.log("businessItemData is not set, skipping overwrite");
            } else {
              clone.supportCentre[x].data = businessItemData;
            }
            

          }
        }
      }

      if (foundRecord === false) {
        let newRecord = supportItemData;
        newRecord['data'] = businessItemData;
        clone.supportCentre.push(newRecord);
      }



      return clone;
    }


    /************************************************************************
    ***** NEW REDUX ACTIONS *************************************************
    *************************************************************************/

    case 'REDUX_UPDATE_ADMINWORK':
    {
      const clone = Object.assign({}, state);
      console.log('ADMIN WORK - REDUX_UPDATE_ADMINWORK');
      //console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z < updateRecords.length; z++) {
        console.log("For each record to update...");
        if (updateRecords!==null && updateRecords!==undefined) {
          let dotPath = updateRecords[z].dotPath;
          let updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          _.merge(clone, cloneItemUpdate);                      //Merge
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      console.log(clone); 
      return clone;
    }


    
    default:
      return state;
  }
};

export default AdminWorkReducer;
