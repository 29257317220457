/** BUSINESS VERIFICATION ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import VerifyApi from './api/VerifyApi';
import VerifyPhoneApi from './api/VerifyPhoneApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_VERIFY = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});
export const addGlobalToastMessage_VERIFY = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const getVerifyInteraction = transaction => ({
  type: 'VERIFY_INTERACTION',
  transaction,
});



export function verifyInteraction(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;   //Not required, but used for providing personalised feedback
    return new Promise(((resolve, reject) => {
      const { data } = body;

      const interactionCode = data.interactionCode;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'VerifyApi',
        function: 'businessInteractionTokenLookup',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      VerifyApi.verifyInteraction(interactionCode, apiAccessToken)
        .then((verifyResult) => {
          //console.log('Return | API | verifyInteraction');
          //console.log(verifyResult);
          dispatch(getVerifyInteraction(verifyResult)); // NEW SYNC TRIAL

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(verifyResult);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}









export function verifyUserPhoneNumberWithCall(body = {}) {
  return (dispatch, getState) => {
    const {apiAccessToken} = getState().Auth;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;

      //console.log('data');
      //console.log(data);
      //console.log(JSON.stringify(data));

      VerifyPhoneApi.verifyUserPhoneNumberWithCall(JSON.stringify(data), apiAccessToken)
        .then((result) => {
          //dispatch(setbusinessInteractionTokenLookup(result));
          //console.log('api response - verifyUserPhoneNumberWithCall');
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    }));
  }
}

