/** ADMIN WORK ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import AdminStatsApi from './api/AdminStatsApi';

const uuidv1 = require('uuid/v1');



export const syncAdminStatsUsers = data => ({
  type: 'SYNC_ADMIN_STATS_USERS',
  data,
});



export function getUserStats(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      const {
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: '',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminStatsApi.getUserStats()
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | getUserStats');
          console.log(apiResponseData);

          if (apiResponseData.status === true) {
            
  
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              dispatch(syncAdminStatsUsers(apiResponseData.data.userStats)); 



              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {

              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {

                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {

              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


