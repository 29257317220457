import Request from './Request';
import RequestRegion from './RequestRegion';


class AdminWorkApi {

  // // SYNC ADMIN WORK QUEUE ////
  static syncWorkQueue(body, accessToken) {
    console.warn(`API | POST | Sync Work Queue`);
    return Request.POST_json(`${process.env.adminWorkAPI}/v1/verifications/active`, body, global.gpAccessToken);
  }
  // // GET WORK QUEUE ITEM ////
  static getWorkQueueItem(workQueueItemId, body, accessToken) {
    console.warn(`API | POST | Get Work Queue Item`);
    return Request.POST_json(`${process.env.adminWorkAPI}/v1/verifications/item/${workQueueItemId}`, body, global.gpAccessToken);
  }
  // // SAVE WORK QUEUE ITEM ////
  static saveWorkQueueItem(workQueueItemId, body, accessToken) {
    console.warn(`API | PATCH | Save Work Queue Item`);
    return Request.PATCH_json(`${process.env.adminWorkAPI}/v1/verifications/item/${workQueueItemId}`, body, global.gpAccessToken);
  }
  // // PROCESS WORK QUEUE ITEM ////
  static processWorkQueueItem(workQueueItemId, body, accessToken) {
    console.warn(`API | POST | Save Work Queue Item`);
    return Request.POST_json(`${process.env.adminWorkAPI}/v1/verifications/item/${workQueueItemId}/process`, body, global.gpAccessToken);
  }
  // // UPDATE STATUS OF WORK QUEUE ITEM ////
  static statusUpdateWorkQueueItem(workQueueItemId, body, accessToken) {
    console.warn(`API | PATCH | Update Work Queue Item Status`);
    return Request.PATCH_json(`${process.env.adminWorkAPI}/v1/verifications/item/${workQueueItemId}/status`, body, global.gpAccessToken);
  }

  //// SUPPORT QUEUE ////
  static syncSupportQueue(body, accessToken) {
    console.warn('API | POST | Sync Support Queue');
    return Request.POST_json(`${process.env.adminWorkAPI}/v1/support/active`, body, global.gpAccessToken);
  }
  
  // // UPDATE STATUS OF WORK QUEUE ITEM ////
  static statusUpdateSupportQueueItem(supportCentreItemId, body, accessToken) {
    console.warn(`API | PATCH | Update Support Queue Item Status`);
    return Request.PATCH_json(`${process.env.adminWorkAPI}/v1/support/item/${supportCentreItemId}/status`, body, global.gpAccessToken);
  }

}


export default AdminWorkApi;
