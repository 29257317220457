import _ from 'lodash';

const initialState = {
  userStats: {},
};

const resetState = {
  userStats: {},
};


const AdminStatsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_ADMIN_WORK':
    {
      return resetState;
    }

    case 'RESET_STATE_ADMIN_WORK':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }
    
    case 'SYNC_ADMIN_STATS_USERS':
    {
      const clone = Object.assign({}, state);
      let dataSet = action.data;
      if (Array.isArray(dataSet) === true) {
        dataSet = action.data[0];
      }

    
      for (const [key, value] of Object.entries(dataSet)) {
        console.log(`${key}: ${value}`);
        try {
          clone.userStats[key] = value;
        } catch (e) {
          console.log(e);
        }
      }

      return clone;
    }

    
    default:
      return state;
  }
};

export default AdminStatsReducer;
