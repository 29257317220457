const axios = require('axios');
module.exports.RequestAxios_json = (method, url, accessToken, body, withHttpOnlyCookies) => {

  //console.log("accessToken + AXIOS");
  //console.log(accessToken);

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  //console.log("headers + AXIOS");
  //console.log(headers);


  return new Promise(((resolve, reject) => {
    console.log(`Axios ${url} | method: ${method} | body: ${body}`);
    axios({
      method: method,
      url: url,
      headers: headers,
      data: body,
      withCredentials: withHttpOnlyCookies,     //true/false passed in
      responseType: 'json',
      // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
      //xsrfCookieName: 'XSRF-TOKEN', // default
      // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
      //xsrfHeaderName: 'X-XSRF-TOKEN', // default
      // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
      //maxContentLength: 2000,
    
      // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
      //maxBodyLength: 2000,

    
    })
    .then((json) => {
      console.log(json);
      let jsonDataResponse = {};
      try {
        jsonDataResponse = json.data;
      } catch (e) {
        jsonDataResponse = {};
      }
      if (jsonDataResponse === undefined) {
        jsonDataResponse = {};
      }
      resolve(jsonDataResponse);
      
    })
    .catch((err) => {
      console.error(`Fetch Error | ${err}`);
      resolve(false);
    });
  }));
};

module.exports.GET_json = (url, accessToken, withHttpOnlyCookies = false) => module.exports.RequestAxios_json('GET', url, accessToken, null, withHttpOnlyCookies);
module.exports.PATCH_json = (url, body, accessToken, withHttpOnlyCookies = false) => module.exports.RequestAxios_json('PATCH', url, accessToken, body, withHttpOnlyCookies);
module.exports.POST_json = (url, body, accessToken, withHttpOnlyCookies = false) => module.exports.RequestAxios_json('POST', url, accessToken, body, withHttpOnlyCookies);
module.exports.DELETE_json = (url, body, accessToken, withHttpOnlyCookies = false) => module.exports.RequestAxios_json('DELETE', url, accessToken, body, withHttpOnlyCookies);

