/** USER ACTIONS ** */
import { db } from 'Store/client';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import SupportApi from './Api/SupportApi';
import AdminWorkApi from './Api/AdminWorkApi';




export const authStatusFalse_SUPPORT = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});


const uuidv1 = require('uuid/v1');

export const addGlobalToastMessage_SUPPORT = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});





///////////////////////////////////////////////////////
// CONTACT US - INTERACTION
export function recordContactUsInteraction(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    console.log("recordContactUsInteraction");
    console.log("apiAccessToken");
    console.log(apiAccessToken);




    return new Promise(((resolve, reject) => {
      const {
        messageSubject = '',
        messageDescription = '',
        contactName = '',
        contactEmail = '',
        contactPhone = '',
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      const apiBody = JSON.stringify({
        messageSubject,
        messageDescription,
        contactName,
        contactEmail,
        contactPhone,
      });

      //If AUTH, SEND AUTH ENDPOINT

      //If NO AUTH, SEND NO AUTH ENDPOINT


      
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'SupportApi',
        function: 'recordContactUsInteraction',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress

      let authedInteraction = false;
      if (apiAccessToken !== null && apiAccessToken !== undefined && apiAccessToken !== '') {
        if (apiAccessToken.length >20) {
          authedInteraction = true;
        }
      }

      if (authedInteraction === true) {
        SupportApi.sendContactUsInteractionWithAuth(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | sendContactUsInteractionWithAuth");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } else {

        SupportApi.sendContactUsInteractionWithoutAuth(apiBody)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | sendContactUsInteractionWithoutAuth");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } //end if
    }));
  };
}


///////////////////////////////////////////////////////
// FEEDBACK - INTERACTION
export function recordFeedbackInteraction(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    console.log("recordFeedbackInteraction");
    console.log("apiAccessToken");
    console.log(apiAccessToken);




    return new Promise(((resolve, reject) => {
      const {
        messageType = '',
        messageCategory = '',
        messageSubject = '',
        messageDescription = '',
        contactName = '',
        contactEmail = '',
        contactPhone = '',
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      const apiBody = JSON.stringify({
        messageType,
        messageCategory,
        messageSubject,
        messageDescription,
        contactName,
        contactEmail,
        contactPhone,
      });

      //If AUTH, SEND AUTH ENDPOINT

      //If NO AUTH, SEND NO AUTH ENDPOINT


      
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'SupportApi',
        function: 'recordFeedbackInteraction',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress

      let authedInteraction = false;
      if (apiAccessToken !== null && apiAccessToken !== undefined && apiAccessToken !== '') {
        if (apiAccessToken.length >20) {
          authedInteraction = true;
        }
      }

      if (authedInteraction === true) {
        SupportApi.sendFeedbackInteractionWithAuth(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | sendFeedbackInteractionWithoutAuth");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } else {
        SupportApi.sendFeedbackInteractionWithoutAuth(apiBody)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | sendFeedbackInteractionWithoutAuth");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } //end if
    }));
  };
}






///////////////////////////////////////////////////////
// SUPPORT CASE - CREATE - INTERACTION
export function createSupportCaseInteraction(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    console.log("createSupportCaseInteraction");
    console.log("apiAccessToken");
    console.log(apiAccessToken);

    return new Promise(((resolve, reject) => {
      const {
        supportCaseCategory = '',
        customerIsAuth = '',
        customerLoggedInId = '',
        customerLoggedInFirstName = '',

        caseType = '',
        user = '',
        business = '',
        businessDetail = '',
        messageSubject = '',
        messageDescription = '',
        customerContactName = '',
        customerContactEmail = '',

      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      let apiBody = JSON.stringify({});

      if (supportCaseCategory === 'accountBilling') {
        apiBody = JSON.stringify({
          supportCaseCategory,
          customerIsAuth,
          customerLoggedInId,
          customerLoggedInFirstName,

          caseType,
          user,
          business,
          businessDetail,
          messageSubject,
          messageDescription,
          customerContactName,
          customerContactEmail,
        });


      } else if (supportCaseCategory === 'technicalSupport') {
        apiBody = JSON.stringify({
          supportCaseCategory,
          customerIsAuth,
          customerLoggedInId,
          customerLoggedInFirstName,

          caseType,
          user,
          business,
          businessDetail,
          messageSubject,
          messageDescription,
          customerContactName,
          customerContactEmail,
        });
      }



      //If AUTH, SEND AUTH ENDPOINT

      //If NO AUTH, SEND NO AUTH ENDPOINT


      
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'SupportApi',
        function: 'createSupportCaseInteraction',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress

      let authedInteraction = false;
      if (apiAccessToken !== null && apiAccessToken !== undefined && apiAccessToken !== '') {
        if (apiAccessToken.length >20) {
          authedInteraction = true;
        }
      }

      if (authedInteraction === true) {
        SupportApi.createSupportCaseWithAuth(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createSupportCaseWithAuth");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } else {

        SupportApi.createSupportCaseWithoutAuth(apiBody)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createSupportCaseWithoutAuth");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } //end if
    }));
  };
}





/*
///////////////////////////////////////////////////////
// SYNC SUPPORT QUEUE - MOVED TO ADMIN WORK
export function syncSupportQueueAdmin(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    console.log("syncSupportQueueAdmin");
    console.log("apiAccessToken");
    console.log(apiAccessToken);

    return new Promise(((resolve, reject) => {
      const {} = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      const apiBody = JSON.stringify({
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'SupportApi',
        function: 'syncSupportQueueAdmin',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress

      let authedInteraction = false;
      if (apiAccessToken !== null && apiAccessToken !== undefined && apiAccessToken !== '') {
        if (apiAccessToken.length >20) {
          authedInteraction = true;
        }
      }

      if (authedInteraction === true) {
        AdminWorkApi.syncSupportQueue(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | syncSupportQueue");
          console.log(apiResponseData);

          if (apiResponseData.status === true) {
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_SUPPORT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_SUPPORT({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_SUPPORT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      } else {
        //User is not authed, no action required.
        resolve({
          status: true,
          message: 'no action required',
        });
      } //end if
    }));
  };
}
*/