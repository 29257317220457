import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Redirect } from 'react-router-dom';

// Components
import CommonTransit from './CommonTransit';

//Actions
import { } from 'Actions';


class CommonRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const thisComponent = this.constructor.name;

  }

  
  
  render() {
    const { exact, key, path, businessAccountRoute, userRoutePermissions } = this.props;

    //console.log(`====== Common Route ======`);

    const DynamicRouteComponent = this.props.component;

    return <CommonTransit path={this.props.path}
    userHasRoutePermission="true" userBusinessRoutePermissions="" 
    {...this.props}  />;
      
  }
}


const mapStateToProps = (state, ownProps) => {

  return {
    
  };

};


const mapDispatchToProps = {
 
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CommonRoute);
