import Request from './Request';

class BusinessVerificationApi {
  static generateVerificationTokenWebsite(businessId, body, accessToken) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/${businessId}/generate/token/complex`, body, global.gpAccessToken);
  }

  static businessIdentitiesVerify(businessId, body, accessToken) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/${businessId}/verification/identites`, body, global.gpAccessToken);
  }




  static generateVerificationTokenEmail(body, accessToken) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/generate-email-verification-token`, body, global.gpAccessToken);
  }

  static generateVerificationTokenBusinessAddress(body, accessToken) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/generate-address-verification-token`, body, global.gpAccessToken);
  }

  static businessVerificationCheckWebsite(body, accessToken) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/verify-website`, body, global.gpAccessToken);
  }


}

export default BusinessVerificationApi;
