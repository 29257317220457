/** Common Actions ** */
import store from 'Store/ConfigureStore';

//Common actions for API Monitoring//
class CommonActions {
  /****************************************************
   * RESET ACTIONS
   * These merge the inital state with the updated 
   * state structure.
   ****************************************************/
  static RESET_STATE_CORE = () => ({
    type: 'RESET_STATE_CORE',
  });
  static RESET_STATE_ADMIN_WORK = () => ({
    type: 'RESET_STATE_ADMIN_WORK',
  });
  static RESET_STATE_API_ACTIONS = () => ({
    type: 'RESET_STATE_API_ACTIONS',
  });
  static RESET_STATE_APP = () => ({
    type: 'RESET_STATE_APP',
  });
  static RESET_STATE_AUTH = () => ({
    type: 'RESET_STATE_AUTH',
  });
  static RESET_STATE_BUSINESS_IDENTITIES = () => ({
    type: 'RESET_STATE_BUSINESS_IDENTITIES',
  });
  static RESET_STATE_COMMON_ACTIONS = () => ({
    type: 'RESET_STATE_COMMON_ACTIONS',
  });
  static RESET_STATE_NOTIFICATIONS = () => ({
    type: 'RESET_STATE_NOTIFICATIONS',
  });
  static RESET_STATE_PRODUCT = () => ({
    type: 'RESET_STATE_PRODUCT',
  });
  static RESET_STATE_REPORTING = () => ({
    type: 'RESET_STATE_REPORTING',
  });
  static RESET_STATE_SIDEBAR = () => ({
    type: 'RESET_STATE_SIDEBAR',
  });
  static RESET_STATE_SOCKET = () => ({
    type: 'RESET_STATE_SOCKET',
  });
  static RESET_STATE_SYNC = () => ({
    type: 'RESET_STATE_SYNC',
  });
  static RESET_STATE_UI = () => ({
    type: 'RESET_STATE_UI',
  });
  static RESET_STATE_USER = () => ({
    type: 'RESET_STATE_USER',
  });
  static RESET_STATE_VERIFY = () => ({
    type: 'RESET_STATE_VERIFY',
  });

  /****************************************************
   * CLEAR ACTIONS
   * These clear the state back to original removing 
   * all data.
   ****************************************************/
  static CLEAR_STATE_USER = () => ({
    type: 'CLEAR_STATE_USER',
  });
  static CLEAR_STATE_CORE = () => ({
    type: 'CLEAR_STATE_CORE',
  });
  static CLEAR_STATE_ADMIN_WORK = () => ({
    type: 'CLEAR_STATE_ADMIN_WORK',
  });
  static CLEAR_STATE_API_ACTIONS = () => ({
    type: 'CLEAR_STATE_API_ACTIONS',
  });
  static CLEAR_STATE_APP = () => ({
    type: 'CLEAR_STATE_APP',
  });
  static CLEAR_STATE_AUTH = () => ({
    type: 'CLEAR_STATE_AUTH',
  });
  static CLEAR_STATE_BUSINESS_IDENTITIES = () => ({
    type: 'CLEAR_STATE_BUSINESS_IDENTITIES',
  });
  static CLEAR_STATE_COMMON_ACTIONS = () => ({
    type: 'CLEAR_STATE_COMMON_ACTIONS',
  });
  static CLEAR_STATE_NOTIFICATIONS = () => ({
    type: 'CLEAR_STATE_NOTIFICATIONS',
  });
  static CLEAR_STATE_PRODUCT = () => ({
    type: 'CLEAR_STATE_PRODUCT',
  });
  static CLEAR_STATE_REPORTING = () => ({
    type: 'CLEAR_STATE_REPORTING',
  });
  static CLEAR_STATE_SIDEBAR = () => ({
    type: 'CLEAR_STATE_SIDEBAR',
  });
  static CLEAR_STATE_SOCKET = () => ({
    type: 'CLEAR_STATE_SOCKET',
  });
  static CLEAR_STATE_SYNC = () => ({
    type: 'CLEAR_STATE_SYNC',
  });
  static CLEAR_STATE_UI = () => ({
    type: 'CLEAR_STATE_UI',
  });
  static CLEAR_STATE_USER = () => ({
    type: 'CLEAR_STATE_USER',
  });
  static CLEAR_STATE_VERIFY = () => ({
    type: 'CLEAR_STATE_VERIFY',
  });
  
  
}

export default CommonActions;



export const toggleOverlayOnScreen = (request, id, data) => ({
  type: 'TOGGLE_OVERLAY_ON_SCREEN',
  request,
  id,
  data,
});

export const toggleOverlayOnScreenResponsive = (request, id, data) => ({
  type: 'TOGGLE_OVERLAY_ON_SCREEN_RESPONSIVE',
  request,
  id,
  data,
});

export const toggleOverlayOnScreenResponsiveLoading = (request, id, data) => ({
  type: 'TOGGLE_OVERLAY_ON_SCREEN_RESPONSIVE_LOADING',
  request,
  id,
  data,
});

export const updateOverlayOnScreenResponsiveLoadingData = data => ({
  type: 'UPDATE_OVERLAY_ON_SCREEN_RESPONSIVE_LOADING_DATA',
  data,
});



/**********************************************************
 * CLIENT HAS AN ISSUE WITH STATE. CAN CLEAN LOCAL STORAGE
 *********************************************************/
export const cleanClientLocalStorage = () => ({
  type: 'RESET_STATE',
});





export const RESET_STATE_CORE = () => ({
  type: 'RESET_STATE_CORE',
});
export const RESET_STATE_ADMIN_WORK = () => ({
  type: 'RESET_STATE_ADMIN_WORK',
});
export const RESET_STATE_API_ACTIONS = () => ({
  type: 'RESET_STATE_API_ACTIONS',
});
export const RESET_STATE_APP = () => ({
  type: 'RESET_STATE_APP',
});
export const RESET_STATE_AUTH = () => ({
  type: 'RESET_STATE_AUTH',
});
export const RESET_STATE_BUSINESS_IDENTITIES = () => ({
  type: 'RESET_STATE_BUSINESS_IDENTITIES',
});
export const RESET_STATE_COMMON_ACTIONS = () => ({
  type: 'RESET_STATE_COMMON_ACTIONS',
});
export const RESET_STATE_NOTIFICATIONS = () => ({
  type: 'RESET_STATE_NOTIFICATIONS',
});
export const RESET_STATE_PRODUCT = () => ({
  type: 'RESET_STATE_PRODUCT',
});
export const RESET_STATE_REPORTING = () => ({
  type: 'RESET_STATE_REPORTING',
});
export const RESET_STATE_SIDEBAR = () => ({
  type: 'RESET_STATE_SIDEBAR',
});
export const RESET_STATE_SOCKET = () => ({
  type: 'RESET_STATE_SOCKET',
});
export const RESET_STATE_SYNC = () => ({
  type: 'RESET_STATE_SYNC',
});
export const RESET_STATE_UI = () => ({
  type: 'RESET_STATE_UI',
});
export const RESET_STATE_USER = () => ({
  type: 'RESET_STATE_USER',
});
export const RESET_STATE_VERIFY = () => ({
  type: 'RESET_STATE_VERIFY',
});



export function resetAppData(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
  
      dispatch(RESET_STATE_CORE); 
      dispatch(RESET_STATE_ADMIN_WORK); 
      dispatch(RESET_STATE_API_ACTIONS); 
      dispatch(RESET_STATE_APP); 
      dispatch(RESET_STATE_AUTH); 
      dispatch(RESET_STATE_BUSINESS_IDENTITIES); 
      dispatch(RESET_STATE_COMMON_ACTIONS); 
      dispatch(RESET_STATE_NOTIFICATIONS); 
      dispatch(RESET_STATE_PRODUCT); 
      dispatch(RESET_STATE_REPORTING); 
      dispatch(RESET_STATE_SIDEBAR); 
      dispatch(RESET_STATE_SOCKET); 
      dispatch(RESET_STATE_SYNC); 
      dispatch(RESET_STATE_UI); 
      dispatch(RESET_STATE_USER); 
      dispatch(RESET_STATE_VERIFY); 
 

      console.table([
        {
          name: 'reset',
          value: 'RESET_STATE_CORE',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_ADMIN_WORK',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_API_ACTIONS',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_APP',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_AUTH',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_BUSINESS_IDENTITIES',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_COMMON_ACTIONS',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_NOTIFICATIONS',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_PRODUCT',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_REPORTING',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_SIDEBAR',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_SOCKET',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_SYNC',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_UI',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_USER',
        },
        {
          name: 'reset',
          value: 'RESET_STATE_VERIFY',
        },
      ]);


    }));
  };
}