/** BUSINESS VERIFICATION ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import BusinessApi from './api/BusinessApi';
//import BusinessConnectApi from './api/BusinessConnectApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_BUSINESS_CONNECT = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_BUSINESS_CONNECT = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

// UI - New Customer Connection
export const customerConnectNew = data => ({
  type: 'SET_CUSTOMER_CONNECT_INTERACTION_NEW',
  data,
});
export const customerConnectLoad = data => ({
  type: 'SET_CUSTOMER_CONNECT_INTERACTION_LOAD',
  data,
});

export const interactionStatusUpdate = () => ({
  type: 'UPDATE_CUSTOMER_CONNECT_INTERACTIONS_STATUS',
});




export const customerConnectSetBusinessProfileId = data => ({
  type: 'SET_CUSTOMER_CONNECT_INTERACTION_BUSINESS_PROFILE',
  data,
});


export const customerConnectInteraction = customerConnectResult => ({
  type: 'SET_CUSTOMER_CONNECT_INTERACTION_RESULT',
  customerConnectResult,
});


export function customerConnectShortMessageResponse(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { connectRequest } = body;
      
      const businessId = connectRequest.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const businessProfileId = connectRequest.businessProfileId;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);


      try {
        connectRequest.businessDataRegion = businessDataRegion;
      } catch (e) {}


      const apiBody = JSON.stringify(connectRequest);

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ConnectApi',
        function: 'createCustomerConnectCode',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createCustomerConnectCode_inPerson(businessDataRegion, businessId, businessProfileId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          let toastData = {};
          console.warn('Return | API | createCustomerConnectCode_inPerson');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(customerConnectInteraction(apiResponseData.data.connectTransaction));

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_CONNECT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_CONNECT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


export function createCustomerConnectCode_inPerson(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { connectRequest } = body;
      
      const businessId = connectRequest.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const businessProfileId = connectRequest.businessProfileId;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);


      try {
        connectRequest.businessDataRegion = businessDataRegion;
      } catch (e) {}


      const apiBody = JSON.stringify(connectRequest);

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ConnectApi',
        function: 'createCustomerConnectCode',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createCustomerConnectCode_inPerson(businessDataRegion, businessId, businessProfileId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          let toastData = {};
          console.warn('Return | API | createCustomerConnectCode_inPerson');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(customerConnectInteraction(apiResponseData.data.connectTransaction));

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_CONNECT());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_CONNECT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}




export function createCustomerConnectCode(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { connectRequest } = body;
      
      const businessId = connectRequest.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      const businessProfileId = connectRequest.businessProfileId;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);


      try {
        connectRequest.businessDataRegion = businessDataRegion;
      } catch (e) {}


      const apiBody = JSON.stringify(connectRequest);
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ConnectApi',
        function: 'createCustomerConnectCode',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createCustomerConnectCode(businessDataRegion, businessId, businessProfileId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          let toastData = {};
          console.warn('Return | API | createCustomerConnectCode');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            //Handle unauthorised requests
            if (apiResponseData.statusCode === 401) {
              //Auth Issue, re-login
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));

            } else {

              dispatch(customerConnectInteraction(apiResponseData.data.connectTransaction));

              ///////////////////////////////
              // APP STATUS TOAST MESSAGING
              ///////////////////////////////
              if (apiResponseData.data.appStatus === true) {
                //Display a success Toast if configured
                if (apiResponseData.data.appMessageSuccess !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageSuccess, 
                    type: 'success',
                  }
                  dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));
                }
              } else {
                //// AUTH CHECK ////
                try {
                  if (apiResponseData.data.authStatus === false) {
                    dispatch(authStatusFalse_BUSINESS_CONNECT());
                  }
                } catch (e) {}
                //Display a failure Toast if configured
                if (apiResponseData.data.appMessageFailure !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageFailure, 
                    type: 'error',
                  }
                  dispatch(addGlobalToastMessage_BUSINESS_CONNECT(toastData));
                }
              }

              
              dispatch(ApiUI.updateUIApiActivity({
                id: apiActivityId,
                status: 'successful',
              }));

            }
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_CONNECT({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}















export const setBusinessConnectActivePhoneToken = tokenObj => ({
  type: 'SET_BUSINESS_CONNECT_ACTIVE_PHONE_TOKEN',
  tokenObj,
});
export const setBusinessConnectActiveEmailToken = tokenObj => ({
  type: 'SET_BUSINESS_CONNECT_ACTIVE_EMAIL_TOKEN',
  tokenObj,
});

