import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import BusinessApi from './api/BusinessApi';

//import BusinessIdentityApi from './api/BusinessIdentityApi';
//import BusinessVerificationApi from './api/BusinessVerificationApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_BUSINESS_USERS = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_BUSINESS_USERS = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const syncBusinessData_BUSINESS_USERS = businesses => ({
  type: 'SYNC_BUSINESS_DATA',
  businesses,
});
export const businessDataRemoveUiDisplayType = businessId => ({
  type: 'BUSINESS_DATA_REMOVE_UI_DISPLAY_TYPE',
  businessId,
});
export const businessDataRemoveBusinessId = businessId => ({
  type: 'BUSINESS_DATA_REMOVE_BUSINESSID',
  businessId,
});



export function businessUsersAddUser(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      const { data } = body;

      const newUserAccountId = data.newUserAccountId;
      const businessId = data.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      let userId = '';
      try {
        userId = id;
      } catch (e) {}

      //console.log('apiActivityId');
      //console.log(apiActivityId);

      console.table([
        {
          name: 'api',
          value: 'Business',
        },
        {
          name: 'function',
          value: 'businessUsersAddUser',
        },
        {
          name: 'AccessToken',
          value: apiAccessToken,
        },
      ]);

      const apiBody = JSON.stringify({
        businessId,
        newUserAccountId,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessUsersApi',
        function: 'businessUsersAddUser',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessUser(businessDataRegion, businessId, userId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | createBusinessUser');

        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_BUSINESS_USERS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_BUSINESS_USERS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_BUSINESS_USERS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_BUSINESS_USERS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_BUSINESS_USERS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);

      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}


export function businessUsersRemoveUser(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { id } = getState().User; //logged in user
    return new Promise(((resolve, reject) => {
      const { data = {} } = body;
      console.log('data');
      console.log(data);

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      let businessId = '';
      try {
        businessId = data.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let userIdToRemove = '';
      try {
        userIdToRemove = data.userId;
      } catch (e) {}


      let userId = '';
      try {
        userId = id;
      } catch (e) {}

      const apiBody = JSON.stringify({
        businessId,
        userIdToRemove,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessUsersApi',
        function: 'businessUsersAddUser',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusinessUser(businessDataRegion, businessId, userId, userIdToRemove, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | deleteBusinessUser');
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData_BUSINESS_USERS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_USERS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_USERS());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_USERS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_USERS({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/*
addBusinessUser      (businessId, accessToken)      | invite a user to be added to this business
removeBusinessUser   (businessId, userId, body, accessToken)
changeBusinessUser   (businessId, userId, body, accessToken)

addBusinessUserPermission       (businessId, userId, body, accessToken)
removeBusinessUserPermission       (businessId, userId, body, accessToken)

*/


export function addBusinessUserPermission(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      const userId = id;  //userId from state (logged in user)

      const { business } = body;
      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      const administratorId = business.userId;
      const permissionName = business.permissionName;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
     
      const apiBody = JSON.stringify({
        businessId,
        administratorId,
        permissionName,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessUsersApi',
        function: 'addBusinessUserPermission',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessUserPermission(businessDataRegion, businessId, userId, administratorId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | createBusinessUserPermission');
        //console.log(apiResponseData);
        
        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_BUSINESS_USERS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_BUSINESS_USERS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_BUSINESS_USERS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_BUSINESS_USERS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_BUSINESS_USERS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}

export function removeBusinessUserPermission(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      const userId = id;  //userId from state (logged in user)

      const { business } = body;
      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      const administratorId = business.userId;
      const permissionName = business.permissionName;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      const apiBody = JSON.stringify({
        businessId,
        administratorId,
        permissionName,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessUsersApi',
        function: 'removeBusinessUserPermission',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusinessUserPermission(businessDataRegion, businessId, userId, administratorId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | deleteBusinessUserPermission');
        //console.log(apiResponseData);
      
        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_BUSINESS_USERS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_BUSINESS_USERS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_BUSINESS_USERS());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_BUSINESS_USERS({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_BUSINESS_USERS({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/** ********************************************************************
 *  USERS ACTIONS ON BUSINESS ACCOUNT
 *
 ********************************************************************* */

export function userBusinessInvitationResponse(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { id } = getState().User;
    return new Promise(((resolve, reject) => {
      const { data } = body;

      console.log(JSON.stringify(data));

      const userInvitationResponse = data.userInvitationResponse;
      const businessId = data.businessId;

      console.log("businessId");
      console.log(businessId);

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      const userId = id;

      const apiBody = JSON.stringify({
        businessId,
        userId,
        userInvitationResponse,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessUsersApi',
        function: 'userBusinessInvitationResponse',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.userBusinessInvitationResponse(businessDataRegion, userInvitationResponse, businessId, userId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn(`API | RESPONSE | userBusinessInvitationResponse | ${userInvitationResponse}`);
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(businessDataRemoveUiDisplayType(businessId));
            if (userInvitationResponse === 'decline') {
              dispatch(businessDataRemoveBusinessId(businessId));
            }
            dispatch(syncBusinessData_BUSINESS_USERS(apiResponseData.data.businessResult));
  
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_USERS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_USERS());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_USERS({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_USERS({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });


    }));
  };
}
