import _ from 'lodash';

/** *** API ACTIONS (Monitor and report on API activity) ***** */
const initialState = {
  healthReportingPeriodStart: Date.now(), // Date/time for this stat period
  AuthFailureCount: 0,
  ApiSuccessCount: 0,
  ApiFailureCount: 0,
  ApiInProgress: 0,
  ApiErrors: {
    E400: 0, // Bad Reqeust
    E401: 0, // Unauthorized
    E403: 0, // Forbidden
    E404: 0, // Not Found
    E405: 0, // Method Not Allowed
    E408: 0, // Request Timeout
    E429: 0, // Too Many Requests
    E500: 0, // Internal Server Error
    E502: 0, // Bad Gateway
    E503: 0, // Service Unavailable
    E504: 0, // Gateway Timeout
    Other: 0, // Other
  },
  ApiActive: [], // Array of all the active APIs
  ApiFailures: [], // Array of all the failed APIs

  ApiLogIds: [], // All the log ids for troubleshooting

};

const resetState = {
  healthReportingPeriodStart: Date.now(), // Date/time for this stat period
  AuthFailureCount: 0,
  ApiSuccessCount: 0,
  ApiFailureCount: 0,
  ApiInProgress: 0,
  ApiErrors: {
    E400: 0, // Bad Reqeust
    E401: 0, // Unauthorized
    E403: 0, // Forbidden
    E404: 0, // Not Found
    E405: 0, // Method Not Allowed
    E408: 0, // Request Timeout
    E429: 0, // Too Many Requests
    E500: 0, // Internal Server Error
    E502: 0, // Bad Gateway
    E503: 0, // Service Unavailable
    E504: 0, // Gateway Timeout
    Other: 0, // Other
  },
  ApiActive: [], // Array of all the active APIs
  ApiFailures: [], // Array of all the failed APIs

  ApiLogIds: [], // All the log ids for troubleshooting

};


const ApiActionsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_API_ACTIONS':
    {
      return resetState;
    }

    case 'RESET_STATE_API_ACTIONS':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    

    case 'REMOVE_API_ACTIVE_EVENT':
    {
      const clone = Object.assign({}, state);

      try {
        // Remove from the Active API
        const index = clone.ApiActive.findIndex(x => x.id === action.data.id);
        clone.ApiActive.splice(index, 1);
      } catch (e) {}
      
      if (clone.ApiInProgress > 0) {
        clone.ApiInProgress -= 1;
      }
  
      return clone;
    }
    
    case 'CLEAN_API_ACTIVE_EVENTS':
    {
      const clone = Object.assign({}, state);

      try {
        for (let x = clone.ApiActive.length-1; x >=0; x--) {
          if (typeof clone.ApiActive[x] !== 'object' || clone.ApiActive[x] === null) {
            clone.ApiActive.splice(x, 1);
          }
        }
      } catch (e) {}
  
      return clone;
    }


    case 'SET_HEALTH_REPORTING_PERIOD_START':
    {
      const clone = Object.assign({}, state);
      clone.healthReportingPeriodStart = Date.now();
      return clone;
    }
    


    case 'AUTH_FAILURE_COUNT':
    {
      const clone = Object.assign({}, state);
      clone.ApiErrors.AuthFailureCount += 1;
      return clone;
    }

    case 'UPDATE_API_STATUS_CODES':
    {
      const clone = Object.assign({}, state);
      //console.log('UPDATE_API_STATUS_CODES');
      //console.log(action);
      //console.log(action.statusCode);

      if (action.statusCode.toString() === '500') {
        clone.ApiErrors.E500 += 1;
      }
      if (action.statusCode.toString() === '502') {
        clone.ApiErrors.E502 += 1;
      }
      if (action.statusCode.toString() === '503') {
        clone.ApiErrors.E503 += 1;
      }
      if (action.statusCode.toString() === '504') {
        clone.ApiErrors.E504 += 1;
      }


      switch (action.statusCode) {
        case '400': { clone.ApiErrors.E400 += 1; }
        case '401': { clone.ApiErrors.E401 += 1; }
        case '403': { clone.ApiErrors.E403 += 1; }
        case '404': { clone.ApiErrors.E404 += 1; }
        case '405': { clone.ApiErrors.E405 += 1; }
        case '408': { clone.ApiErrors.E408 += 1; }
        case '429': { clone.ApiErrors.E429 += 1; }

        // case 500: { clone.ApiErrors.E500 += 1; }
        // case 502: { clone.ApiErrors.E502 += 1; }
        // case 503: { clone.ApiErrors.E503 += 1; }
        // case 504: { clone.ApiErrors.E504 += 1; }
        default: {
          clone.ApiErrors.Other += 1;
        }
      }


      return clone;
    }

    case 'UPDATE_API_SUCCESS_COUNT':
    {
      const clone = Object.assign({}, state);

      try {
        // Remove from the Active API
        const index = clone.ApiActive.findIndex(x => x.id === action.apiMonitoring.id);
        clone.ApiActive.splice(index, 1);
      } catch (e) {}

      if (clone.ApiSuccessCount >= 0) {
        clone.ApiSuccessCount += 1;
      }
      if (clone.ApiInProgress > 0) {
        clone.ApiInProgress -= 1;
      }
  
      return clone;
    }

    case 'UPDATE_API_FAILURE_COUNT':
    {
      const clone = Object.assign({}, state);
      try {
        //console.log(action.apiMonitoring);
      } catch (e) {}
      try {
        // Add the Failure to the API
        clone.ApiFailures.push(action.apiMonitoring);
      } catch (e) {}
      try {
        // Remove from the Active API
        const index = clone.ApiActive.findIndex(x => x.id === action.apiMonitoring.id);
        clone.ApiActive.splice(index, 1);
      } catch (e) {}

      if (clone.ApiFailureCount >= 0) {
        clone.ApiFailureCount += 1;
      }
      if (clone.ApiInProgress > 0) {
        clone.ApiInProgress -= 1;
      }

      return clone;
    }

    case 'UPDATE_API_INPROGRESS_COUNT':
    {
      const clone = Object.assign({}, state);
      //console.log(action);
      try {
        //console.log(action.apiMonitoring);
      } catch (e) {}
      try {
        // Record API Activity
        clone.ApiActive.push(action.apiMonitoring);
      } catch (e) {}

      try {
        // Remove NULL, etc Active API
        clone.ApiActive.filter(x => x);
      } catch (e) {}
      // [1,"", null, NaN, 2, undefined,4,5,6].filter(x => x);


      if (clone.ApiInProgress >= 0) {
        clone.ApiInProgress += 1;
      }
      return clone;
    }

    default:
      return state;
  }
};

export default ApiActionsReducer;
