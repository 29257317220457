/** USER ACTIONS ** */
import { db } from 'Store/client';

import store from 'Store/ConfigureStore';
//import AuthApi from './api/AuthApi';
//import UsersApi from './api/UsersApi';

export const authStatusFalse_BUSINESS = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const setStateBusinessProfile = businessProfile => ({
  type: 'SET_BUSINESS_PROFILE',
  businessProfile,
});

export function setBusinessProfile(businessProfile) {
  return (dispatch, getState) => {
    const {apiAccessToken} = getState().Auth;
    //console.log("Access Token from state");
    //console.log(apiAccessToken);

    const body = JSON.stringify({
      body: 'nothing',
    });
    const accessToken = apiAccessToken;
    dispatch(setStateBusinessProfile(businessProfile));
    /*
    BusinessVerificationApi.generateVerificationTokenWebsite(body, accessToken)
      .then((result) => {
        dispatch(setBusinessVerification_tokenWebsite(result.token));
        //console.log('return done from verification api');
      })
      .catch((error) => {
        throw (error);
      });
    */
  };
}

export const removeBusinessProfileBusinessRegistryItem = index => ({
  type: 'REMOVE_BUSINESS_PROFILE_BUSINESS_REGISTRY_ITEM',
  index,
});

