import Request from './Request';
import RequestRegion from './RequestRegion';


class DemoApi {


  // // CONNECT DEMO  ////
  static connectDemoUser_demo1_notification(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Connect Demo 1`);
    return RequestRegion.POST_json(userDataRegion, 'connectDemoApi', `/v1/notification/user/${userId}/demo1`, body, global.gpAccessToken);
  }
  static connectDemoUser_phone_validSecurityCode(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Connect Demo | Valid Security Code`);
    return RequestRegion.POST_json(userDataRegion, 'connectDemoApi', `/v1/phone/valid-security-code/user/${userId}`, body, global.gpAccessToken);
  }
  static connectDemoUser_phone_invalidSecurityCode(userDataRegion, userId, body, accessToken) {
    console.warn(`API | POST | Connect Demo | Inalid Security Code`);
    return RequestRegion.POST_json(userDataRegion, 'connectDemoApi', `/v1/phone/invalid-security-code/user/${userId}`, body, global.gpAccessToken);
  }

  
}


export default DemoApi;
