/** BUSINESS IDENTITY ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import BusinessApi from './api/BusinessApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_BUSINESS_REPORTING = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_BUSINESS_REPORTING = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const syncLogsData = data => ({
  type: 'SYNC_BUSINESS_REPORTING_LOGS',
  data,
});


export function syncLogs(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      let businessId = '';
      try {
        businessId = data.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessId,
        userId: _id, // Not required, just info
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.syncLogs(businessDataRegion, businessId, apiBody, apiAccessToken)
        .then((apiResponse) => {
          console.warn('API | RESPONSE | syncLogs');
          //console.log(apiResponse);
          //Incorrect way - not sending back a correctly formatted reponse (status, etc)
          dispatch(syncLogsData({
            businessId,
            logEntries: apiResponse,
          }));




          if (apiResponse.status === true) {
            dispatch(syncLogsData({
              businessId,
              logEntries: apiResponse.data.logResult,
            }));
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
          } else {
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponse);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
