import Request from './Request';

class GeneralApi {
  // NEW API
  static syncProducts(body, accessToken) {
    console.warn('API | POST | List Products');
    return Request.POST_json(`${process.env.generalAPI}/v1/products/list`, body, global.gpAccessToken);
  }

}
export default GeneralApi;
