import Request from './Request';
import RequestRegion from './RequestRegion';


class StripePaymentsApi {

  //Create Business Subscription
  //This will create a stripe account for the business, setup the subscription billing
  static createBusinessSubscription(businessDataRegion, businessId, body, accessToken) {
    console.warn('API | POST | Subscribe Business to Subscription Plan');
    return RequestRegion.POST_json(businessDataRegion, 'paymentsApi', `/v1/business/${businessId}/subscribe`, body, global.gpAccessToken);
  }

  static updateBusinessSubscriptionPayment(businessDataRegion, businessId, body, accessToken) {
    console.warn('API | POST | Update Subscription Business Payment');
    return RequestRegion.POST_json(businessDataRegion, 'paymentsApi', `/v1/card/business/${businessId}/update`, body, global.gpAccessToken);
  }
  

  static cancelBusinessSubscription(businessDataRegion, businessId, subscriptionId, body, accessToken) {
    console.warn('API | POST | Cancel a Subscription Plan');
    return RequestRegion.POST_json(businessDataRegion, 'paymentsApi', `/v1/business/${businessId}/subscription/${subscriptionId}/cancel`, body, global.gpAccessToken);
  }

  // // SUBSCRIPTION - LIST INVOICES ////

  static listBusinessInvoices(businessDataRegion, businessId, body, accessToken) {
    console.warn('API | POST | List business invoices');
    return RequestRegion.POST_json(businessDataRegion, 'paymentsApi', `/v1/business/${businessId}/invoices/list`, body, global.gpAccessToken);
  }



 





  // USERS (HOW DO I HANDLE BOTH A USER AND A BUSINESS - SEPARATE APIS????)
  static createPaymentUser(customerId, accessToken) {
    console.warn('API | POST | Create Payments User');
    return Request.POST_json(`${process.env.paymentsAPI}/v1/user/${customerId}`, null, global.gpAccessToken);
  }
  static getPaymentUser(customerId, accessToken) {
    console.warn('API | GET | Get Payments User');
    return Request.GET_json(`${process.env.paymentsAPI}/v1/user/${customerId}`, null, global.gpAccessToken);
  }
  static updatePaymentUser(customerId, accessToken) {
    console.warn('API | PATCH | Update Payments User');
    return Request.PATCH_json(`${process.env.paymentsAPI}/v1/user/${customerId}`, null, global.gpAccessToken);
  }
  static deletePaymentUser(customerId, accessToken) {
    console.warn('API | DELETE | Delete Payments User');
    return Request.DELETE_json(`${process.env.paymentsAPI}/v1/user/${customerId}`, null, global.gpAccessToken);
  }

  // SUBSCRIBE USER TO PLANS
  static subscribeUserToSubscriptionPlan(customerId, planId, accessToken) {
    console.warn('API | POST | Subscribe user to subscription plan');
    return Request.POST_json(`${process.env.paymentsAPI}/v1/user/${customerId}/subscribe/${planId}`, null, global.gpAccessToken);
  }
  static getAllUserSubscriptionPlans(customerId, accessToken) {
    console.warn('API | GET | Get User Subscription Plans (all)');
    return Request.GET_json(`${process.env.paymentsAPI}/v1/user/${customerId}/subscribe`, null, global.gpAccessToken);
  }
  static getUserSubscriptionPlan(customerId, planId, accessToken) {
    console.warn('API | GET | Get User Subscription Plan (one)');
    return Request.GET_json(`${process.env.paymentsAPI}/v1/user/${customerId}/subscribe/${planId}`, null, global.gpAccessToken);
  }
  static updatePaymentUser(customerId, planId, accessToken) {
    console.warn('API | PATCH | Update User Subscription Plan');
    return Request.PATCH_json(`${process.env.paymentsAPI}/v1/user/${customerId}/subscribe/${planId}`, null, global.gpAccessToken);
  }
  static deletePaymentUser(customerId, planId, accessToken) {
    console.warn('API | DELETE | Delete User from Subscription Plan');
    return Request.DELETE_json(`${process.env.paymentsAPI}/v1/user/${customerId}/subscribe/${planId}`, null, global.gpAccessToken);
  }

  // USAGE REPORTS
  static getSubscriptionUsage(customerId, subscriptionItemId, accessToken) {
    console.warn('API | GET | Get the Usage for a Subscription Plan');
    return Request.GET_json(`${process.env.paymentsAPI}/v1/user/${customerId}/billing/${subscriptionItemId}`, null, global.gpAccessToken);
  }


   // ADHOC CHARGES
   static createAdhocChange(body, accessToken) {
    console.warn('API | POST | Create an Adhoc Charge');
    return Request.POST_json(`${process.env.paymentsAPI}/v1/charge`, body, global.gpAccessToken);
  }
  static getAdhocChange(chargeId, body, accessToken) {
    console.warn('API | GET | Get an Adhoc Charge');
    return Request.GET_json(`${process.env.paymentsAPI}/v1/charge/${chargeId}`, body, global.gpAccessToken);
  }
  static updateAdhocChange(chargeId, body, accessToken) {
    console.warn('API | POST | Update an Adhoc Charge');
    return Request.POST_json(`${process.env.paymentsAPI}/v1/charge/${chargeId}`, body, global.gpAccessToken);
  }
  static captureAdhocChange(chargeId, body, accessToken) {
    console.warn('API | POST | Capture an Adhoc Charge');
    return Request.POST_json(`${process.env.paymentsAPI}/v1/charge/${chargeId}/capture`, body, global.gpAccessToken);
  }




  //Old Testing
  static stripePaymentCharge(body, accessToken) {
    console.log('body');
    console.log(body);
    return Request.POST_json(`${process.env.paymentsAPI}/v1/charge`, body, global.gpAccessToken);
  }
}

export default StripePaymentsApi;

