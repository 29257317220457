import Request from './Request';
import RequestRegion from './RequestRegion';


class AdminStatsApi {

  // // GET USER STATS ////
  static getUserStats() {
    console.warn(`API | POST | Get User Stats`);
    return Request.POST_json(`${process.env.adminStatsAPI}/v1/users/overview`, null, global.gpAccessToken);
  }


}


export default AdminStatsApi;
